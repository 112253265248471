export enum Profession {
	OP_SSK = 'OP_SSK',
	ANE_SSK = 'ANE_SSK',
	OP_USK = 'OP_USK',
	ANE_USK = 'ANE_USK',
	COORDINATOR = 'COORDINATOR',
}

export interface Staff {
	id: number;
	profession: Profession;
	teamAllowedPlacement: Array<string>;
	available: boolean;
}

export interface Team {
	id: string;
	staff: Array<Staff>;
}

export interface TeamWithStartEnd extends Team {
	start: string;
	end: string;
}

export interface Constraint {
	name: Profession;
	min: number;
	max: number;
}

export interface TeamWithConstraints extends Team {
	constraints: Constraint;
}

export enum SchedulingType {
	EMERGENCY = 'EMERGENCY',
	ELECTIVE = 'ELECTIVE',
}

export enum DestinationTarget {
	POST_OP = 'POST_OP',
	HOME = 'HOME',
	ICU = 'ICU',
	WARD = 'WARD',
}

export interface Destination {
	target: DestinationTarget;
	duration: number;
}

export interface Operation {
	id: string;
	schedulingType: SchedulingType;
	start: string;
	roomStart: string;
	roomEnd: string;
	end: string;
	teams: Array<TeamWithStartEnd>;
	destinations: Array<Destination>;
}

export interface OperationRoom {
	id: string;
	operations: Array<Operation>;
}

export interface Case {
	id: string;
	operationRooms: Array<OperationRoom>;
}

export interface TeamComposition {
	staff: Array<Staff>;
	teams: Array<TeamWithConstraints>;
}

export interface LunchTimeslot {
	teamId: string;
	start: string;
	end: string;
}

export interface OpColoring {
	operations: Array<OperationRoom>;
	lunchTeam: Team;
	teams: Array<Team>;
	lunchTimes: Array<LunchTimeslot>;
}
