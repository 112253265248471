import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import Topbar from './components/Topbar';
import {
	ReferralCP,
	ReferralOverviewCP,
	StaffCP,
	RoomCP,
	PlanningCP,
	DailyPlanningCP,
	WaitListSO,
	PlanningSO,
	DailyPlanningSO,
	PostOpSO,
	RoomSO,
	StaffSO,
	TeamsSO,
	StartOverview,
	SimulationCP,
} from './components/pages/AllPages';
import { useEffect, useState } from 'react';
import {
	HttpLink,
	ApolloClient,
	InMemoryCache,
	from,
	ApolloProvider,
	NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AppThemeProvider } from './AppTheme';
import AxiosConfiguration from './AxiosConfiguration';

function App() {
	const [keycloak, setKeycloak] = useState<Keycloak>();
	const [apolloClient, setApolloClient] = useState<
		ApolloClient<NormalizedCacheObject> | undefined
	>(undefined);

	useEffect(() => {
		const kc = new Keycloak('/keycloak.json');
		kc.init({
			onLoad: 'login-required',
			checkLoginIframe: false,
		}).then((_) => {
			kc.loadUserProfile()
				.then((_) => setKeycloak(kc))
				.then(() => {
					const link = new HttpLink({
						uri: (window as any).env.GRAPHQL_URL,
					});

					const authLink = setContext((_, { headers }) => {
						return {
							headers: {
								...headers,
								Authorization: kc.token ? `Bearer ${kc.token}` : '',
							},
						};
					});

					setInterval(() => kc.updateToken(60), 45000);

					setApolloClient(
						new ApolloClient({
							cache: new InMemoryCache(),
							link: from([authLink, link]),
						})
					);
				}); // Dangerous might crash if profile is not loaded.
		});
	}, []);

	return (
		<>
			{keycloak?.authenticated === true && apolloClient !== undefined && (
				<AppThemeProvider>
					<ApolloProvider client={apolloClient}>
						<BrowserRouter>
							<Topbar keycloak={keycloak} translationBase="topbar" />
							<Box
								sx={{
									margin: 'auto',
									width: (theme) => theme.breakpoints.values.lg,
								}}
							>
								<AxiosConfiguration keycloak={keycloak} />
								<Routes>
									<Route
										path="/"
										element={
											<Navigate
												to="/clinical-physiology/referral-overview"
												replace={true}
											/>
										}
									/>

									{/* Clinical Physiology */}
									<Route
										path="/clinical-physiology/referral-overview"
										element={
											<ReferralOverviewCP translationBase="clinical physiology" />
										}
									/>
									<Route
										path="/clinical-physiology/planning"
										element={
											<PlanningCP translationBase="clinical physiology" />
										}
									/>
									<Route
										path="/clinical-physiology/daily-planning"
										element={
											<DailyPlanningCP translationBase="clinical physiology" />
										}
									/>
									<Route
										path="/clinical-physiology/staff"
										element={<StaffCP translationBase="clinical physiology" />}
									/>
									<Route
										path="/clinical-physiology/room"
										element={<RoomCP translationBase="clinical physiology" />}
									/>
									<Route
										path="/clinical-physiology/referral"
										element={
											<ReferralCP translationBase="clinical physiology" />
										}
									/>
									<Route
										path="/clinical-physiology/simulation"
										element={
											<SimulationCP translationBase="clinical physiology" />
										}
									/>

									{/* Surgical operation */}
									<Route
										path="/surgery/wait-list"
										element={<WaitListSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/planning"
										element={<PlanningSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/daily-planning"
										element={<DailyPlanningSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/post-op"
										element={<PostOpSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/staff"
										element={<StaffSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/teams"
										element={<TeamsSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/room"
										element={<RoomSO translationBase="operation" />}
									/>
									<Route
										path="/surgery/start-overview"
										element={<StartOverview translationBase="operation" />}
									/>
								</Routes>
							</Box>
						</BrowserRouter>
					</ApolloProvider>
				</AppThemeProvider>
			)}
		</>
	);
}

export default App;
