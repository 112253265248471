import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Typography,
	Select,
	Box,
	InputLabel,
	MenuItem,
	IconButton,
	Chip,
	Tooltip,
} from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import Keycloak from 'keycloak-js';
import { useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ALL_PALETTES,
	ALL_PALETTES_NAME,
	AppThemeContext,
} from '../../AppTheme';

const BOX_HEIGHT = 16;

interface UserDialogProps {
	open: boolean;
	translationBase: string;
	handleClose: (value: boolean) => void;
	keycloak: Keycloak.KeycloakInstance;
}

function UserDialog(props: UserDialogProps) {
	const { profile } = props.keycloak;
	const tm = useContext(AppThemeContext);

	const { t, i18n } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			open={props.open}
			fullWidth
			maxWidth="sm"
			onClose={() => props.handleClose(false)}
		>
			<DialogTitle>{t('user settings')}</DialogTitle>
			<DialogContent dividers>
				{`${t('logged in as')}: ${profile?.firstName} ${profile?.lastName}`}
				<Typography variant="body1">
					{`${t('email')}: ${profile?.email}`}
				</Typography>

				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'auto auto',
						rowGap: (t) => t.spacing(4),
						marginTop: (t) => t.spacing(4),
					}}
				>
					<InputLabel>{t('language')}</InputLabel>
					<Select
						value={i18n.language}
						onChange={(event) => {
							i18n.changeLanguage(event.target.value);
						}}
						fullWidth
					>
						<MenuItem value="sv">{t('swedish')}</MenuItem>
						<MenuItem value="en">{t('english')}</MenuItem>
					</Select>

					<InputLabel>{t('dark mode')}</InputLabel>
					<Box sx={{ display: 'flex' }}>
						<IconButton
							color="primary"
							sx={{
								border: tm.mode === 'light' ? '1px solid #ccc' : 'inherit',
							}}
							onClick={() => tm.colorMode('light')}
						>
							<LightMode />
						</IconButton>

						<IconButton
							color="primary"
							sx={{
								border: tm.mode === 'dark' ? '1px solid #ccc' : 'inherit',
							}}
							onClick={() => tm.colorMode('dark')}
						>
							<DarkMode />
						</IconButton>
					</Box>

					{ALL_PALETTES_NAME.map((pName, index) => (
						<Fragment key={pName}>
							<InputLabel>{pName}</InputLabel>
							<Box
								sx={{
									display: 'flex',
									columnGap: '2px',
									'& div': { height: BOX_HEIGHT, width: BOX_HEIGHT },
									cursor: 'pointer',
								}}
								onClick={() => tm.updateTheme(pName)}
							>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].red.light,
									}}
								/>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].red.dark,
									}}
								/>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].green.light,
									}}
								/>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].green.dark,
									}}
								/>

								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].blue.light,
									}}
								/>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].blue.dark,
									}}
								/>

								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].purple.light,
									}}
								/>
								<Box
									sx={{
										backgroundColor: ALL_PALETTES[index].purple.dark,
									}}
								/>
							</Box>
						</Fragment>
					))}
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginLeft: 'auto',
					}}
				>
					<Tooltip title={t('app version') as string}>
						<Chip label={process.env.REACT_APP_VERSION} />
					</Tooltip>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button disableRipple onClick={() => props.keycloak.logout()}>
					{t('log out')}
				</Button>
				<Button disableRipple onClick={() => props.handleClose(false)}>
					{t('close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UserDialog;
