export enum DrawingMode {
	ALWAYS = 'always',
	AFTER_OPERATION_START = 'after operation start',
}

export type HistoricalDataFetch = {
	durations: Array<{
		name: string;
		ORDuration: number;
		operatorDuration: number;
	}>;
	startingTimes: Array<{
		name: string;
		ORStart: string;
		operatorStart: string;
	}>;
};

export type HistoricalData = {
	[name: string]: {
		ORDuration: number;
		operatorDuration: number;
		ORStart: string;
		operatorStart: string;
	};
};

export type Tracks = {
	maxTracks: number;
	tracks: Array<number>;
};
