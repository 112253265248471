import { gql } from '@apollo/client';

export const OP_TEAMS_QUERY = gql`
	{
		op_Staff(
			order_by: { name: asc }
			where: { Profession: { abbrev: { _nlike: "st" } } }
		) {
			name
			Profession {
				abbrev
			}
			StaffQualificationTeams {
				Team {
					abbrev
				}
			}
			id
		}
	}
`;
