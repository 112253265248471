import { Close } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	FormControl,
	FormLabel,
	Select,
	TextField,
	Box,
	IconButton,
	Paper,
	Typography,
	MenuItem,
	Checkbox,
	FormGroup,
	FormControlLabel,
} from '@mui/material';
import chroma from 'chroma-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constraint, Team } from './Types';

export function ConstraintList(props: {
	team: Team;
	teamColor: string;
	removeConstraint: (name: string) => void;
	selectedConstraintName: string;
	selectedTeamName: string;
	setSelectedConstraint: (constraint: Constraint, teamName: string) => void;
}) {
	return (
		<Box
			sx={{
				margin: '0px 0px 0.25rem 1rem',
				display: 'grid',
				gridTemplateColumns: 'repeat(5, 1fr)',
				gap: '5px',
			}}
		>
			{props.team.constraints.map((constraint) => (
				<Paper
					key={constraint.name}
					elevation={2}
					sx={{
						border: `2px solid ${
							props.team.id === props.selectedTeamName &&
							props.selectedConstraintName === constraint.name
								? 'black'
								: props.teamColor
						}`,
						padding: '0px 3px 5px 10px',
						backgroundColor: props.teamColor,
						color:
							chroma.contrast(props.teamColor, '#000') < 4.5 ? '#fff' : '#000',
						cursor: 'pointer',
						userSelect: 'none',
						height: '32px',
					}}
					onClick={() => props.setSelectedConstraint(constraint, props.team.id)}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr auto min-content',
							paddingTop: '6px',
						}}
					>
						<Typography variant="caption">{constraint.name}</Typography>
						<Typography variant="caption">
							{constraint.min}-{constraint.max}
						</Typography>
						<IconButton
							sx={{
								marginTop: '-6px',
							}}
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								props.removeConstraint(constraint.name);
							}}
						>
							<Close
								fontSize="small"
								sx={{
									color:
										chroma.contrast(props.teamColor, '#000') < 4.5
											? '#fff'
											: '#000',
								}}
							/>
						</IconButton>
					</Box>
				</Paper>
			))}
			{Array(5 - props.team.constraints.length)
				.fill('')
				.map((_, index) => (
					<Paper
						key={`placeholder${index}`}
						elevation={0}
						sx={{
							border: '1px dashed lightgrey',
							padding: '3px 3px 5px 10px',
							height: '32px',
						}}
					/>
				))}
		</Box>
	);
}

export function TeamSettingsDialog(props: {
	teams: Array<Team>;
	teamColors: { [color: string]: string };
	setTeams: (teams: Array<Team>) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
	constraintNames: Array<string>;
	translationBase: string;
	leftover: boolean;
	leftoverToggle: (leftover: boolean) => void;
}) {
	const [constraint, setConstraint] = useState({
		selectedTeam: 'Välj ett team',
		selectedConstraint: 'none',
		min: 0,
		max: 1,
	});

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const selectedTeam = props.teams.find(
		(team) => team.id === constraint.selectedTeam
	);

	return (
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={props.open}
			onClose={() => props.setOpen(false)}
		>
			<DialogTitle>{t('team constraint')}</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				<DialogContentText variant="h6">
					{t('add new constraint')}
				</DialogContentText>
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(2, 2fr) repeat(2, 1fr) min-content',
						marginBottom: '1rem',
						columnGap: '1rem',
					}}
				>
					<FormControl>
						<FormLabel>Team</FormLabel>
						<Select
							value={constraint.selectedTeam}
							onChange={(e) => {
								const newTeam = props.teams.find(
									(t) => t.id === e.target.value
								);
								if (newTeam !== undefined)
									setConstraint({
										...constraint,
										selectedTeam: newTeam.id,
										selectedConstraint:
											newTeam.constraints.findIndex(
												(c) => c.name === constraint.selectedConstraint
											) !== -1
												? 'none'
												: constraint.selectedConstraint,
									});
								else {
									setConstraint({
										...constraint,
										selectedTeam: 'Välj ett team',
										selectedConstraint: 'none',
									});
								}
							}}
						>
							<MenuItem value="Välj ett team">{t('choose a team')}</MenuItem>
							{props.teams.map((team) => (
								<MenuItem key={team.id} value={team.id}>
									{team.id}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<FormLabel>{t('constraint')}</FormLabel>
						<Select
							value={constraint.selectedConstraint}
							onChange={(e) =>
								setConstraint({
									...constraint,
									selectedConstraint: e.target.value,
								})
							}
						>
							<MenuItem value="none">
								<Typography>{t('choose a constraint')}</Typography>
							</MenuItem>
							{props.constraintNames.map((constraint) => {
								let text = '';
								if (selectedTeam !== undefined) {
									const matchingContraint = selectedTeam.constraints.find(
										(c) => c.name === constraint
									);
									if (matchingContraint !== undefined)
										text = `${matchingContraint.min}-${matchingContraint.max}`;
								}
								return (
									<MenuItem key={constraint} value={constraint}>
										<Box
											sx={{
												width: '100%',
												display: 'flex',
												justifyContent: 'space-between',
											}}
										>
											<Typography>{constraint}</Typography>
											<Typography>{text}</Typography>
										</Box>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<FormControl>
						<FormLabel>&nbsp;</FormLabel>
						<TextField
							type="number"
							label="Min"
							value={constraint.min}
							onChange={(e) =>
								setConstraint({
									...constraint,
									min: Math.min(Number(e.target.value), constraint.max),
								})
							}
							InputProps={{
								inputProps: { min: 0 },
							}}
						/>
					</FormControl>

					<FormControl>
						<FormLabel>&nbsp;</FormLabel>
						<TextField
							type="number"
							label="Max"
							value={constraint.max}
							onChange={(e) =>
								setConstraint({
									...constraint,
									max: Math.max(Number(e.target.value), constraint.min),
								})
							}
							InputProps={{
								inputProps: { min: 0 },
							}}
						/>
					</FormControl>

					<FormControl>
						<FormLabel>&nbsp;</FormLabel>
						<Button
							sx={{ height: '100%' }}
							variant="contained"
							disabled={
								constraint.selectedTeam === 'Välj ett team' ||
								constraint.selectedConstraint === 'none' ||
								(selectedTeam !== undefined &&
									selectedTeam.constraints.some(
										(c) =>
											c.name === constraint.selectedConstraint &&
											c.min === constraint.min &&
											c.max === constraint.max
									))
							}
							onClick={() => {
								const teamIndex = props.teams.findIndex(
									(t) => t.id === constraint.selectedTeam
								);
								if (teamIndex !== -1) {
									const constraintIndex = props.teams[
										teamIndex
									].constraints.findIndex(
										(c) => c.name === constraint.selectedConstraint
									);
									const copy = [...props.teams];
									if (constraintIndex === -1)
										copy[teamIndex].constraints.push({
											name: constraint.selectedConstraint,
											min: constraint.min,
											max: constraint.max,
										});
									else
										copy[teamIndex].constraints[constraintIndex] = {
											min: constraint.min,
											max: constraint.max,
											name: constraint.selectedConstraint,
										};

									props.setTeams(copy);
								}
							}}
						>
							OK
						</Button>
					</FormControl>
				</Box>
				<DialogContentText>{t('view')}</DialogContentText>
				<Box sx={{ overflowY: 'auto' }}>
					{props.teams.map((team, teamIndex) => (
						<Box key={team.id}>
							<Typography>{team.id}</Typography>
							<ConstraintList
								team={team}
								teamColor={props.teamColors[team.id]}
								removeConstraint={(constraint) => {
									const copy = [...props.teams];
									copy[teamIndex].constraints = copy[
										teamIndex
									].constraints.filter((c) => c.name !== constraint);

									props.setTeams(copy);
									setConstraint({
										selectedTeam: 'Välj ett team',
										selectedConstraint: 'none',
										min: 0,
										max: 1,
									});
								}}
								selectedConstraintName={constraint.selectedConstraint}
								selectedTeamName={constraint.selectedTeam}
								setSelectedConstraint={(changedConstraint, teamName) =>
									setConstraint({
										min: changedConstraint.min,
										max: changedConstraint.max,
										selectedConstraint: changedConstraint.name,
										selectedTeam: teamName,
									})
								}
							/>
						</Box>
					))}
				</Box>

				<FormGroup sx={{ mt: 2 }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={props.leftover}
								onChange={() => props.leftoverToggle(!props.leftover)}
							/>
						}
						label={t('move leftover staff')}
					/>
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.setOpen(false)}>{t('close')}</Button>
			</DialogActions>
		</Dialog>
	);
}
