import {
	Referral as SpecReferral,
	Room as SpecRoom,
	Staff as SpecStaff,
} from '../Common/Types';

export type QueryType = {
	staff: Array<{
		id: string;
		name: string;
		staffqualifications: Array<{
			referraltype: {
				abbrev: string;
				bma: string;
			};
		}>;
		profession: {
			title: string;
		};
	}>;
	room: Array<{
		id: string;
		name: string;
		abbrev: string;
		roomreferraltypes: Array<{
			referraltype: {
				abbrev: string;
			};
		}>;
	}>;
	referraltype: Array<{
		abbrev: string;
		interpret: string;
	}>;
};

export type ReferralType = {
	abbrev: string;
	bma: string;
};

export enum SimulationState {
	ERROR = -1,
	NONE = 0,
	LOADING_SIMULATION = 1,
	FINISHED_LOADING_SIMULATION = 2,
	LOADING_SCHEDULING = 3,
	FINISHED_LOADING_SCHEDULING = 4,
	LOADING_INTERPET = 5,
	FINISHED_LOADING_INTERPET = 6,
}

export interface Room extends Omit<SpecRoom, 'referrals'> {
	referrals: Array<Referral>;
	active: boolean;
	name: string;
}

export interface Staff extends SpecStaff {
	name: string;
}

export interface Referral extends Omit<SpecReferral, 'bma'> {
	bma?: Staff;
	interpretDuration?: number;
}

export enum BackgroundColorMode {
	INTERPRET = 0,
	PRIORITY = 1,
}
