import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import chroma from 'chroma-js';
import { setHours, setMinutes } from 'date-fns';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
	Case as SpecCase,
	OperationRoom as SpecOperationRoom,
} from '../../Common/Types';
import { RelativeGraph } from '../../StartOverviewSO/StartOverview';
import { HMToX } from '../DailyPlanningSO';
import { HistoricalData } from '../Types';

function RoomTimeUsage(props: {
	rooms: Array<SpecOperationRoom>;
	colors: { [name: string]: string };
	historicData: HistoricalData;
	t: TFunction;
}) {
	const [showHistoricData, setShowHistoricData] = useState(false);

	const clampOperation = (val: number) => Math.min(540, Math.max(val, 60));

	const groupedRooms = props.rooms.reduce<{
		[name: string]: {
			'room usage': number;
			'knife usage': number;
			'historic room usage': number;
			'historic knife usage': number;
		};
	}>((acc, cur) => {
		for (const operation of cur.operations) {
			if (acc[cur.id] === undefined)
				acc[cur.id] = {
					'room usage': 0,
					'knife usage': 0,
					'historic room usage': 0,
					'historic knife usage': 0,
				};

			acc[cur.id]['room usage'] +=
				clampOperation(HMToX(operation.end)) -
				clampOperation(HMToX(operation.start));

			acc[cur.id]['knife usage'] +=
				clampOperation(HMToX(operation.roomEnd)) -
				clampOperation(HMToX(operation.roomStart));

			if (showHistoricData && props.historicData[cur.id] !== undefined) {
				acc[cur.id]['historic room usage'] =
					props.historicData[cur.id].ORDuration;
				acc[cur.id]['historic knife usage'] =
					props.historicData[cur.id].operatorDuration;
			}
		}
		return acc;
	}, {});

	const graphData = Object.keys(groupedRooms).map((rt) => ({
		type: rt,
		...groupedRooms[rt],
	}));

	return (
		<>
			<Typography variant="h5">{props.t('room usage')}</Typography>
			<FormControlLabel
				control={
					<Switch
						checked={showHistoricData}
						onClick={() => setShowHistoricData(!showHistoricData)}
					/>
				}
				label={props.t('show historical data')}
				sx={{ pt: (t) => t.spacing(2) }}
			/>

			<ResponsiveBar
				maxValue={550}
				data={graphData}
				groupMode="grouped"
				innerPadding={1}
				keys={[
					'room usage',
					'knife usage',
					...(showHistoricData
						? ['historic room usage', 'historic knife usage']
						: []),
				]}
				indexBy="type"
				margin={{ top: 25, right: 50, bottom: 30, left: 60 }}
				padding={0.6}
				valueScale={{ type: 'linear' }}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				colors={(e) =>
					e.id.toString().includes('historic')
						? chroma(props.colors[e.indexValue]).alpha(0.25).hex()
						: props.colors[e.indexValue]
				}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 45,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: props.t('minutes'),
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				markers={[
					{
						axis: 'y',
						value: 510,
						lineStyle: {
							stroke: '#000',
							strokeDasharray: '10, 5',
							strokeWidth: 1,
						},
						textStyle: {
							fill: '#000',
						},
						legend: `8.5 ${props.t('hours')}`,
					},
				]}
				tooltipLabel={(e) => props.t(e.id as string)}
				label={() => ''}
			/>
		</>
	);
}

function RoomStart(props: {
	rooms: Array<SpecOperationRoom>;
	historicData: HistoricalData;
	colors: { [name: string]: string };
	translationBase: string;
	t: TFunction;
}) {
	const [relativeBar, setRelativeBar] = useState(false);

	const graphData: Array<{ [name: string]: string }> = [
		...props.rooms.map((r) => ({ bar: r.id })),
	];

	props.rooms.forEach((room, roomIndex) => {
		if (room.operations.length > 0) {
			const earliestOperation = [...room.operations].sort(
				(a, b) => HMToX(a.start) - HMToX(b.start)
			)[0];

			const start = setMinutes(setHours(new Date(), 7), 0);
			start.setMinutes(start.getMinutes() + HMToX(earliestOperation.start));

			const roomStart = setMinutes(setHours(new Date(), 7), 0);
			roomStart.setMinutes(
				roomStart.getMinutes() + HMToX(earliestOperation.roomStart)
			);

			graphData[roomIndex][props.t('room start')] = (
				start.getHours() +
				start.getMinutes() / 60
			).toString();
			graphData[roomIndex][props.t('knife start')] = (
				roomStart.getHours() +
				roomStart.getMinutes() / 60
			).toString();

			if (props.historicData[room.id] !== undefined) {
				graphData[roomIndex][props.t('historic room start')] = (
					7 +
					HMToX(props.historicData[room.id].ORStart) / 60
				).toString();
				graphData[roomIndex][props.t('historic knife start')] = (
					7 +
					HMToX(props.historicData[room.id].operatorStart) / 60
				).toString();
			}
		}
	});

	return (
		<>
			<Typography variant="h5">{props.t('start view')}</Typography>
			<FormControlLabel
				control={
					<Switch
						checked={relativeBar}
						onClick={() => setRelativeBar(!relativeBar)}
					/>
				}
				label={props.t('relative view')}
				sx={{ pt: (t) => t.spacing(2) }}
			/>
			<RelativeGraph
				data={graphData}
				colors={props.colors}
				keys={[
					props.t('room start'),
					props.t('knife start'),
					props.t('historic room start'),
					props.t('historic knife start'),
				]}
				isRelative={relativeBar}
				relativeOffset={0.5}
				baseOffset={7}
				margin={{ top: 0, right: 25, bottom: 30, left: 45 }}
				translateXaxis={false}
				secondMarker={0.75}
				noBarLabel={true}
				translationBase={`${props.translationBase}.room usage panel`}
			/>
		</>
	);
}

function RoomDensityComparison(props: {
	unOptimizedSchedule: Array<SpecOperationRoom>;
	optimizedSchedule: Array<SpecOperationRoom>;
	colors: { [name: string]: string };
	t: TFunction;
}) {
	const graphData: Array<{ [name: string]: string }> = [
		...props.unOptimizedSchedule.map((r) => ({ room: r.id })),
	];

	if (props.unOptimizedSchedule.length === props.optimizedSchedule.length) {
		const unOptimizedData = props.unOptimizedSchedule.reduce<Array<number>>(
			(acc, cur) => {
				const roomMinMax = cur.operations.reduce(
					(innerAcc, innerCur) => ({
						min:
							HMToX(innerCur.start) < innerAcc.min
								? HMToX(innerCur.start)
								: innerAcc.min,
						max:
							HMToX(innerCur.end) > innerAcc.max
								? HMToX(innerCur.end)
								: innerAcc.max,
					}),
					{ min: Number.MAX_VALUE, max: Number.MIN_VALUE }
				);
				acc.push(roomMinMax.max - roomMinMax.min);
				return acc;
			},
			[]
		);

		const optimizedData = props.optimizedSchedule.reduce<Array<number>>(
			(acc, cur) => {
				const roomMinMax = cur.operations.reduce(
					(innerAcc, innerCur) => ({
						min:
							HMToX(innerCur.start) < innerAcc.min
								? HMToX(innerCur.start)
								: innerAcc.min,
						max:
							HMToX(innerCur.end) > innerAcc.max
								? HMToX(innerCur.end)
								: innerAcc.max,
					}),
					{ min: Number.MAX_VALUE, max: Number.MIN_VALUE }
				);
				acc.push(roomMinMax.max - roomMinMax.min);
				return acc;
			},
			[]
		);

		unOptimizedData.forEach((_, index) => {
			graphData[index]['effektivisering'] = (
				(unOptimizedData[index] / optimizedData[index]) *
				100
			).toString();
		});
	}

	const maxValue = graphData.reduce((acc, cur) => {
		return Math.max(acc, Number(cur['effektivisering']));
	}, 100);

	return (
		<>
			<Typography variant="h5">{props.t('streamlining')}</Typography>
			<ResponsiveBar
				data={graphData}
				innerPadding={1}
				keys={['effektivisering']}
				indexBy="room"
				margin={{ top: 78, right: 50, bottom: 67, left: 60 }}
				padding={0.6}
				valueScale={{ type: 'linear' }}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				colors={(e) => props.colors[e.indexValue]}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 45,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					format: (value) => `${value} %`,
					legend: props.t('streamlining'),
					legendPosition: 'middle',
					legendOffset: -50,
				}}
				maxValue={maxValue === 100 ? maxValue : maxValue * 1.1}
				label=""
				tooltip={() => <></>}
			/>
		</>
	);
}

export function RoomStatisticsPanel(props: {
	index: number;
	value: number;
	case: SpecCase;
	optimizedSchedule: Array<SpecOperationRoom>;
	enableLunchTeam: boolean;
	historicalData: HistoricalData;
	colors: { [name: string]: string };
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.room usage panel`,
	});

	const rooms = props.enableLunchTeam
		? props.optimizedSchedule
		: props.case.operationRooms;

	return (
		<Box role="tabpanel" hidden={props.value !== props.index}>
			{props.value === props.index && (
				<Box
					sx={{
						height: '500px',
						display: 'grid',
						gridTemplateColumns: '1fr 1fr 1fr',
						background: 'white',
					}}
				>
					<Box sx={{ height: '410px' }}>
						<RoomTimeUsage
							rooms={rooms}
							historicData={props.historicalData}
							colors={props.colors}
							t={t}
						/>
					</Box>
					<Box sx={{ height: '410px' }}>
						<RoomStart
							rooms={rooms}
							historicData={props.historicalData}
							colors={props.colors}
							translationBase={props.translationBase}
							t={t}
						/>
					</Box>
					<Box sx={{ height: 'inherit' }}>
						<RoomDensityComparison
							unOptimizedSchedule={props.case.operationRooms}
							optimizedSchedule={props.optimizedSchedule}
							colors={props.colors}
							t={t}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
}
