import {
	Autocomplete,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	Tab,
	Tabs,
	TextField,
} from '@mui/material';
import { Box } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';
import { PageBox } from '../../../common/Components';
import { Legend } from '../../../common/Legend';
import { Marker, Timeline } from './PlanningGraphComponents';
import { OperationBlock } from './OperationBlock';
import { LunchReliefPanel } from './Panels/LunchReliefPanel';
import {
	DrawingMode,
	HistoricalDataFetch,
	HistoricalData,
	Tracks,
} from './Types';
import { DraggableData } from 'react-draggable';
import { RoomStatisticsPanel } from './Panels/RoomUsagePanel';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import svSE from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import { PostOPPanel } from './Panels/PostOPPanel';

import { PlayArrow, Pause, Done, Replay } from '@mui/icons-material';
import {
	Case as SpecCase,
	LunchTimeslot as SpecLunchTimeslot,
	OpColoring,
	Operation as SpecOperation,
	OperationRoom as SpecOperationRoom,
	Staff as SpecStaff,
	Team as SpecTeam,
} from '../Common/Types';

const ROOM_START = 7;
const ROOM_END = 17;
const LUNCH_START = 11;
const LUNCH_END = 13;
export const NUM_HOURS = Math.abs(ROOM_END - ROOM_START);
export const GRID_GAP_SIZE = '1rem';
const ROW_HEIGHT = 42;
const ANIMATION_DURATION_MS = 1000 * 10;

const LUNCH_TEAM: SpecTeam = {
	id: 'Lunch team',
	staff: [] as Array<SpecStaff>,
};

export function getPercentage(num: number, fraction: boolean = true) {
	return (num / (NUM_HOURS * 60)) * (fraction ? 1 : 100);
}

export function getOffsetTimeStr(totalMinutes: number) {
	const minutes = Math.round((ROOM_START % 1) * 60) + (totalMinutes % 60);
	const hours = Math.floor(ROOM_START + totalMinutes / 60);
	return `${hours.toString().padStart(2, '0')}:${(minutes % 60)
		.toString()
		.padStart(2, '0')}`;
}

export const palette = {
	emergency: 'hsl(0, 51%, 88%)',
	emergencyCritical: 'hsl(0, 80%, 70%)',
	elective: 'hsl(192, 100%, 95%)',
	electiveCritical: 'hsl(192, 80%, 70%)',
};

export function xToHM(x: number) {
	const hour = String(Math.floor(x / 60) + ROOM_START).padStart(2, '0');
	const minute = String(Math.floor(x % 60)).padStart(2, '0');
	return `${hour}:${minute}`;
}

export function HMToX(HM: string) {
	return (
		HM.split(':').reduce(
			(acc, cur, index) => acc + Number(cur) * (index === 0 ? 60 : 1),
			0
		) -
		ROOM_START * 60
	);
}

/** Checks if there is operator-time overlap between `opA` and `opB` */
function isKnifeOverlapping(opA: SpecOperation, opB: SpecOperation) {
	return opA.roomStart < opB.roomEnd && opB.roomStart < opA.roomEnd;
}

/** Checks if there is overlap between `opA` and `opB` */
function isOverlapping(opA: SpecOperation, opB: SpecOperation) {
	return opA.start < opB.end && opB.start < opA.end;
}

function moveTemporial(op: SpecOperation, delta: number): SpecOperation {
	return {
		...op,
		end: xToHM(delta + (HMToX(op.end) - HMToX(op.start))),
		roomEnd: xToHM(delta + (HMToX(op.roomEnd) - HMToX(op.start))),
		roomStart: xToHM(delta + (HMToX(op.roomStart) - HMToX(op.start))),
		start: xToHM(delta),
	};
}

function DailyPlanningSO(props: { translationBase: string }) {
	const [cases, setCases] = useState<Array<SpecCase>>([]);
	const [selectedCase, setSelectedCase] = useState(0);

	const [enableLunchTeam, setEnableLunchTeam] = useState(false);
	const [noRelief, setNoRelief] = useState<Array<boolean>>([]);
	const [lunchTimes, setLunchTimes] = useState<Array<SpecLunchTimeslot>>([]);

	// Optimized schedule with lunch replacements.
	const [optimizedSchedule, setOptimizedSchedule] = useState<
		Array<SpecOperationRoom>
	>([]);

	const [tracks, setTracks] = useState<Array<Tracks>>([]);

	const [animating, setAnimating] = useState(false);
	const [markerPositionPercentage, setMarkerPositionPercentage] = useState(0);

	const [drawingMode, setDrawingMode] = useState<DrawingMode>(
		DrawingMode.ALWAYS
	);

	const [selectedTab, setSelectedTab] = useState(0);
	const [fetching, setFetching] = useState(false);

	const [operationStartData, setOperationStartData] = useState<HistoricalData>(
		{}
	);

	const [selectedDate, setSelectedDate] = useState(new Date());
	const [loadLimit, setLoadLimit] = useState(6);

	const [ref, setRef] = useState<HTMLDivElement | null>(null);

	const { t, i18n } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.daily planning`,
	});
	const tm = useContext(AppThemeContext);

	const colors = [
		tm.paletteColors.green.dark,
		tm.paletteColors.green.light,
		tm.paletteColors.yellow.dark,
		tm.paletteColors.blue.dark,
		tm.paletteColors.red.light,
	];

	const roomColors: { [name: string]: string } = {};
	const teamColors: { [name: string]: string } = {
		'Lunch team': colors[colors.length - 1],
	};
	if (cases.length > 0) {
		for (let i = 0; i < cases[selectedCase].operationRooms.length; i++) {
			if (i < colors.length - 1) {
				teamColors[`Team ${i + 1}`] = colors[i];
				roomColors[`Sal-${i + 1}`] = colors[i];
			} else {
				teamColors[`Team ${i + 1}`] = 'black';
				roomColors[`Sal-${i + 1}`] = 'black';
			}
		}
	}

	const locale = i18n.language === 'sv' ? svSE : enUS;
	useEffect(() => {
		setFetching(true);
		axios
			.get<Array<SpecCase>>(
				`/simulation/operation/cases?date=${selectedDate.getFullYear()}-${(
					selectedDate.getMonth() + 1
				)
					.toString()
					.padStart(2, '0')}-${selectedDate
					.getDate()
					.toString()
					.padStart(2, '0')}`
			)
			.then((res) => {
				// Op coloring for all cases
				if (res.data.length > 0) {
					const defaultNoRelief = res.data[0].operationRooms.map(() => false);
					setNoRelief(defaultNoRelief);
					updateAllTracks(res.data[0].operationRooms);

					fetchColorsAndOverlap(
						res.data[0].operationRooms,
						defaultNoRelief
					).then((result) => {
						res.data[0].operationRooms = result.operationRooms;
						setCases(res.data);
						setSelectedCase(0);

						setFetching(false);
						setLunchTimes(result.lunchTimes);

						const customizedCaseIndex = res.data.findIndex(
							(c) => c.id === `${res.data[0].id}-customized`
						);

						setOptimizedSchedule(
							customizedCaseIndex === -1
								? result.optimizedOperationRooms
								: res.data[customizedCaseIndex].operationRooms
						);
					});
				}
			})
			.catch(() => {
				setSelectedCase(0);
				setCases([]);
				setFetching(false);
			});

		axios
			.get<HistoricalDataFetch>('/simulation/operation/historic-values')
			.then((res) => {
				const newHistoricalData: HistoricalData = {};
				res.data.durations.forEach(
					(data) =>
						(newHistoricalData[data.name] = {
							ORDuration: data.ORDuration,
							operatorDuration: data.operatorDuration,
							ORStart: '0:0',
							operatorStart: '0: 0',
						})
				);
				res.data.startingTimes.forEach(
					(data) =>
						(newHistoricalData[data.name] = {
							ORStart: data.ORStart,
							operatorStart: data.operatorStart,
							ORDuration: newHistoricalData[data.name].ORDuration,
							operatorDuration: newHistoricalData[data.name].operatorDuration,
						})
				);
				setOperationStartData(newHistoricalData);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	const getSimulationButtonText = () => {
		if (markerPositionPercentage === 1) return animating ? <Pause /> : <Done />;
		else return animating ? <Pause /> : <PlayArrow />;
	};

	const reset = () => {
		setLoadLimit(6);
		setNoRelief(noRelief.map(() => false));
		setAnimating(false);
		setMarkerPositionPercentage(0);
	};

	const fetchColors = async (
		operationsRooms: Array<SpecOperationRoom>,
		disableRelief: Array<boolean>
	): Promise<{
		operationRooms: Array<SpecOperationRoom>;
		lunchTimes: Array<SpecLunchTimeslot>;
	}> => {
		const filteredRooms = operationsRooms.filter((_r, i) => !disableRelief[i]);

		// remove existing team info
		for (let i = 0; i < filteredRooms.length; i++) {
			filteredRooms[i] = {
				...filteredRooms[i],
				operations: filteredRooms[i].operations.map((o) => ({
					...o,
					teams: [],
				})),
			};
		}

		const teamList: Array<{ id: string; staff: [] }> = [];

		disableRelief.forEach((item, i) => {
			if (!item) {
				teamList.push({
					id: `Team ${i + 1}`,
					staff: [],
				});
			}
		});

		const requestBody: OpColoring = {
			teams: teamList,
			lunchTeam: LUNCH_TEAM,
			operations: filteredRooms,
			lunchTimes: [],
		};

		const opColorResult = await axios.post<OpColoring>(
			'/model/op-color',
			requestBody
		);
		const copyLunchTimes = [...opColorResult.data.lunchTimes];

		const copy = [...operationsRooms];
		for (let i = 0; i < copy.length; i++) {
			const originalTeam = copy[i].operations
				.map((o) => o.teams)
				.flat()
				.find((t) => t.id !== 'Lunch team');

			if (originalTeam !== undefined) {
				// Merge split up operations into a single unit
				for (let oi = 0; oi < copy[i].operations.length; oi++) {
					copy[i].operations[oi].teams = [
						{
							...originalTeam,
							start: copy[i].operations[oi].start,
							end: copy[i].operations[oi].end,
						},
					];
				}
			}

			// Write over existing room data with coloring result
			for (let newRoomData of opColorResult.data.operations)
				if (copy[i].id === newRoomData.id) copy[i] = newRoomData;
		}

		return Promise.resolve({
			operationRooms: copy,
			lunchTimes: copyLunchTimes,
		});
	};

	const fetchOverlap = async (
		operationRooms: Array<SpecOperationRoom>,
		disableRelief: Array<boolean>
	): Promise<Array<SpecOperationRoom>> => {
		const res = await axios.post<Array<SpecOperationRoom>>(
			'/model/overlap',
			operationRooms
		);
		const copy = [...res.data];
		for (let i = 0; i < copy.length; i++)
			if (disableRelief[i]) copy[i] = operationRooms[i];

		return Promise.resolve(copy);
	};

	const fetchColorsAndOverlap = async (
		operationRooms: Array<SpecOperationRoom>,
		disableRelief: Array<boolean>
	) => {
		return fetchColors(operationRooms, disableRelief).then((resColors) => {
			return fetchOverlap(resColors.operationRooms, disableRelief).then(
				(resOverlap) => {
					return {
						operationRooms: resColors.operationRooms,
						optimizedOperationRooms: resOverlap,
						lunchTimes: resColors.lunchTimes,
					};
				}
			);
		});
	};

	const lunchStart = getPercentage((LUNCH_START - ROOM_START) * 60, false);
	const lunchEnd = getPercentage((LUNCH_END - ROOM_START) * 60, false);

	const updateAllTracks = (or: Array<SpecOperationRoom>) => {
		const newTracks = or.map((room) => determineTracks(room.operations));
		setTracks(newTracks);
	};

	const determineTracks = (roomOps: Array<SpecOperation>) => {
		const newTrack: Tracks = {
			tracks: roomOps.map(() => 0),
			maxTracks: 1,
		};

		for (let i = 0; i < roomOps.length; i++) {
			for (let j = i + 1; j < roomOps.length; j++) {
				const opA = roomOps[i];
				const opB = roomOps[j];

				// Find collision
				if (isOverlapping(opA, opB)) {
					newTrack.tracks[j] += 1;
					newTrack.maxTracks += 1;
				}
			}
		}
		return newTrack;
	};

	/**
	 * Called when dropping an operation.
	 * Will check for valid moves: overlap and collisions with other operations
	 * @param e Object
	 * @param data DraggableData, used to third-party library
	 * @param opIndex number index of the draggable Operation
	 * @param roomIndex number index of the room, of the draggable Operation
	 */
	const dropHandle = (
		e: Object,
		data: DraggableData,
		opIndex: number,
		roomIndex: number
	) => {
		const newOptimized = [...optimizedSchedule];

		const newStart =
			60 * NUM_HOURS * (data.x / (ref?.clientWidth ? ref.clientWidth : 1200));
		const opA = moveTemporial(
			newOptimized[roomIndex].operations[opIndex],
			newStart
		);

		let collision = false;
		const overlapArr: Array<boolean> = [];

		/* Check for collision and overlaps with other operations in the same operating room */
		for (const item of newOptimized[roomIndex].operations) {
			if (collision === false && opA.id !== item.id)
				collision = isKnifeOverlapping(opA, item); // sets collision, only ONCE
			overlapArr.push(isOverlapping(opA, item));
		}

		const isOutsideBorder =
			HMToX(opA.roomStart) < 0 || HMToX(opA.roomEnd) > 16 * 60;

		if (!collision && !isOutsideBorder) {
			newOptimized[roomIndex].operations[opIndex] = opA;

			fetchColorsAndOverlap(newOptimized, noRelief).then((res) => {
				setOptimizedSchedule(res.optimizedOperationRooms);
				setLunchTimes(res.lunchTimes);
			});

			const newTracks = [...tracks];

			/** Getting a list of operations that overlaps with opA */
			const cand = overlapArr.reduce<Array<number>>((acc, item, ind) => {
				if (
					ind !== opIndex &&
					item &&
					!acc.includes(newTracks[roomIndex].tracks[ind])
				)
					acc.push(newTracks[roomIndex].tracks[ind]); // pushing trackNr
				return acc;
			}, []);

			// Getting the highest available/new trackNr
			let trackNr = 0;
			while (cand.includes(trackNr)) trackNr += 1;

			newTracks[roomIndex].tracks[opIndex] = trackNr;
			newTracks[roomIndex].maxTracks =
				Math.max(...tracks[roomIndex].tracks) + 1;

			setTracks(newTracks);
		}
	};

	const saveCustomized = () => {
		const caseCopy = [...cases];
		let customizedCase = caseCopy.findIndex(
			(c) => c.id === caseCopy[selectedCase].id + '-customized'
		);
		if (customizedCase === -1) {
			caseCopy.splice(selectedCase + 1, 0, {
				id: `${caseCopy[selectedCase].id}-customized`,
				operationRooms: optimizedSchedule,
			});
			customizedCase = selectedCase + 1;
		} else caseCopy[customizedCase].operationRooms = optimizedSchedule;

		axios
			.put<Array<SpecCase>>(
				`/simulation/operation/cases?date=${selectedDate.getFullYear()}-${(
					selectedDate.getMonth() + 1
				)
					.toString()
					.padStart(2, '0')}-${selectedDate
					.getDate()
					.toString()
					.padStart(2, '0')}`,
				caseCopy
			)
			.then(() => setCases(caseCopy));
	};

	return (
		<PageBox pageTitle={t('daily planning')}>
			<Box sx={{ display: 'flex' }}>
				<Autocomplete
					fullWidth
					value={cases.length > 0 ? cases[selectedCase].id : ''}
					disablePortal
					disabled={animating || fetching}
					options={cases
						.filter((c) => !c.id.includes('-customized'))
						.map((c) => c.id)}
					onChange={(_event, newValue) => {
						if (newValue !== null) {
							const index = cases.findIndex((c) => c.id === newValue);
							if (index !== -1) {
								setFetching(true);
								fetchColorsAndOverlap(
									cases[index].operationRooms,
									noRelief.map(() => false)
								).then((res) => {
									const caseCopy = [...cases];
									caseCopy[index].operationRooms = res.operationRooms;

									setSelectedCase(index);
									setCases(caseCopy);
									reset();

									const customizedCaseIndex = caseCopy.findIndex(
										(c) => c.id === `${caseCopy[index].id}-customized`
									);

									setOptimizedSchedule(
										customizedCaseIndex === -1
											? res.optimizedOperationRooms
											: caseCopy[customizedCaseIndex].operationRooms
									);
									setLunchTimes(res.lunchTimes);
									setFetching(false);
									updateAllTracks(res.optimizedOperationRooms);
								});
							}
						}
					}}
					renderInput={(params) => <TextField {...params} label={t('cases')} />}
					sx={{
						marginBottom: '1rem',
						marginRight: '1rem',
						backgroundColor: (t) => t.palette.common.white,
					}}
					noOptionsText={t('no options')}
				/>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
					<DatePicker
						disableMaskedInput
						value={selectedDate}
						onChange={(e) => {
							if (e !== null) setSelectedDate(e);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('selected day')}
								sx={{ height: '100%', background: 'white' }}
							/>
						)}
					/>
				</LocalizationProvider>
			</Box>

			{cases.length > 0 && (
				<>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<FormControl>
								<FormLabel>{t('drawing mode')}</FormLabel>
								<RadioGroup value={drawingMode} row name="drawing-modes">
									{Object.values(DrawingMode).map((mode) => (
										<FormControlLabel
											key={mode.valueOf()}
											disabled={fetching}
											value={mode.valueOf()}
											control={<Radio />}
											label={t(mode.valueOf()).toString()}
											onClick={() => setDrawingMode(mode)}
										/>
									))}
								</RadioGroup>
							</FormControl>
							<FormControl component="fieldset" variant="standard">
								<FormLabel component="legend">
									{t('disable lunch relief')}
								</FormLabel>
								<FormGroup row>
									{cases[selectedCase].operationRooms.map((room, roomIndex) => (
										<FormControlLabel
											key={room.id}
											control={
												<Checkbox
													checked={noRelief[roomIndex]}
													disabled={!enableLunchTeam}
													onChange={() => {
														const reliefCopy = [...noRelief];
														reliefCopy[roomIndex] = !reliefCopy[roomIndex];
														setFetching(true);

														fetchColorsAndOverlap(
															optimizedSchedule,
															reliefCopy
														).then((res) => {
															setFetching(false);
															setNoRelief(reliefCopy);

															setOptimizedSchedule(res.optimizedOperationRooms);
														});
													}}
													name="lunch relieve"
												/>
											}
											label={room.id}
										/>
									))}
								</FormGroup>
							</FormControl>
						</Box>
						<Box sx={{ alignSelf: 'end' }}>
							<Legend
								items={Object.keys(teamColors).map((team) => ({
									color: teamColors[team],
									name: team,
								}))}
							/>
						</Box>
					</Box>

					<Box ref={setRef}>
						{[
							...(enableLunchTeam
								? optimizedSchedule
								: cases[selectedCase].operationRooms),
						].map((room, roomIndex) => (
							<Paper
								key={room.id}
								elevation={0}
								sx={{
									position: 'relative',
									border: '1px solid black',
									background: `linear-gradient(90deg, #f0f0f0 ${lunchStart}%, #f0c8c8 ${lunchStart}%, #f0c8c8 ${lunchEnd}%, #f0f0f0 ${lunchEnd}%)`,
									borderRadius: '4px',
									marginBottom: GRID_GAP_SIZE,
									height:
										ROW_HEIGHT *
										(enableLunchTeam ? tracks[roomIndex].maxTracks : 1),
								}}
							>
								{room.operations.map((operation, operationIndex) => {
									return (
										<OperationBlock
											yPosition={
												ROW_HEIGHT *
												(enableLunchTeam
													? tracks[roomIndex].tracks[operationIndex]
													: 0)
											}
											key={operation.id}
											animating={animating}
											drawingMode={drawingMode}
											startPassed={
												markerPositionPercentage >=
												getPercentage(HMToX(operation.start), true)
											}
											mainTeam={`Team ${roomIndex + 1}`}
											operation={operation}
											teamColors={teamColors}
											translationBase={props.translationBase}
											enableLunchTeam={enableLunchTeam}
											parentWidth={ref === null ? 0 : ref.clientWidth}
											dropHandle={(e: Object, data: DraggableData) => {
												dropHandle(e, data, operationIndex, roomIndex);
											}}
											disabled={!enableLunchTeam}
										/>
									);
								})}
							</Paper>
						))}
						<Marker
							duration={ANIMATION_DURATION_MS}
							height={`calc(${GRID_GAP_SIZE} * ${
								cases[selectedCase].operationRooms.length
							} + ${
								(enableLunchTeam
									? tracks.reduce((acc, cur) => acc + cur.maxTracks, 0)
									: cases[selectedCase].operationRooms.length) * ROW_HEIGHT
							}px - 4px)`}
							parentWidth={ref === null ? 0 : ref.clientWidth}
							animating={animating}
							setAnimating={(val) => setAnimating(val)}
							markerPositionPercentage={markerPositionPercentage}
							setMarkerPositionPercentage={(val) =>
								setMarkerPositionPercentage(val)
							}
						/>
						<Timeline
							start={ROOM_START}
							end={ROOM_END}
							numHours={NUM_HOURS}
							gridGapSize={GRID_GAP_SIZE}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							columnGap: '5rem',
						}}
					>
						<Box>
							<FormControlLabel
								control={
									<Switch
										value={!enableLunchTeam}
										onClick={() => {
											setEnableLunchTeam(!enableLunchTeam);
											if (selectedTab === 2) setSelectedTab(0);
										}}
									/>
								}
								label={t(!enableLunchTeam ? 'original' : 'edit')}
							/>

							<Button
								disabled={fetching || !enableLunchTeam}
								onClick={saveCustomized}
								sx={{ ml: '1rem' }}
							>
								{t(
									cases.find(
										(c) => c.id === cases[selectedCase].id + '-customized'
									) !== undefined
										? 'update scenario'
										: 'create scenario'
								)}
							</Button>
						</Box>
						<Box>
							<Button
								disabled={markerPositionPercentage === 1 || fetching}
								onClick={() => setAnimating(!animating)}
							>
								{getSimulationButtonText()}
							</Button>
							<Button
								disabled={markerPositionPercentage === 0 || fetching}
								onClick={reset}
							>
								{<Replay />}
							</Button>
						</Box>
					</Box>
					<Tabs
						value={selectedTab}
						onChange={(_e, newValue) => setSelectedTab(Number(newValue))}
						TabIndicatorProps={{ sx: { background: 'white', height: '0px' } }}
					>
						<Tab
							disableRipple
							sx={{
								background: 'white',
								border: '1px solid rgb(204, 204, 204)',
								borderRightWidth: '0px',
								borderTopLeftRadius: '4px',
								borderBottomColor:
									selectedTab === 0 ? 'white' : 'rgb(204, 204, 204)',
							}}
							label={t('resource usage')}
						/>
						<Tab
							disableRipple
							sx={{
								background: 'white',
								border: '1px solid rgb(204, 204, 204)',
								borderBottomColor:
									selectedTab === 1 ? 'white' : 'rgb(204, 204, 204)',
							}}
							label="PostOP"
						/>
						<Tab
							disableRipple
							disabled={!enableLunchTeam}
							sx={{
								background: 'white',
								border: '1px solid rgb(204, 204, 204)',
								borderLeftWidth: '0px',
								borderTopRightRadius: '4px',
								borderBottomColor:
									selectedTab === 2 ? 'white' : 'rgb(204, 204, 204)',
							}}
							label={t('lunch times')}
						/>
					</Tabs>
					<Box
						sx={{
							border: '1px solid rgb(204, 204, 204)',
							marginTop: '-1px',
							borderRadius: '4px',
							borderTopLeftRadius: '0px',
							background: 'white',
							p: '1rem 1rem',
						}}
					>
						{cases.length > 0 && (
							<>
								<RoomStatisticsPanel
									value={selectedTab}
									index={0}
									enableLunchTeam={enableLunchTeam}
									case={cases[selectedCase]}
									optimizedSchedule={optimizedSchedule}
									historicalData={operationStartData}
									colors={roomColors}
									translationBase={`${props.translationBase}.daily planning`}
								/>
								<PostOPPanel
									operations={[
										...(enableLunchTeam
											? optimizedSchedule.map((r) => r.operations).flat()
											: cases[selectedCase].operationRooms
													.map((r) => r.operations)
													.flat()),
									]}
									translationBase={`${props.translationBase}.daily planning`}
									loadLimit={loadLimit}
									setLoadLimit={(newLimit) => setLoadLimit(newLimit)}
									value={selectedTab}
									index={1}
								/>
								<LunchReliefPanel
									value={selectedTab}
									index={2}
									teamColors={teamColors}
									lunchTimes={lunchTimes}
									showLunchrelief={enableLunchTeam}
									translationBase={`${props.translationBase}.daily planning`}
								/>
							</>
						)}
					</Box>
				</>
			)}
		</PageBox>
	);
}

export default DailyPlanningSO;
