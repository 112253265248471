import { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Box,
	Tooltip,
	MenuItem,
	Menu,
	Button,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserDialog from './Topbar/UserDialog';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../AppTheme';

type LinkType = {
	name: string;
	url: string;
	sublinks: Array<{
		name: string;
		url: string;
	}>;
};

export const LINKS: Array<LinkType> = [
	{
		name: 'clinical physiology',
		url: 'clinical-physiology',
		sublinks: [
			{ name: 'referral overview', url: 'referral-overview' },
			{ name: 'referral types', url: 'referral' },
			{ name: 'staff', url: 'staff' },
			{ name: 'room', url: 'room' },
			{ name: 'planning', url: 'simulation' },
			{ name: 'daily planning', url: 'daily-planning' },
		],
	},
	{
		name: 'operation',
		url: 'surgery',
		sublinks: [
			{ name: 'daily planning', url: 'daily-planning' },
			{ name: 'postop', url: 'post-op' },
			{ name: 'staff', url: 'staff' },
			{ name: 'teams', url: 'teams' },
			{ name: 'operation room', url: 'room' },
			{ name: 'start overview', url: 'start-overview' },
		],
	},
];

const FirstToolbar = styled(Toolbar)(({ theme }) => ({
	margin: 'auto',
	display: 'flex',
	justifyContent: 'space-between',
	minWidth: theme.breakpoints.values.lg,

	'& div:first-of-type': {
		display: 'flex',
		gap: theme.spacing(4),
		alignItems: 'center',
		a: {
			textDecoration: 'none',

			'.MuiTypography-root': {
				color: '#fff',
			},

			'.superlink-active, .MuiTypography-root:hover': {
				textDecoration: 'underline',
				transition: '0.5s ease',
			},
		},
	},

	'& div:nth-of-type(2)': {
		display: 'flex',
		alignItems: 'center',
	},

	// Color for icons
	svg: {
		color: theme.palette.common.white,
	},
}));

function Topbar(props: {
	keycloak: Keycloak.KeycloakInstance;
	translationBase: string;
}) {
	const [userSelection, setUserSelection] = useState(0);
	const [openUserDialog, setOpenUserDialog] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const tm = useContext(AppThemeContext);

	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname
		.substring(1, location.pathname.length)
		.split('/');
	const activeSubLinkName = path.length === 2 ? path[1] : '';

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<>
			<UserDialog
				open={openUserDialog}
				translationBase={props.translationBase}
				handleClose={setOpenUserDialog}
				keycloak={props.keycloak}
			/>
			<AppBar
				elevation={1}
				position="fixed"
				sx={{
					backgroundColor: tm.paletteColors.green.dark,
				}}
			>
				<FirstToolbar variant="dense" disableGutters>
					<Box sx={{ height: '48px' }}>
						{LINKS[userSelection].sublinks.map((item, index) => (
							<Link
								key={index}
								to={`/${LINKS[userSelection].url}/${LINKS[userSelection].sublinks[index].url}`}
								style={{
									backgroundColor:
										LINKS[userSelection].sublinks[index].url ===
										activeSubLinkName
											? 'rgba(0, 0, 0, 0.25)'
											: undefined,
									height: 'inherit',
									display: 'flex',
									padding: '0px 8px',
								}}
							>
								<Typography
									variant="subtitle1"
									sx={{ width: 'max-content', my: 'auto' }}
								>
									{t(item.name)}
								</Typography>
							</Link>
						))}
					</Box>
					<Box>
						<Box
							sx={{
								backgroundColor: 'rgba(0, 0, 0, 0.25)',
								height: '48px',
								padding: '.25rem',
							}}
						>
							<Button
								disableRipple
								id="basic-button"
								onClick={(event) => setAnchorEl(event.currentTarget)}
								sx={{ color: 'white', cursor: 'pointer' }}
							>
								{t(LINKS[userSelection].name)}
							</Button>
							<Menu
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								onClick={() => setAnchorEl(null)}
								sx={{ mt: '7px' }}
							>
								{LINKS.map((item, index) => (
									<MenuItem
										key={item.name}
										value={index}
										onClick={() => {
											setUserSelection(index);
											navigate(
												`/${LINKS[index].url}/${LINKS[index].sublinks[0].url}`
											);
										}}
									>
										<Typography>{t(item.name)}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box
							onClick={() => setOpenUserDialog(true)}
							sx={{ cursor: 'pointer' }}
						>
							<IconButton aria-label="user settings">
								<AccountCircleIcon />
							</IconButton>
							<Tooltip
								title={`${t('logged in as')} ${
									props.keycloak.profile?.firstName
								} ${props.keycloak.profile?.lastName}`}
							>
								<Typography variant="body2" component="div">
									{props.keycloak.profile?.username}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
				</FirstToolbar>
			</AppBar>
		</>
	);
}

export default Topbar;
