export function GetIntervalString(
	hours: number = 0,
	minutes: number = 0,
	seconds: number = 0
): string {
	let totalMinutes = minutes + Math.floor(seconds / 60);
	let totalHours = hours + Math.floor(totalMinutes / 60);

	totalMinutes %= 60;

	return `${totalHours}:${totalMinutes}:${seconds % 60}`;
}

export function GetIntervalInMinutes(intervall: string): number {
	const parts = intervall.split(':').map((number) => Number(number));

	if (parts.length !== 3) return 0;

	return parts[0] * 60 + parts[1] + Math.floor(parts[2] / 60);
}

export function padSingleDigit(val: number): string {
	return val < 10 ? `0${val}` : val.toString();
}

export function merge<T>(orig: Array<T>, back: Array<any>, key: string = 'id') {
	const container: Array<T> = [];
	for (const item of back) {
		const index = orig.findIndex((s) => (s as any)[key] === item[key]);
		if (index !== -1)
			container.push({
				...orig[index],
				...item,
			});
	}
	return container;
}

export function groupArray<T>(key: string, arr: Array<T>) {
	const res : {[name: string]: T} = {};
	for (const item of arr)
		res[(item as any)[key]] = item;
	return res;
}