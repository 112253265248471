import { Destination, SchedulingType } from '../Common/Types';

export enum Gender {
	Male = 0,
	Female = 1,
}

export const Genders = ['Man', 'Kvinna'];

export enum OperatingUnit {
	Gynekologi = 0,
	Kirurgi = 1,
	Ortopedi = 2,
	Urologi = 3,
	Underhall = 4,
}

export const OperatingUnits = [
	'gynecology',
	'surgery',
	'orthopedics',
	'urology',
	'maintenance',
];

export type Link = {
	source: string;
	target: string;
	value: number;
};

export class OperationPostOP {
	id: number;
	action: string;
	opDuration: number;
	urgency: SchedulingType;
	operatingUnit: number;
	age: number;
	asa: number;
	opStart: number;
	opEnd: number;
	gender: Gender;
	name: string;
	destinations: Array<Destination>;

	constructor(
		id: number,
		action: string,
		opDuration: number,
		operatingUnit: number,
		urgency: SchedulingType,
		age: number = 0,
		asa: number = 0,
		opStart: number = 0,
		opEnd: number = opStart + opDuration,
		gender: Gender = Gender.Female,
		name: string = 'N.N',
		destinations: Array<Destination>
	) {
		this.id = id;
		this.action = action;
		this.opDuration = opDuration;
		this.urgency = urgency;
		this.operatingUnit = operatingUnit;
		this.age = age;
		this.asa = asa;
		this.opStart = opStart;
		this.opEnd = opEnd;
		this.gender = gender;
		this.name = name;
		this.destinations = destinations;
	}
}

export type DragOperation = {
	operation: OperationPostOP;
	sourceKey: string;
	sourceIndex: number;
	sourceWidth: number;
	sourceHeight: number;
	color: string;
};
