import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
	en: {
		translation: {
			components: {
				weekpicker: {
					week: 'Week',
					of: 'of',
				},
			},
			topbar: {
				'logged in as': 'Logged in as',
				'user settings': 'User settings',
				email: 'Email',
				'log out': 'LOG OUT',
				close: 'CLOSE',
				swedish: 'Swedish',
				english: 'English',
				language: 'Language',
				'dark mode': 'Dark mode',
				'app version': 'App version',
				'clinical physiology': 'Clinical physiology',
				'referral overview': 'Referral overview',
				planning: 'Planning',
				'daily planning': 'Daily Planning',
				staff: 'Staff',
				room: 'Room',
				'referral types': 'Referral types',
				simulation: 'Simulation',
				operation: 'Operation',
				waitlist: 'Waitlist',
				postop: 'PostOP',
				teams: 'Teams',
				'operation room': 'OR',
				'start overview': 'Start overview',
			},
			home: {
				palette: 'Palette',
			},
			'clinical physiology': {
				'referral overview': {
					'referral overview': 'Referral overview',
					priorities: 'Priorities',
					'referrals received': 'Referrals received',
					quantity: 'Quantity',
					deadlines: 'Deadlines',
				},
				planning: {
					planning: 'Planning',
				},
				'daily planning': {
					'daily planning': 'Daily Planning',
					mls: 'MLS',
					patient: 'Patient',
					'social security number': 'Social security number',
					type: 'Type',
					doctor: 'Doctor',
					'start time': 'Start time',
					'estimated time': 'Estimated time',
				},
				staff: {
					staff: 'Staff',
					'staff view': 'Staff view',
					'add staff': 'Add staff',
					'add new staff': 'Add new staff',
					cancel: 'cancel',
					'qualifications list': 'Qualifications list',
					BMA: 'MLS',
					Läkare: 'Doctor',
					search: 'Search',
					name: 'Name',
					abbreviation: 'Abbreviation',
					profession: 'Profession',
					'choose a profession': 'Choose a profession',
					qualifications: 'Qualifications',
					production: 'Exam',
					interpret: 'Interpet',
					assist: 'Assist',
					'needs assist': 'Needs assistance',
					'exam duration': 'Examination duration',
					'interpret duration': 'Interpret duration',
					'no options': 'No options',
					'degree of service': 'Degree of service',
					'staff id': 'Staff id',
					'are you sure that you want to delete':
						'Are you sure that you want to delete',
					delete: 'DELETE',
					reset: 'RESET',
					update: 'UPDATE',
					w: 'W.',
					mon: 'Mon',
					tue: 'Tue',
					wed: 'Wed',
					thu: 'Thu',
					fri: 'Fri',
					sat: 'Sat',
					sun: 'Sun',
				},
				room: {
					room: 'Room',
					name: 'Name',
					abbreviation: 'Abbreviation',
					'available for': 'Available for',
					equipment: 'Equipment',
					'update room': 'UPDATE ROOM',
					reset: 'RESET',
					'remove room': 'REMOVE ROOM',
					'verify removal of room': 'Verify removal of room',
					'remove room text':
						'A removed room will lose all of its connections, this can not be automatically reverted.',
					cancel: 'CANCEL',
					remove: 'REMOVE',
					'create new room': 'Create new room',
					'create room': 'CREATE ROOM',
				},
				'referral types': {
					'referral types': 'Referral types',
					'examination time': 'Examination time',
					'interpreting time': 'Interpreting time',
					'update referral type': 'Update referral type',
					minutes: 'Minutes',
					'examination time for mls': 'Examination time for MLS',
					'examination time for doctor': 'Examination time for doctor',
					'interpretation time': 'Interpretation time',
					name: 'Name',
					abbreviation: 'Abbreviation',
					update: 'Update',
					reset: 'Reset',
					remove: 'Remove',
					'verify removal of referral': 'Verify removal of referral',
					'remove referral text':
						'A removed referral will lose all of its relations to rooms and staff, this can not be automatically reverted.',
					cancel: 'Cancel',
					'create new referral': 'Create new referral',
					'create referral': 'CREATE REFERRAL',
				},
				simulation: {
					planning: 'Planning',
					'selected day': 'Selected day',
					warning: 'Warning',
					cancel: 'Cancel',
					'is not working today': 'is not working today',
					'choose new working hours': 'Choose new working hours',
					MORNING: 'Morning',
					AFTERNOON: 'Afternoon',
					WHOLE_DAY: 'Whole day',
					UNAVAILABLE: 'Unavailable',
					referrals: 'Referrals',
					'total referrals': '{{count}} referrals',
					'not shown referrals': '{{count}} referrals are not shown',
					none: 'None',
					actual: 'Actual queue',
					diverse: 'Diverse mix',
					count: 'Count',
					'interpret filter': 'Interpret filter',
					'filter tooltip': 'Filter referrals based on interpretation coverage',
					'show interpretation times': 'Show interpretation times',
					'choose MLS': 'Choose MLS',
					'MLS staff': 'MLS staff',
					'no options': 'No options',
					'choose doctors': 'Choose Doctors',
					'doctor staff': 'Doctor staff',
					'customize referrals': 'Customize referrals',
					'referrals total': 'Referrals total',
					'choose rooms': 'Choose rooms',
					reset: 'Reset',
					plan: 'Plan',
					save: 'Save',
					'confirm save': 'Confirm save',
					date: 'Date',
					doctor: 'Doctor',
					'send notification': 'Send notification',
					'simulation settings': 'Simulation settings',
					'timelimits (seconds)': 'Timelimits (seconds)',
					'unavailable staff': 'Unavailable staff',
					'predefined templates': 'Predefined templates',
					'choose template': 'Choose template',
					'reserved emergency': 'Reserved emergency time slots',
					'reserved referrals': 'Reserved elective referrals',
					selection: 'Selection',
					sequencing: 'Sequencing',
					'time is split equally on each room':
						'Time is split equally on each room',
					name: 'Name',
					type: 'Type',
					room: 'Room',
					start: 'Start',
					end: 'End',
					minutes: 'Minutes',
					duration: 'Duration',
					interpreter: 'Interpreter',
					confirm: 'Confirm',
					close: 'Close',
					'room view': 'Room view',
					'interpret view': 'Interpret view',
					'statistics view': 'Statistics view',
					'staff view': 'Staff view',
					settings: 'Settings',
					referral: 'Referral',
					priority: 'Priority',
					MLS: 'MLS',
					mode: 'Mode',
					selected: 'Selected',
					simulation: 'Simulation',
					'assign referral to scheduled time':
						'Assign referral to scheduled time',

					// Interpret panel
					'num referrals': 'Number of referrals',
					'accumulated interpret duration': 'Accumulated interpret duration',

					'est. interpretation time (minutes)':
						'Est. interpretation time (minutes)',
					'scheduling dialog': {
						planning: 'Planning',
						selection: 'Selection',
						'optimal solution found': 'Optimal solution found',
						'solution found': 'Solution found',
						'referrals selected': '{{count}} referrals selected',
						'preview of selected referrals': 'Preview of selected referrals',
						scheduled: 'Scheduled',
						acute: 'Acute',
						planned: 'Planned',
						lunch: 'Lunch',
						sequencing: 'Sequencing',
						loading: 'Loading',
						done: 'Done',
						cancel: 'Cancel',
						view: 'View',
						'simulation failed': 'Simulation failed',
					},
					'statistics panel': {
						'resource utilization': 'Resource utilization',
						hours: 'Hours',
						minutes: 'Minutes',
						'exam distribution': 'Exam distribution',
						quantity: 'Quantity',
						referral: 'Referral',
						'referral type': 'Referral type',
						incoming: 'Arrived',
						deadline: 'Deadline',
						scheduled: 'Scheduled',
						priority: 'Priority',
						'days late': 'Days late',
					},
				},
			},

			operation: {
				waitlist: {
					waitlist: 'Waitlist',
				},
				planning: {
					planning: 'Planning',
				},
				'daily planning': {
					'daily planning': 'Daily planning',
					cases: 'Cases',
					'selected day': 'Selected day',
					'no options': 'No options',
					'drawing mode': 'Drawing mode',
					always: 'Always',
					'after operation start': 'After operation start',
					'disable lunch relief': 'Disable lunch relief',
					name: 'Name',
					start: 'Start',
					'critical start': 'Critical start',
					'critical end': 'Critical end',
					end: 'End',
					'lunch times': 'Lunch times',
					edit: 'Edit',
					original: 'Original',
					'create scenario': 'Create scenario',
					'update scenario': 'Update scenario',
					none: 'None',
					'resource usage': 'Resource usage',
					'room usage panel': {
						minutes: 'Minutes',
						hours: 'Hours',
						'start view': 'Start view',
						'guide value': 'Optimal start time',
						'relative view': 'Relative view',
						'knife start': 'Knife start',
						'room start': 'Room start',
						'show historical data': 'Show historical data',
						'room usage': 'Room usage',
						'knife usage': 'Knife usage',
						'historic room usage': 'Historical room usage',
						'historic knife usage': 'Historical knife usage',
						'historic room start': 'Historic room start',
						'historic knife start': 'Historic suregon start',
						streamlining: 'Streamlining',
					},
					'postop panel': {
						'number of beds': 'Number of beds',
						frequency: 'Frequency',
						time: 'Time',
						'max load': 'Max load',
						below: 'Below',
					},
					'lunch times panel': {
						'lunch relief': 'Lunch relief',
						'lunch times': 'Lunch times',
						none: 'None',
					},
				},
				postop: {
					postop: 'PostOP',
					'waiting list': 'Waiting list',
					'surgery program': 'Surgery program',
					'load graph': 'Load graph',
					'patient flow graph': 'Patient flow graph',
					gynecology: 'Gynecology',
					urology: 'Urology',
					surgery: 'Surgery',
					orthopedics: 'Orthopedics',
					maintenance: 'Maintenance',
					'operating room': 'Operating room',
					minutes: 'Minutes',
					'minutes end': 'Minutes, ends',
					'number of beds': 'Number of beds',
					frequency: 'Frequency',
					time: 'Time',
					'max load': 'Max load',
					below: 'Below',
				},
				staff: {
					staff: 'Staff',
					'staff view': 'Staff view',
					Operationssjuksköterska: 'Surgical nurse',
					Anestesisjuksköterska: 'Anesthesia nurse',
					Operationsundersköterska: 'Surgical assistant nurse',
					Anestesiundersköterska: 'Anesthesia assistant nurse',
					Steriltekniker: 'Sterile technician',
					Samordnare: 'Coordinator',
					'add staff': 'Add staff',
					'choose a profession': 'Choose a profession',
					abbreviation: 'Abbreviation',
					cancel: 'cancel',
					delete: 'delete',
					'are you sure that you want to delete':
						'Are you sure that you want to delete',
					'add new staff': 'Add new staff',
					create: 'create',
					name: 'Name',
					profession: 'Profession',
					'degree of service': 'Degree of service',
					reset: 'RESET',
					update: 'UPDATE',
				},
				teams: {
					teams: 'Teams',
					'selected day': 'Selected day',
					staff: 'Staff',
					reset: 'Reset',
					distribute: 'Distribute',
					'settings for': 'Settings for',
					profession: 'Profession',
					'allowed team placement': 'Allowed team placement',
					available: 'Available',
					close: 'Close',
					'team constraint': 'Team constraint',
					'add new constraint': 'Add new constraint',
					'choose a team': 'Choose a team',
					'choose a constraint': 'Choose a constraint',
					constraint: 'Constraint',
					view: 'View',
					'model infeasible': 'Model infeasible',
					'move leftover staff': 'Move all leftover staff to Korridorsteam.',
				},
				'operation room': {
					'operation room': 'OR',
					'operation rooms': 'Operation rooms',
					'available for': 'Available for',
					equipment: 'Equipment',
					elective: 'Elective',
					acute: 'Acute',
					'equipment description is not yet available':
						'Equipment description is not yet available',
				},
				'start overview': {
					'start overview': 'Start overview',
					'relative view': 'Relative view',
					'choose rooms': 'Choose rooms',
					monday: 'Monday',
					tuesday: 'Tuesday',
					wednesday: 'Wednesday',
					thursday: 'Thurday',
					friday: 'Friday',
					saturday: 'Saturday',
					sunday: 'Sunday',
					'operationroom 1': 'Operationroom 1',
					'operationroom 2': 'Operationroom 2',
					'operationroom 3': 'Operationroom 3',
					'operationroom 4': 'Operationroom 4',
					'guide value': 'Guide value',
				},
			},
		},
	},
	sv: {
		translation: {
			components: {
				weekpicker: {
					week: 'Vecka',
					of: 'av',
				},
			},
			topbar: {
				'logged in as': 'Inloggad som',
				'user settings': 'Användarinställningar',
				email: 'E-post',
				'log out': 'LOGGA UT',
				close: 'STÄNG',
				swedish: 'Svenska',
				english: 'Engelska',
				language: 'Språk',
				'dark mode': 'Mörkt läge',
				'app version': 'Programvaruversion',
				'clinical physiology': 'Klinfys',
				'referral overview': 'Översikt remisser',
				planning: 'Planera',
				'daily planning': 'Daglig Planering',
				staff: 'Personal',
				room: 'Rum',
				'referral types': 'Remisstyper',
				simulation: 'Simulering',
				operation: 'Operation',
				waitlist: 'Väntelista',
				postop: 'PostOP',
				teams: 'Teams',
				'operation room': 'Opsalar',
				'start overview': 'Startöverblick',
			},
			home: {
				palette: 'Palett',
			},
			'clinical physiology': {
				'referral overview': {
					'referral overview': 'Översikt remisser',
					priorities: 'Prioriteringar',
					'referrals received': 'Inkomna remisser',
					quantity: 'Antal',
					deadlines: 'Deadlines',
				},
				planning: {
					planning: 'Planering',
				},
				'daily planning': {
					'daily planning': 'Daglig Planering',
					mls: 'BMA',
					patient: 'Patient',
					'social security number': 'Personnummer',
					type: 'Typ',
					doctor: 'Läkare',
					'start time': 'Starttid',
					'estimated time': 'Estimerad tid',
				},
				staff: {
					staff: 'Personal',
					'staff view': 'Personalvy',
					'add staff': 'Lägg till personal',
					'add new staff': 'Lägg till ny personal',
					cancel: 'Tillbaka',
					'qualifications list': 'Kompetenser',
					BMA: 'BMA',
					Läkare: 'Läkare',
					search: 'Sök',
					name: 'Namn',
					abbreviation: 'Akronym',
					profession: 'Yrke',
					qualifications: 'Kompetenser',
					production: 'Kan utföra',
					interpret: 'Kan tolka',
					assist: 'Kan assistera',
					'needs assist': 'Behöver assistans',
					'exam duration': 'Undersökningstid',
					'interpret duration': 'Tolkningstid',
					'choose a profession': 'Välj ett yrke',
					'no options': 'Inga alternativ',
					'degree of service': 'Tjänstgöringsgrad',
					'staff id': 'Personal id',
					'are you sure that you want to delete':
						'Är du säker på att du vill radera',
					delete: 'RADERA',
					reset: 'ÅTERSTÄLL',
					update: 'UPPDATERA',
					w: 'V.',
					mon: 'mån',
					tue: 'Tis',
					wed: 'Ons',
					thu: 'Tor',
					fri: 'Fre',
					sat: 'Lör',
					sun: 'Sön',
				},
				room: {
					room: 'Rum',
					name: 'Namn',
					abbreviation: 'Förkortning',
					'available for': 'Tillgänglig för',
					equipment: 'Utrustning',
					'update room': 'UPPDATERA RUM',
					reset: 'NOLLSTÄLL',
					'remove room': 'TA BORT RUM',
					'verify removal of room': 'Verifiera borttagning av rum',
					'remove room text':
						'Ett borttaget rum förlorar alla dess relationer, detta kan ej automatiskt återställas.',
					cancel: 'TILLBAKA',
					remove: 'TA BORT',
					'create new room': 'Skapa nytt rum',
					'create room': 'SKAPA RUM',
				},
				'referral types': {
					'referral types': 'Remisstyper',
					'examination time': 'Undersökningstid',
					'interpreting time': 'Tolktid',
					'update referral type': 'Uppdatera remisstyp',
					minutes: 'Minuter',
					'examination time for mls': 'Undersökningstid för BMA',
					'examination time for doctor': 'Undersökningstid för läkare',
					'interpretation time': 'Tolkningstid',
					name: 'Namn',
					abbreviation: 'Förkortning',
					update: 'UPPDATERA',
					reset: 'NOLLSTÄLL',
					remove: 'TA BORT',
					'verify removal of referral': 'Verifiera borttagning av remiss',
					'remove referral text':
						'En borttagen remiss förlorar alla dess relationer till bland annat rum och personal, detta kan ej automatiskt återställas.',
					cancel: 'TILLBAKA',
					'create new referral': 'Skapa ny remiss',
					'create referral': 'SKAPA REMISS',
				},
				simulation: {
					planning: 'Planera',
					'selected day': 'Vald dag',
					warning: 'Varning',
					cancel: 'Tillbaka',
					'is not working today': 'arbetar inte idag',
					MORNING: 'Förmiddag',
					AFTERNOON: 'Eftermiddag',
					WHOLE_DAY: 'Hela dagen',
					UNAVAILABLE: 'Otillgänglig',
					'choose new working hours': 'Välj ny arbetstid',
					referrals: 'Remisser',
					'total referrals': '{{count}} remisser',
					'not shown referrals': '{{count}} remisser visas ej',
					none: 'Inga',
					actual: 'Aktuell kö',
					diverse: 'Simuleringsmix',
					count: 'Antal',
					'interpret filter': 'Tolkningsfilter',
					'filter tooltip': 'Filtrera undersökningar baserat på tolkningsstöd',
					'show interpretation times': 'Visa tolkningstider',
					'choose MLS': 'Välj BMA',
					'MLS staff': 'BMA personal',
					'no options': 'Inga alternativ',
					'choose doctors': 'Välj läkare',
					'doctor staff': 'Läkare',
					'customize referrals': 'Remissinställningar',
					'referrals total': 'Antal remisser',
					'choose rooms': 'Välj rum',
					reset: 'Nollställ',
					plan: 'Planera',
					save: 'Spara',
					'confirm save': 'Bekräfta sparning',
					date: 'Datum',
					doctor: 'Doktor',
					'send notification': 'Skicka notifikation',
					'simulation settings': 'Simuleringsinställningar',
					'timelimits (seconds)': 'Tidsbegränsningar (sekunder)',
					selection: 'Selektion',
					sequencing: 'Sekvensiering',
					'time is split equally on each room':
						'Tiden blir jämt fördelad för varje rum',
					'unavailable staff': 'Otillgänglig personal',
					'predefined templates': 'Fördefinierade scenario',
					'choose template': 'Välj scenario',
					'reserved emergency': 'Reserverade akuttider',
					'reserved referrals': 'Reserverade elektiva remisser',
					name: 'Namn',
					type: 'Typ',
					room: 'Rum',
					start: 'Start',
					end: 'Slut',
					minutes: 'Minuter',
					duration: 'Längd',
					interpreter: 'Tolkare',
					confirm: 'Bekräfta',
					close: 'Stäng',
					'room view': 'Rumsvy',
					'interpret view': 'Tolkningsvy',
					'statistics view': 'Statistikvy',
					'staff view': 'Personalvy',
					settings: 'Inställningar',
					referral: 'Remiss',
					priority: 'Prioritet',
					MLS: 'BMA',
					mode: 'Läge',
					selected: 'Vald',
					simulation: 'Simulering',
					'assign referral to scheduled time':
						'Tilldela remiss till schemalagd tid',

					// Interpret panel
					'num referrals': 'Antal remisser',
					'accumulated interpret duration': 'Total tolkningstid',
					'est. interpretation time (minutes)': 'Est. tolkningstid (minutes)',
					'scheduling dialog': {
						planning: 'Planerar',
						selection: 'Selektion',
						'optimal solution found': 'Optimal lösning hittad',
						'solution found': 'Lösning hittad',
						'referrals selected': '{{count}} remisser valda',
						'preview of selected referrals': 'Förhandsvisning av valda tider',
						scheduled: 'Schemalagd',
						acute: 'Akut',
						planned: 'Planerad',
						lunch: 'Lunch',
						sequencing: 'Sekvensiering',
						loading: 'Laddar',
						done: 'Klar',
						cancel: 'Avbryt',
						view: 'Visa',
						'simulation failed': 'Simuleringen misslyckades',
					},
					'statistics panel': {
						'resource utilization': 'Resursanvändning',
						minutes: 'Minuter',
						hours: 'Timmar',
						'exam distribution': 'Undersökningsfördelning',
						quantity: 'Antal',
						referral: 'Remiss',
						'referral type': 'Remisstyp',
						incoming: 'Inkom',
						deadline: 'Deadline',
						scheduled: 'Schemalagd',
						priority: 'Prioritet',
						'days late': 'Dagar försenad',
					},
				},
			},

			operation: {
				waitlist: {
					waitlist: 'Väntelista',
				},
				planning: {
					planning: 'Planering',
				},
				'daily planning': {
					'daily planning': 'Daglig planering',
					cases: 'Scenarios',
					'selected day': 'Vald dag',
					'no options': 'Inga alternativ',
					'drawing mode': 'Ritningsläge',
					always: 'Alltid',
					'after operation start': 'Efter påbörjad operation',
					'disable lunch relief': 'Inaktivera lunchavlösning',
					name: 'Namn',
					start: 'Start',
					'critical start': 'Kritiskt start',
					'critical end': 'Kritiskt slut',
					end: 'Slut',
					'lunch times': 'Lunchtider',
					edit: 'Redigera',
					original: 'Original',
					'create scenario': 'Skapa scenario',
					'update scenario': 'Uppdatera scenario',
					none: 'Ingen',
					'resource usage': 'Resursanvändning',
					'room usage panel': {
						minutes: 'Minuter',
						hours: 'Timmar',
						'start view': 'Startvy',
						'guide value': 'Optimal starttid',
						'relative view': 'Relativ vy',
						'knife start': 'Kniv start',
						'room start': 'Sal start',
						'show historical data': 'Visa historisk data',
						'room usage': 'Salsutnyttjande',
						'knife usage': 'Knivtid',
						'historic room usage': 'Historisk salsutnyttjande',
						'historic knife usage': 'Historisk knivtid',
						'historic room start': 'Historisk sal start',
						'historic knife start': 'Historisk kirurgi start',
						streamlining: 'Effektivisering',
					},
					'postop panel': {
						'number of beds': 'Antal sängplatser',
						frequency: 'Antal',
						time: 'Tid',
						'max load': 'Maximal belastning',
						below: 'Under',
					},

					'lunch times panel': {
						'lunch relief': 'Lunchavlösning',
						'lunch times': 'Lunchtider',
						none: 'Ingen',
					},
				},
				postop: {
					postop: 'PostOP',
					'waiting list': 'Väntelista',
					'surgery program': 'Operationsprogram',
					'load graph': 'Belastningsgraf',
					'patient flow graph': 'Patientflödesgraf',
					gynecology: 'Gynekologi',
					urology: 'Urologi',
					surgery: 'Kirurgi',
					orthopedics: 'Ortopedi',
					maintenance: 'Uppehåll',
					'operating room': 'Operationssal',
					minutes: 'Minuter',
					'minutes end': 'Minuter, slutar',
					'number of beds': 'Antal sängplatser',
					frequency: 'Antal',
					time: 'Tid',
					'max load': 'Maximal belastning',
					below: 'Under',
				},
				staff: {
					staff: 'Personal',
					'staff view': 'Personalvy',
					Operationssjuksköterska: 'Operationssjuksköterska',
					Anestesisjuksköterska: 'Anestesisjuksköterska',
					Operationsundersköterska: 'Operationsundersköterska',
					Anestesiundersköterska: 'Anestesiundersköterska',
					Steriltekniker: 'Steriltekniker',
					Samordnare: 'Samordnare',
					'add staff': 'Lägg till personal',
					'choose a profession': 'Välj ett yrke',
					abbreviation: 'Akronym',
					cancel: 'Tillbaka',
					delete: 'Radera',
					'are you sure that you want to delete':
						'Är du säker på att du vill radera',
					'add new staff': 'Lägg till ny personal',
					create: 'Skapa',
					name: 'Namn',
					profession: 'Yrke',
					'degree of service': 'Tjänstgöringsgrad',
					reset: 'ÅTERSTÄLL',
					update: 'UPPDATERA',
				},
				teams: {
					teams: 'Teams',
					'selected day': 'Vald dag',
					staff: 'Personal',
					reset: 'Nollställ',
					distribute: 'Distribuera',
					'settings for': 'Inställningar för',
					profession: 'Yrke',
					'allowed team placement': 'Tillåtna teamplaceringar',
					available: 'Tillgänglig',
					close: 'Stäng',
					'team constraint': 'Team begränsning',
					'add new constraint': 'Lägg till ny begränsing',
					'choose a team': 'Välj ett team',
					'choose a constraint': 'Välj en begränsing',
					constraint: 'Begränsning',
					view: 'Vy',
					'model infeasible': 'Modell omöjlig',
					'move leftover staff':
						'Flytta överbliven personal till Korridorsteam.',
				},
				'operation room': {
					'operation room': 'Opsalar',
					'operation rooms': 'Operationssalar',
					'available for': 'Tillgänglig för',
					equipment: 'Utrustning',
					elective: 'Elektiv',
					acute: 'Akut',
					'equipment description is not yet available':
						'Utrustningens beskrivning är för tillfället ej tillgänglig',
				},
				'start overview': {
					'start overview': 'Startöverblick',
					'relative view': 'Relativ vy',
					'choose rooms': 'Välj rum',
					monday: 'Måndag',
					tuesday: 'Tisdag',
					wednesday: 'Onsdag',
					thursday: 'Torsdag',
					friday: 'Fredag',
					saturday: 'Lördag',
					sunday: 'Söndag',
					'operationroom 1': 'Operationsrum 1',
					'operationroom 2': 'Operationsrum 2',
					'operationroom 3': 'Operationsrum 3',
					'operationroom 4': 'Operationsrum 4',
					'guide value': 'Riktvärde',
				},
			},
		},
	},
};

i18n.use(initReactI18next).init({
	resources: resources,
	lng: 'sv',
	fallbackLng: 'en',
	//debug: true,

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
