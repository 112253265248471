import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Staff } from '../Types';
import { TimeSlot } from '../../Common/Types';

export default function ConfirmStaffSelectionDialog(props: {
	open: boolean;
	close: () => void;
	staff: Staff;
	staffIndex: number;
	confirm: (newTimeSlot: TimeSlot, staffIndex: number) => void;
	workTimeColors: {
		red: string;
		yellow: string;
		green: string;
	};
	translationBase: string;
}) {
	const [selectedTimeSlot, setSelectedTimeSlot] =
		useState<TimeSlot>('UNAVAILABLE');
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setSelectedTimeSlot(props.staff.workTime), [props.open]);

	const timeSlots: Array<{ time: TimeSlot; color: string }> = [
		{ time: 'UNAVAILABLE', color: props.workTimeColors.red },
		{ time: 'MORNING', color: props.workTimeColors.yellow },
		{ time: 'AFTERNOON', color: props.workTimeColors.yellow },
		{ time: 'WHOLE_DAY', color: props.workTimeColors.green },
	];

	return (
		<Dialog open={props.open} onClose={props.close}>
			{props.staffIndex === -1 && <DialogTitle>{t('warning')}!</DialogTitle>}
			<DialogContent>
				{props.staffIndex === -1 && (
					<DialogContentText>
						{`${props.staff.name} ${t('is not working today')}`}.
					</DialogContentText>
				)}
				<FormControl sx={{ marginTop: '0.25rem' }}>
					<FormLabel>{t('choose new working hours')}</FormLabel>
					<RadioGroup name="work-time">
						{timeSlots.map((timeSlot) => (
							<FormControlLabel
								key={timeSlot.time}
								value={timeSlot.time}
								disabled={timeSlot.time === 'UNAVAILABLE'}
								control={
									<Radio
										sx={{ marginLeft: '1rem' }}
										checked={timeSlot.time === selectedTimeSlot}
									/>
								}
								label={t(timeSlot.time)}
								onClick={() => {
									if (timeSlot.time !== 'UNAVAILABLE')
										setSelectedTimeSlot(timeSlot.time);
								}}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} autoFocus>
					{t('cancel')}
				</Button>
				<Button
					disabled={selectedTimeSlot === 'UNAVAILABLE'}
					onClick={() => {
						props.confirm(selectedTimeSlot, props.staffIndex);
						setSelectedTimeSlot('UNAVAILABLE');
					}}
				>
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
