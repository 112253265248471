import {
	Autocomplete,
	Box,
	Chip,
	SxProps,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Staff } from './Types';
interface StaffSelectorProps {
	availableStaff: Array<Staff>;
	changeWorkingTimes?: boolean;
	selectedStaff: Array<Staff>;
	setSelectedStaff: (func: (cur: Array<Staff>) => Array<Staff>) => void;
	openWarningDialog: (staff: Staff) => void;
	sx?: SxProps<Theme>;
	translationBase: string;
	titleTranslation: string;
	labelTranslation: string;
	colors?: string[];
	workTimeColors: {
		red: string;
		yellow: string;
		green: string;
	};
}

/**
 * StaffSelector Multiselect componen for selection of Staff
 * @param props
 * @returns
 */
export default function StaffSelector(props: StaffSelectorProps) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ ...props.sx }}>
			<Typography variant="subtitle1">
				{t(`${props.titleTranslation}`)}
			</Typography>
			<Autocomplete
				multiple
				options={props.availableStaff}
				getOptionLabel={(option) => option.name}
				value={props.selectedStaff}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, __, reason, details) => {
					if (details !== undefined) {
						if (reason === 'selectOption') {
							if (details.option.workTime === 'UNAVAILABLE')
								props.openWarningDialog(details.option);
							else props.setSelectedStaff((cur) => [...cur, details.option]);
						} else if (reason === 'removeOption')
							props.setSelectedStaff((cur) =>
								cur.filter((s) => s.id !== details.option.id)
							);
					}
					if (reason === 'clear') props.setSelectedStaff(() => []);
				}}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => {
						const bgColor =
							props.colors && index < props.colors.length
								? props.colors[index]
								: undefined;
						const color = bgColor
							? chroma.contrast(bgColor, '#000') < 4.5
								? '#fff'
								: '#000'
							: '#000';
						return (
							<Chip
								sx={{
									backgroundColor: bgColor,
									color: color,
									'svg.MuiSvgIcon-root': {
										color: color,
										opacity: 0.5,
									},
									opacity: option.workTime === 'UNAVAILABLE' ? 0.666 : 1,
								}}
								label={
									<Box sx={{ display: 'flex' }}>
										{option.name}
										{props.changeWorkingTimes && (
											<Chip
												sx={{
													height: '18px',
													fontStyle: 'italic',
													m: 'auto 0px auto 0.25rem',
													cursor: 'pointer',
													backgroundColor:
														option.workTime === 'WHOLE_DAY'
															? props.workTimeColors.green
															: option.workTime === 'MORNING' ||
															  option.workTime === 'AFTERNOON'
															? props.workTimeColors.yellow
															: props.workTimeColors.red,
												}}
												onClick={() => props.openWarningDialog(option)}
												label={t(option.workTime)}
											/>
										)}
									</Box>
								}
								{...getTagProps({ index })}
							/>
						);
					})
				}
				renderInput={(params) => (
					<TextField
						variant="outlined"
						{...params}
						placeholder={
							props.selectedStaff.length === 0
								? t(`${props.labelTranslation}`)
								: ''
						}
						sx={{ background: '#fff' }}
					/>
				)}
				renderOption={(autoCompleteProps, option) => (
					<li
						style={{
							justifyContent: 'space-between',
						}}
						{...autoCompleteProps}
					>
						<Typography
							sx={{
								opacity: option.workTime === 'UNAVAILABLE' ? 0.666 : 1,
							}}
						>
							{option.name}
						</Typography>
						<Box
							sx={{
								width: '100%',
								opacity: option.workTime === 'UNAVAILABLE' ? 0.666 : 1,
							}}
						>
							{option.referralTypes.map((referral) => (
								<Chip
									key={referral}
									sx={{ margin: '0 0.1rem' }}
									label={referral}
								/>
							))}
						</Box>

						<Chip
							sx={{
								margin: '0 0.1rem',
								opacity: 1,
								backgroundColor:
									option.workTime === 'WHOLE_DAY'
										? props.workTimeColors.green
										: option.workTime === 'MORNING' ||
										  option.workTime === 'AFTERNOON'
										? props.workTimeColors.yellow
										: props.workTimeColors.red,
							}}
							label={t(option.workTime)}
						/>
					</li>
				)}
				noOptionsText={t('no options')}
			/>
		</Box>
	);
}
