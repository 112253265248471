import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
	DialogTitle,
	FormControl,
	FormGroup,
	FormHelperText,
	FormLabel,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Staff, Room } from '../Types';
import { RoomCalendarPalette } from '../Panels/RoomCalendarPanel';

export function timeStr(min: number): string {
	const paddedStart = 7 * 60 + 30 + min;
	const hours = Math.floor(paddedStart / 60);
	const minutes = paddedStart - hours * 60;
	return `${hours.toString().padStart(2, '0')}:${minutes
		.toString()
		.padStart(2, '0')}`;
}

export function SettingsDialog(props: {
	open: boolean;
	close: () => void;
	rooms: Array<Room>;
	staff: Array<Staff>;
	timeLimits: { selection: number; sequencing: number };
	setTimeLimits: (newTimelimits: {
		selection: number;
		sequencing: number;
	}) => void;
	translationBase: string;
	palette: RoomCalendarPalette;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog fullWidth maxWidth="md" open={props.open} onClose={props.close}>
			<DialogTitle>{t('simulation settings')}</DialogTitle>
			<DialogContent sx={{ minHeight: '20vh' }} dividers>
				<FormControl fullWidth component="fieldset">
					<FormLabel component="legend">{t('timelimits (seconds)')}</FormLabel>
					<FormGroup sx={{ columnGap: '2%', mt: '.5rem' }} row>
						<FormControl sx={{ width: '49%' }} variant="filled">
							<TextField
								type="number"
								variant="outlined"
								label={t('selection')}
								inputProps={{ min: 1 }}
								value={props.timeLimits.selection}
								onChange={(e) =>
									props.setTimeLimits({
										...props.timeLimits,
										selection: Number(e.target.value),
									})
								}
							/>
						</FormControl>
						<FormControl sx={{ width: '49%' }} variant="filled">
							<TextField
								type="number"
								variant="outlined"
								label={t('sequencing')}
								inputProps={{ min: 2 }}
								value={props.timeLimits.sequencing}
								onChange={(e) =>
									props.setTimeLimits({
										...props.timeLimits,
										sequencing: Number(e.target.value),
									})
								}
							/>
							<FormHelperText id="my-helper-text">
								{t('time is split equally on each room')}
							</FormHelperText>
						</FormControl>
					</FormGroup>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close}>{t('confirm')}</Button>
				<Button onClick={props.close}>{t('close')}</Button>
			</DialogActions>
		</Dialog>
	);
}
