import { gql } from '@apollo/client';

export const GET_WORK_SCHEDULE = gql`
	query ($staffid: Int, $from: date, $to: date) {
		workschedule: get_staffworkschedule(
			args: { p_staffid: $staffid, p_from: $from, p_to: $to }
		) {
			end: dayend
			start: daystart
		}
	}
`;

export const STAFF_QUERY = gql`
	{
		staff {
			id
			name
			abbrev
			service
			profession {
				id
				title
			}
			staffqualifications {
			referraltype {
				id
				abbrev
				bma
				interpret
				doctor
			}
			qualificationtype
			}
		}
		referraltype {
			id
			abbrev
			bma
			interpret
			doctor
		}
		profession {
			id
			title
		}
	}
	`;


export const STAFF_CREATE = gql`
	mutation (
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
		$qualificationproduction: _int4
		$qualificationinterpret: _int4
		$qualificationassist: _int4
		$qualificationneedsassist: _int4
	) {
		insertstaffwithqualification(
			args: {
				name: $name
				abbrev: $abbrev
				service: $service
				professionid: $professionid
				qualificationproduction: $qualificationproduction
				qualificationinterpret: $qualificationinterpret
				qualificationassist: $qualificationassist
				qualificationneedsassist: $qualificationneedsassist
			}
		) {
			staffid
		}
	}
`;

export const STAFF_EDIT = gql`
	mutation (
		$id: Int!
		$name: String!
		$abbrev: String!
		$professionid: Int!
		$service: Int!
		$qualificationsToAdd: [staffqualifications_insert_input!]!
	) {
		update_staff_by_pk(
			pk_columns: { id: $id }
			_set: {
				name: $name
				abbrev: $abbrev
				professionid: $professionid
				service: $service
			}
		) {
			id
			name
			abbrev
			service
			profession {
				id
				title
			}
		}

		delete_staffqualifications(
			where: {
				staffid: { _eq: $id }
			}
		) {
			returning {
				referraltypeid
			}
		}

		insert_staffqualifications(objects: $qualificationsToAdd) {
			returning {
				qualificationtype
				referraltype {
					abbrev
					id
				}
			}
		}
	}
`;

export const STAFF_DELETE = gql`
	mutation ($id: Int!) {
		delete_staffqualifications(where: { staffid: { _eq: $id } }) {
			affected_rows
		}
		delete_staff_by_pk(id: $id) {
			id
		}
	}
`;
