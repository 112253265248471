/**
 * Classes from Open API spec
 */
export enum ScheduleType {
	BOOKED = 'BOOKED',
	EMERGENCY = 'EMERGENCY',
	UNSCHEDULED = 'UNSCHEDULED',
	SCHEDULED = 'SCHEDULED',
	LUNCH = 'LUNCH',
}

/**
 * Type of referral queue
 */
export enum QueueType {
	ORIGINAL = 'ORIGINAL',
	DIVERSE = 'DIVERSE',
}

/**
 * Pre-defined time slots
 */
export type TimeSlot = 'MORNING' | 'AFTERNOON' | 'WHOLE_DAY' | 'UNAVAILABLE';

export type ReferralPriority = 'pr1' | 'pr2' | 'pr3' | 'pr4' | 'pr5';

export interface Referral {
	id: string;
	referralType?: string;
	duration: number;
	start: number;
	schedulingType: ScheduleType;
	timeSlot?: TimeSlot;
	deadline: Date;
	priority: ReferralPriority;
	incoming?: Date;
	doctor?: string;
	bma?: Staff;
}

export interface Room {
	id: string;
	referralTypes: Array<string>;
	duration: number;
	referrals: Array<Referral>;
}

export interface Staff {
	id: string;
	referralTypes: Array<string>;
	workTime: TimeSlot;
	profession: 'BMA' | 'DOCTOR';
}

export interface MKP {
	staff: Array<Staff>;
	rooms: Array<Room>;
	referrals: Array<Referral>;
}

export interface ReferralInQueue {
	id: string;
	priority: ReferralPriority;
	referralType: string;
	deadline: Date;
	incoming: Date;
}

export interface Interpret {
	availableStaff: Array<Staff>;
	rooms: Array<Room>;
}
