import { CssBaseline, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import chroma from 'chroma-js';
import { createContext, useMemo, useState } from 'react';

const sownderColors: PaletteColors = {
	red: { light: chroma(227, 24, 54).hex(), dark: chroma(159, 63, 63).hex() },
	yellow: {
		light: chroma(241, 135, 0).hex(),
		dark: chroma(203, 138, 54).hex(),
	},
	green: {
		light: chroma(185, 219, 173).hex(),
		dark: chroma(105, 130, 122).hex(),
	},
	blue: {
		light: chroma(172, 218, 215).hex(),
		dark: chroma(109, 119, 141).hex(),
	},
	purple: {
		light: chroma(110, 54, 140).hex(),
		dark: chroma(134, 101, 154).hex(),
	},
};

const paletteColors: PaletteColors = {
	red: { light: '#EF522E', dark: '#92140C' },
	yellow: { light: '#F1F1E6', dark: '#EEC170' },
	green: { light: '#00B591', dark: '#055E60' },
	blue: { light: '#595e66', dark: '#111d4a' },
	purple: { light: '#DB819E', dark: '#B17BAF' },
};

export const ALL_PALETTES: Array<PaletteColors> = [
	sownderColors,
	paletteColors,
];
export const ALL_PALETTES_NAME: Array<string> = ['Sownder', '10100'];

interface ColorVersion {
	dark: string;
	light: string;
}

interface PaletteColors {
	red: ColorVersion;
	yellow: ColorVersion;
	green: ColorVersion;
	blue: ColorVersion;
	purple: ColorVersion;
}

interface IThemeContext {
	mode: PaletteMode;
	colorMode: (mode: PaletteMode) => void;

	paletteColors: PaletteColors;
	updateTheme: (paletteName: string) => void;
	priorityPalette: Array<string>;
}

export const AppThemeContext = createContext<IThemeContext>({
	mode: 'light',
	colorMode: () => {},
	updateTheme: () => {},
	paletteColors: ALL_PALETTES[0],
	priorityPalette: ['#000', '#000', '#000', '#000', '#000'],
});

export const AppThemeProvider = (props: { children: React.ReactNode }) => {
	const [palette, setPalette] = useState<PaletteColors>(ALL_PALETTES[0]);
	const [mode, setMode] = useState<PaletteMode>('light');

	const colorMode = useMemo(() => (mode: PaletteMode) => setMode(mode), []);

	const updateTheme = (paletteName: string) => {
		const metaThemeColor = document.querySelector('meta[name=theme-color]');
		let colorIndex: number = 0;
		switch (paletteName) {
			case 'Sownder':
				setPalette(ALL_PALETTES[0]);
				break;
			case '10100':
				colorIndex = 1;
				setPalette(ALL_PALETTES[1]);
				break;
		}

		if (metaThemeColor)
			metaThemeColor.setAttribute(
				'content',
				ALL_PALETTES[colorIndex].green.dark
			);
	};

	const pp = useMemo(
		() =>
			chroma
				.scale([palette.red.light, palette.yellow.dark, palette.green.light])
				.mode('lch')
				.colors(5)
				.map((c) => chroma(c).brighten(0.25).hex()),
		[palette]
	);
	console.log(paletteColors.green.light);
	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					primary: {
						main: mode === 'light' ? palette.green.dark : palette.green.light,
					},
					secondary: {
						main: palette.red.dark,
					},
					mode: mode,
				},
				components: {
					MuiCssBaseline: {
						styleOverrides: {
							body: {
								backgroundColor:
									mode === 'light' ? '#fbfafa' : 'rgb(66, 66, 66)',
							},
						},
					},
					MuiAutocomplete: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiChip: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTextField: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiSelect: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTable: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiTableCell: {
						defaultProps: {
							size: 'small',
						},
					},
				},
			}),
		[mode, palette]
	);

	return (
		<AppThemeContext.Provider
			value={{
				mode: mode,
				colorMode: colorMode,
				updateTheme: updateTheme,
				paletteColors: palette,
				priorityPalette: pp,
			}}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{props.children}
			</ThemeProvider>
		</AppThemeContext.Provider>
	);
};
