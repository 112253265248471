import { Box, Typography } from '@mui/material';
import { ResponsiveLine, Serie } from '@nivo/line';
import { useTranslation } from 'react-i18next';
import { GraphDataInterface } from './Types';

export default function ReferralLineGraph(props: {
	data: Array<GraphDataInterface>;
	priorityColors: { [priority: string]: string };
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<ResponsiveLine
			data={props.data as Serie[]}
			animate={true}
			colors={(e) => props.priorityColors[e.id]}
			margin={{ top: 10, bottom: 60, left: 40, right: 20 }}
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				precision: 'day',
			}}
			// xFormat="time:%Y-%m-%d"
			yScale={{ type: 'linear', stacked: false }}
			axisLeft={{
				legendOffset: -30,
				legendPosition: 'middle',
				legend: t(`quantity`),
				format: (e) => Math.floor(e) === e && e, // whole numbers
			}}
			axisBottom={{
				format: '%b-%d',
				tickValues: 'every 5 days',
				tickRotation: 45,
			}}
			pointLabelYOffset={-12}
			enableArea={true}
			isInteractive={true}
			enableSlices="x"
			sliceTooltip={(data) => {
				return (
					<Box
						sx={{
							backgroundColor: '#fff',
							border: '1px solid #ccc',
							padding: (t) => t.spacing(1),
						}}
					>
						<Typography variant="subtitle2">
							{
								data.slice.points[0].data.x
									.toLocaleString('sv-SE')
									.split(' ')[0] // Date only. TODO: Hardcoded to swedish
							}
						</Typography>
						<>
							{data.slice.points.map((item) => (
								<div
									key={item.serieId}
									style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
								>
									<div
										style={{
											height: '12px',
											width: '16px',
											backgroundColor: item.serieColor,
											display: 'inline-block',
										}}
									/>
									<Typography sx={{ width: '100%' }} variant="body2">
										{item.serieId}
									</Typography>

									<Typography variant="body2">
										{item.data.yFormatted}
									</Typography>
								</div>
							))}
						</>
					</Box>
				);
			}}
		/>
	);
}
