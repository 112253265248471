import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
	TableRow,
	TableCell,
	IconButton,
	Chip,
	Box,
	Typography,
	Collapse,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Slider,
	Button,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	Dialog,
	DialogActions,
	DialogTitle,
	Fade,
} from '@mui/material';
import svSE from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getWeek from 'date-fns/getWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ReferralTypeQualification, Staff } from './Types';
import { QualificationsSelect } from './SharedComponents';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { staffworkData } from './StaffCP';
import { useTranslation } from 'react-i18next';
import { WeekPicker } from '../../../common/Weekpicker';
import { AppThemeContext } from '../../../../AppTheme';

function sameContent(
	arr1: Array<{ id: number; abbrev: string }>,
	arr2: Array<{ id: number; abbrev: string }>
) {
	return (
		arr1.length === arr2.length && arr1.every((itemA) => arr2.includes(itemA))
	);
}

function DeleteUserDialog(props: {
	open: boolean;
	setOpen: (open: boolean) => void;
	deleteUser: () => void;
	name: string;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			open={props.open}
			onClose={() => props.setOpen(false)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>{`${t('are you sure that you want to delete')} ${
				props.name
			}?`}</DialogTitle>

			<DialogActions>
				<Button onClick={() => props.setOpen(false)}>{t('cancel')}</Button>
				<Button onClick={props.deleteUser}>{t('delete')}</Button>
			</DialogActions>
		</Dialog>
	);
}

function StaffCalendar(props: {
	weeks: Array<{ nr: number; weekdata: Array<staffworkData> }>;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});
	const getDayTimes = (weekdata: Array<staffworkData>, dayNumber: number) => {
		const day = weekdata.find((date) => date.start.getDay() === dayNumber + 1);

		if (day !== undefined) {
			const start = new Date(
				day.start.getTime() + day.start.getTimezoneOffset() * 60000
			);
			const end = new Date(
				day.end.getTime() + day.end.getTimezoneOffset() * 60000
			);
			return `${format(start, 'kk:mm')} - ${format(end, 'kk:mm')}`;
		}
	};

	const dayNames = [
		t('mon'),
		t('tue'),
		t('wed'),
		t('thu'),
		t('fri'),
		t('sat'),
		t('sun'),
	];

	return (
		<TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">{t('w')}</TableCell>
						{dayNames.map((dayName, index) => (
							<TableCell key={index} align="center">
								{dayName}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.weeks.map((week) => {
						return (
							<TableRow key={week.nr} sx={{ whiteSpace: 'nowrap' }}>
								<TableCell align="center" component="th" scope="row">
									{week.nr}
								</TableCell>
								{dayNames.map((_dayName, index) => (
									<TableCell key={`${week.nr}${index}`} align="center">
										{getDayTimes(week.weekdata, index)}
									</TableCell>
								))}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export function StaffTableRow(props: {
	professions: Array<{ id: number; title: string }>;
	staff: Staff;
	qualificationOptions: Array<ReferralTypeQualification>;
	translationBase: string;
	getStaffSchedule: (
		staffId: number,
		from: Date,
		to: Date
	) => Promise<Array<staffworkData>>;
	updateStaff: (newStaff: Staff) => void;
	deleteStaff: () => void;
}) {
	const [open, setOpen] = useState(false);
	const [localStaff, setLocalStaff] = useState<Staff>({ ...props.staff });
	const [weekDate, setWeekDate] = useState(new Date());
	const [staffWorkData, setStaffWorkData] = useState<Array<staffworkData>>([]);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const { t, i18n } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});
	const locale = i18n.language === 'sv' ? svSE : enUS;

	const { paletteColors } = useContext(AppThemeContext);

	useEffect(() => {
		if (open) {
			const end = new Date(
				endOfWeek(weekDate, { locale: locale }).getTime() -
					weekDate.getTimezoneOffset() * 60000
			);
			end.setDate(end.getDate() + 7);

			props
				.getStaffSchedule(
					props.staff.id,
					new Date(
						startOfWeek(weekDate, { locale: locale }).getTime() -
							weekDate.getTimezoneOffset() * 60000
					),
					end
				)
				.then((workData) => {
					setStaffWorkData(workData);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, weekDate]);

	const hasChanged = useMemo(
		() =>
			localStaff.abbrev !== props.staff.abbrev ||
			localStaff.name !== props.staff.name ||
			localStaff.profession !== props.staff.profession ||
			localStaff.service !== props.staff.service ||
			!sameContent(
				localStaff.qualificationsProduction,
				props.staff.qualificationsProduction
			) ||
			!sameContent(
				localStaff.qualificationsInterpret,
				props.staff.qualificationsInterpret
			) ||
			!sameContent(
				localStaff.qualificationsAssist,
				props.staff.qualificationsAssist
			) ||
			!sameContent(
				localStaff.qualificationsNeedsAssist,
				props.staff.qualificationsNeedsAssist
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localStaff]
	);

	return (
		<>
			<DeleteUserDialog
				open={deleteDialogOpen}
				setOpen={(open) => setDeleteDialogOpen(open)}
				deleteUser={props.deleteStaff}
				name={localStaff.name}
				translationBase={props.translationBase}
			/>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						size="small"
						onClick={() => setOpen(!open)}
						data-staffid={`staff${props.staff.id}`}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell>{props.staff.id}</TableCell>
				<TableCell sx={{ whiteSpace: 'nowrap' }}>{props.staff.name}</TableCell>
				<TableCell>
					<Chip label={props.staff.profession.title} />
				</TableCell>
				<TableCell>
					<Fade in={!open} timeout={{ enter: 200, exit: 800 }} unmountOnExit>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 1,
							}}
						>
							{props.staff.qualificationsProduction.map((q) => (
								<Chip
									key={`production-${q.id}`}
									label={q.abbrev}
									sx={{ backgroundColor: paletteColors.green.light }}
								/>
							))}
							{props.staff.qualificationsInterpret.map((q) => (
								<Chip
									key={`interpret-${q.id}`}
									label={q.abbrev}
									sx={{
										backgroundColor: paletteColors.green.dark,
									}}
								/>
							))}
						</Box>
					</Fade>
				</TableCell>
				<TableCell>
					<Typography variant="caption">{`${props.staff.service}%`}</Typography>
					<div
						style={{
							width: props.staff.service * 0.66,
							height: 2,
							backgroundColor: '#ff0000',
						}}
					/>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell colSpan={6} sx={{ paddingBottom: 0, paddingTop: 0 }}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box
							sx={{
								margin: 1,
								display: 'grid',
								columnGap: (t) => t.spacing(4),
								rowGap: (t) => t.spacing(2),
								gridTemplateColumns: '1fr 1fr',
								gridTemplateRows: `repeat(4, 1fr)`,
								gridAutoFlow: 'column',
							}}
						>
							<TextField
								fullWidth
								label={t('name')}
								variant="standard"
								value={localStaff.name}
								onChange={(event) =>
									setLocalStaff({
										...localStaff,
										name: event.target.value,
									})
								}
							/>

							<Box>
								<TextField
									fullWidth
									label={t('abbreviation')}
									variant="standard"
									value={localStaff.abbrev}
									onChange={(event) =>
										setLocalStaff({
											...localStaff,
											abbrev: event.target.value,
										})
									}
									inputProps={{ maxLength: 8 }}
								/>
							</Box>
							<Box>
								<FormControl variant="standard" fullWidth>
									<InputLabel>{t('profession')}</InputLabel>
									<Select
										onChange={(event) => {
											const professionId = event.target.value as number;
											const professionTitle = props.professions.find(
												(p) => p.id === professionId
											)?.title;
											setLocalStaff({
												...localStaff,
												profession: {
													id: professionId,
													title: professionTitle ? professionTitle : '',
												},
											});
										}}
										value={localStaff.profession.id}
									>
										{props.professions.map((p) => (
											<MenuItem key={p.id} value={p.id}>
												{t(`${p.title}`)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<Box>
								<Typography variant="body2">
									{t('degree of service')}
								</Typography>
								<Slider
									arie-label="work-slider"
									marks={[0, 25, 50, 75, 100].map((val) => ({
										value: val,
										label: `${val}%`,
									}))}
									step={null}
									value={localStaff.service}
									onChange={(_, value) => {
										if (typeof value === 'number')
											setLocalStaff({ ...localStaff, service: value });
									}}
									valueLabelDisplay="auto"
									getAriaValueText={(number) => `${number}%`}
								/>
							</Box>
							<QualificationsSelect
								title={t('production')}
								color={paletteColors.green.light}
								options={props.qualificationOptions}
								values={localStaff.qualificationsProduction}
								translationBase={props.translationBase}
								updateSelection={(newValues) =>
									setLocalStaff({
										...localStaff,
										qualificationsProduction: newValues,
									})
								}
							/>
							<QualificationsSelect
								title={t('interpret')}
								color={paletteColors.green.dark}
								options={props.qualificationOptions}
								values={localStaff.qualificationsInterpret}
								translationBase={props.translationBase}
								updateSelection={(newValues) =>
									setLocalStaff({
										...localStaff,
										qualificationsInterpret: newValues,
									})
								}
							/>
							<QualificationsSelect
								title={t('assist')}
								color={paletteColors.yellow.dark}
								options={props.qualificationOptions}
								values={localStaff.qualificationsAssist}
								translationBase={props.translationBase}
								updateSelection={(newValues) =>
									setLocalStaff({
										...localStaff,
										qualificationsAssist: newValues,
									})
								}
							/>
							<QualificationsSelect
								title={t('needs assist')}
								color={paletteColors.red.dark}
								options={props.qualificationOptions}
								values={localStaff.qualificationsNeedsAssist}
								translationBase={props.translationBase}
								updateSelection={(newValues) =>
									setLocalStaff({
										...localStaff,
										qualificationsNeedsAssist: newValues,
									})
								}
							/>
						</Box>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(3, 1fr)',
								marginTop: '1rem',
							}}
						>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'end',
									alignItems: 'center',
									height: '100%',
								}}
							>
								<ArrowBackIosNewIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										const newDate = new Date(weekDate.getTime());
										newDate.setDate(newDate.getDate() - 7);
										setWeekDate(newDate);
									}}
								/>
							</Box>
							<Box sx={{ margin: 'auto' }}>
								<WeekPicker
									locale={locale}
									onChange={(newDate) => {
										if (newDate !== null) setWeekDate(newDate);
									}}
									weekDate={weekDate}
								/>
							</Box>
							<Box>
								<Box
									style={{
										display: 'flex',
										justifyContent: 'start',
										alignItems: 'center',
										height: '100%',
									}}
								>
									<ArrowForwardIosIcon
										sx={{ cursor: 'pointer' }}
										onClick={() => {
											const newDate = new Date(weekDate.getTime());
											newDate.setDate(newDate.getDate() + 7);
											setWeekDate(newDate);
										}}
									/>
								</Box>
							</Box>
						</Box>

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'flex-end',
							}}
						>
							<Button color="error" onClick={() => setDeleteDialogOpen(true)}>
								{t('delete')}
							</Button>
							<Button
								disabled={!hasChanged}
								onClick={() => setLocalStaff(props.staff)}
							>
								{t('reset')}
							</Button>
							<Button
								disabled={
									!hasChanged
									// localStaff.name === props.staff.name &&
									// localStaff.abbrev === props.staff.abbrev &&
									// localStaff.service === props.staff.service &&
									// localStaff.profession === props.staff.profession
									// localStaff.qualifications.length ===
									// props.staff.qualifications.length &&
									// localStaff.qualifications.every((value) =>
									// props.staff.qualifications.includes(value)
									// )
								}
								onClick={() => props.updateStaff(localStaff)}
							>
								{t('update')}
							</Button>
						</Box>
						<StaffCalendar
							weeks={[
								{
									nr: getWeek(weekDate),
									weekdata: staffWorkData.filter(
										(date) => getWeek(weekDate) === getWeek(date.start)
									),
								},
								{
									nr: getWeek(weekDate) + 1,
									weekdata: staffWorkData.filter(
										(date) => getWeek(weekDate) !== getWeek(date.start)
									),
								},
							]}
							translationBase={props.translationBase}
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
