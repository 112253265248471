import { useQuery } from '@apollo/client';
import {
	Autocomplete,
	Box,
	Chip,
	LinearProgress,
	TextField,
	Typography,
} from '@mui/material';
import { addMonths, format } from 'date-fns';
import { useContext, useState } from 'react';
import chroma from 'chroma-js';
import { PageBox } from '../../../common/Components';
import { GET_PRIORITIES, GET_REFERRALS_ARRIVAL_AND_DEADLINE } from './Queries';
import ReferralLineGraph from './Graph';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';
import {
	ReferralOverviewQueryType,
	PriorityQuery,
	GraphState,
	ReferralQueryVariables,
} from './Types';

const PRIORITY_TRANSLATION = ['pr1', 'pr2', 'pr3', 'pr4', 'pr5'];

const DATE_ONLY_FMT = 'yyyy-MM-dd';

const LoadingBar = () => {
	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgress />
		</Box>
	);
};

const dataQueryConversion = (
	data: ReferralOverviewQueryType,
	prioritiesNames: Array<PriorityQuery>
): GraphState => ({
	arrivals: PRIORITY_TRANSLATION.map((priority, index) => {
		return {
			id: prioritiesNames[index].name,
			data: data.arrivals.map((item: any) => ({
				x: item.date,
				y: item[priority],
			})),
		};
	}),
	deadlines: PRIORITY_TRANSLATION.map((priority, index) => {
		return {
			id: prioritiesNames[index].name,
			data: data.deadlines.map((item: any) => ({
				x: item.date,
				y: item[priority],
			})),
		};
	}),
});

function ReferralOverviewCP(props: { translationBase: string }) {
	const [graphData, setGraphData] = useState<GraphState>({
		arrivals: [],
		deadlines: [],
	});

	const [allPriorities, setAllPriorities] = useState<Array<PriorityQuery>>([]);

	const [userSelectedPriorities, setUserSelectedPriorities] = useState<
		Array<PriorityQuery>
	>([]);

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.referral overview`,
	});

	useQuery<{ priority: Array<PriorityQuery> }>(GET_PRIORITIES, {
		onCompleted: (data) => {
			setAllPriorities(data.priority);
			setUserSelectedPriorities(data.priority);
		},
	});

	const tm = useContext(AppThemeContext);

	useQuery<ReferralOverviewQueryType, ReferralQueryVariables>(
		GET_REFERRALS_ARRIVAL_AND_DEADLINE,
		{
			variables: {
				a_from: format(addMonths(new Date(), -1), DATE_ONLY_FMT),
				a_to: format(new Date(), DATE_ONLY_FMT),
				d_from: format(new Date(), DATE_ONLY_FMT),
				d_to: format(addMonths(new Date(), 1), DATE_ONLY_FMT),
			},
			skip: allPriorities.length === 0, // depends on allPriorities
			onCompleted: (data) => {
				setGraphData(dataQueryConversion(data, allPriorities));
			},
		}
	);

	const priorityColors: { [priority: string]: string } = {};
	for (let priorityi = 0; priorityi < allPriorities.length; priorityi++)
		priorityColors[allPriorities[priorityi].name] =
			tm.priorityPalette[priorityi];

	return (
		<PageBox pageTitle={t('referral overview')}>
			<Box>
				{allPriorities.length > 0 ? (
					<Autocomplete
						multiple
						options={allPriorities}
						getOptionLabel={(option) => option.name}
						value={userSelectedPriorities}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(_, newValue) =>
							setUserSelectedPriorities(
								newValue.sort((a, b) => (a.id < b.id ? -1 : 1))
							)
						}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									sx={{
										backgroundColor: chroma(tm.priorityPalette[option.id - 1])
											.alpha(0.8)
											.hex(),
										color:
											chroma.contrast(
												tm.priorityPalette[option.id - 1],
												'#000'
											) <= 4.5
												? '#fff'
												: '#000',
									}}
									label={option.name}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label={t('priorities')}
							/>
						)}
					/>
				) : (
					<LoadingBar />
				)}
				{graphData.arrivals.length > 0 && graphData.deadlines.length > 0 ? (
					<>
						<Typography variant="h6">{t('referrals received')}</Typography>
						<Box
							sx={{
								margin: 'auto',
								width: (t) => t.breakpoints.values.lg,
								height: 300,
							}}
						>
							<ReferralLineGraph
								data={graphData.arrivals.filter((gd) => {
									return userSelectedPriorities
										.map((usp) => usp.name)
										.includes(gd.id);
								})}
								priorityColors={priorityColors}
								translationBase={`${props.translationBase}.referral overview`}
							/>
						</Box>
						<Typography variant="h6">{t('deadlines')}</Typography>
						<Box
							sx={{
								margin: 'auto',
								width: (t) => t.breakpoints.values.lg,
								height: 300,
							}}
						>
							<ReferralLineGraph
								data={graphData.deadlines.filter((gd) => {
									return userSelectedPriorities
										.map((usp) => usp.name)
										.includes(gd.id);
								})}
								priorityColors={priorityColors}
								translationBase={`${props.translationBase}.referral overview`}
							/>
						</Box>
					</>
				) : (
					<LoadingBar />
				)}
			</Box>
		</PageBox>
	);
}

export default ReferralOverviewCP;
