import { ResponsiveSankey } from '@nivo/sankey';
import { Link, OperatingUnit, OperationPostOP } from './Models';

export const PatientFlowGraph = (props: {
	data: Array<OperationPostOP>;
	palette: Array<string>;
}) => {
	const data: { nodes: Array<{ id: string }>; links: Array<Link> } = {
		nodes: [{ id: 'Operation' }],
		links: [],
	};

	/* Creating links from props.data */
	props.data
		.filter((op) => op.operatingUnit !== OperatingUnit.Underhall)
		.forEach((op) =>
			op.destinations.forEach((destination, destinationi) => {
				const source =
					destinationi === 0
						? 'Operation'
						: op.destinations[destinationi - 1].target;

				const index = data.links.findIndex(
					(link) => link.source === source && link.target === destination.target
				);

				if (index !== -1) data.links[index].value += 1;
				else
					data.links.push({
						source: source,
						target: destination.target,
						value: 1,
					});

				if (data.nodes.findIndex((n) => n.id === destination.target) === -1)
					data.nodes.push({ id: destination.target });
			})
		);

	return (
		<ResponsiveSankey
			data={data}
			margin={{ top: 10, right: 160, bottom: 10, left: 160 }}
			align="justify"
			colors={props.palette}
			nodeOpacity={1}
			nodeThickness={18}
			nodeInnerPadding={3}
			nodeSpacing={24}
			nodeBorderWidth={0}
			nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
			linkOpacity={0.7}
			linkHoverOpacity={1}
			linkHoverOthersOpacity={0.1}
			linkBlendMode="darken"
			enableLinkGradient={true}
			labelPosition="outside"
			labelOrientation="horizontal"
			labelPadding={4}
			labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
			theme={{
				tooltip: {
					container: {
						background: '#333',
						color: '#fff',
						fontSize: 'inherit',
						borderRadius: '2px',
						boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
						padding: '5px 9px',
					},
				},
				axis: {
					ticks: {
						text: {
							color: '#eee',
							fontSize: 14,
						},
					},
					legend: {
						text: {
							color: '#eee',
							fontSize: 14,
						},
					},
				},
			}}
			animate={true}
		/>
	);
};
