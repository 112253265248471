import { DestinationTarget, SchedulingType } from '../Common/Types';
import { Gender, OperatingUnit, OperationPostOP } from './Models';

export function initContainerData(): { [key: string]: OperationPostOP[] } {
	let id = 0;
	return {
		c1: [
			new OperationPostOP(
				id++,
				'ZZ999',
				34,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				0,
				34,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'JKA21',
				46,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				48,
				2,
				34,
				80,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 202 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				160 - 80,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				80,
				160,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'JAD61',
				81,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				77,
				2,
				160,
				241,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 278 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				358 - 241,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				241,
				358,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'JDF97',
				35,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				23,
				2,
				358,
				393,
				Gender.Male,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 229 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				622 - 393,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				393,
				622,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NCJ69',
				140,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				79,
				2,
				622,
				762,
				Gender.Male,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 209 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
		],
		c2: [
			new OperationPostOP(
				id++,
				'ZZ999',
				30,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				0,
				30,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'JAB30',
				49,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				65,
				2,
				30,
				79,
				Gender.Male,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 230 },
					{ target: DestinationTarget.HOME, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				135 - 79,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				79,
				135,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'TPX10',
				61,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				77,
				3,
				135,
				196,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 67 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				339 - 196,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				196,
				339,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NHJ62',
				190,
				OperatingUnit.Ortopedi,
				SchedulingType.EMERGENCY,
				80,
				2,
				339,
				529,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 956 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
		],
		c3: [
			new OperationPostOP(
				id++,
				'ZZ999',
				33,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				0,
				33,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NGB49',
				86,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				74,
				3,
				33,
				119,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 130 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				200 - 119,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				119,
				200,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NFB49',
				87,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				88,
				3,
				200,
				287,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 226 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				391 - 287,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				287,
				391,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NFB49',
				84,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				74,
				2,
				391,
				475,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 238 },
					{ target: DestinationTarget.WARD, duration: 0 },
				]
			),
		],
		c4: [
			new OperationPostOP(
				id++,
				'ZZ999',
				55,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				0,
				55,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NBH71',
				92,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				20,
				1,
				55,
				147,
				Gender.Male,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 276 },
					{ target: DestinationTarget.HOME, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				278 - 147,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				147,
				278,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NBA11',
				54,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				39,
				2,
				278,
				332,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 306 },
					{ target: DestinationTarget.HOME, duration: 0 },
				]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				423 - 332,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				0,
				1,
				332,
				423,
				Gender.Male,
				'Paus',
				[{ target: DestinationTarget.HOME, duration: 0 }]
			),
			new OperationPostOP(
				id++,
				'NHG46',
				62,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				85,
				3,
				423,
				485,
				Gender.Female,
				'N.N',
				[
					{ target: DestinationTarget.POST_OP, duration: 160 },
					{ target: DestinationTarget.HOME, duration: 0 },
				]
			),
		],
		gyn: [
			new OperationPostOP(
				id++,
				'GG121',
				120,
				OperatingUnit.Gynekologi,
				SchedulingType.EMERGENCY,
				38,
				2,
				0,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'GX914',
				220,
				OperatingUnit.Gynekologi,
				SchedulingType.ELECTIVE,
				37,
				4,
				120,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'GRA12',
				120,
				OperatingUnit.Gynekologi,
				SchedulingType.ELECTIVE,
				28,
				4,
				128 + 220,
				undefined,
				undefined,
				undefined,
				[]
			),
		],
		kir: [
			new OperationPostOP(
				id++,
				'KRB11',
				120,
				OperatingUnit.Kirurgi,
				SchedulingType.ELECTIVE,
				31,
				3,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'KXX88',
				220,
				OperatingUnit.Kirurgi,
				SchedulingType.EMERGENCY,
				55,
				3,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'KBB81',
				120,
				OperatingUnit.Kirurgi,
				SchedulingType.EMERGENCY,
				49,
				4,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
		],
		ort: [
			new OperationPostOP(
				id++,
				'OR001',
				120,
				OperatingUnit.Ortopedi,
				SchedulingType.EMERGENCY,
				33,
				3,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'OR002',
				220,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				65,
				5,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'OX010',
				120,
				OperatingUnit.Ortopedi,
				SchedulingType.ELECTIVE,
				27,
				4,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
		],
		uro: [
			new OperationPostOP(
				id++,
				'UU022',
				110,
				OperatingUnit.Urologi,
				SchedulingType.EMERGENCY,
				30,
				5,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'UR102',
				210,
				OperatingUnit.Urologi,
				SchedulingType.ELECTIVE,
				31,
				3,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'UA021',
				71,
				OperatingUnit.Urologi,
				SchedulingType.ELECTIVE,
				78,
				2,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
		],
		break: [
			new OperationPostOP(
				id++,
				'ZZ999',
				60,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				90,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
			new OperationPostOP(
				id++,
				'ZZ999',
				120,
				OperatingUnit.Underhall,
				SchedulingType.ELECTIVE,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				[]
			),
		],
	};
}
