import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageBox } from '../../../common/Components';
import { Constraint, QueryType, Staff, Team } from './Types';
import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { OP_TEAMS_QUERY } from './Queries';
import { Settings } from '@mui/icons-material';
import { TeamSettingsDialog } from './TeamSettingsDialog';
import axios from 'axios';
import { StaffSettingsDialog } from './StaffSettingsDialog';
import { CustomStaffDragLayer, TeamDroppable } from './TeamDroppable';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import svSE from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import { Profession, Staff as SpecStaff } from '../Common/Types';
import { merge } from '../../../common/Utility';
import { AppThemeContext } from '../../../../AppTheme';

function TeamsSO(props: { translationBase: string }) {
	const [teams, setTeams] = useState<Array<Team>>([
		{
			id: '',
			description: '',
			isoperating: false,
			staff: [],
			constraints: [],
		},
	]);
	const [contraintNames, setContraintNames] = useState<Array<string>>([]);

	const [teamSettingsOpen, setTeamSettingsOpen] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState<Staff>();

	const [fade, setFade] = useState(false);
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [leftover, setLeftover] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const { t, i18n } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.teams`,
	});

	const tm = useContext(AppThemeContext);

	const staffToSpec = (staff: Staff): SpecStaff => ({
		id: staff.id,
		profession: staff.profession,
		teamAllowedPlacement: staff.teamAllowedPlacement,
		available: staff.available,
	});

	function getStaffAndTeamsAvailability(
		date: Date,
		staffContainer: Team,
		staff: Array<Staff>
	) {
		const formattedDate = format(date, 'yyyy-MM-dd');
		axios
			.all([
				axios.get(
					`/simulation/operation/available-teams?date=${formattedDate}`
				),
				axios.post(
					`/simulation/operation/available-staff?date=${formattedDate}`,
					staff.map((s) => staffToSpec(s))
				),
			])
			.then(
				axios.spread((teamsResponse, staffResponse) =>
					setTeams([
						{
							...staffContainer,
							staff: merge(staff, staffResponse.data as Array<SpecStaff>),
						},
						...(teamsResponse.data as Array<Team>),
					])
				)
			);
	}

	function convertProfession(abbrev: string): Profession {
		if (abbrev === 'sam') return Profession.COORDINATOR;
		else return abbrev.toUpperCase().replace('-', '_') as Profession;
	}

	function distribute() {
		setLoading(true);
		setFade(true);
		const teamCopy = [...teams];

		for (let teami = 1; teami < teamCopy.length; teami++) {
			teamCopy[0].staff.push(...teamCopy[teami].staff.filter((s) => !s.locked));
			teamCopy[teami].staff = teamCopy[teami].staff.filter((s) => s.locked);
		}

		const availableStaff = teamCopy[0].staff.filter(
			(s) => s.available && !s.locked
		);

		const unavailableStaff = teamCopy[0].staff.filter(
			(s) => !s.available || s.locked
		);

		const allStaff = teams.map((t) => t.staff).flat();
		axios
			.post<{
				staff: Array<SpecStaff>;
				teams: Array<{
					constraints: Array<Constraint>;
					id: string;
					staff: Array<SpecStaff>;
				}>;
			}>(
				'/model/team-assignment' + (leftover ? '?leftover=Korridorsteam' : ''),
				{
					staff: availableStaff.map((s) => staffToSpec(s)),
					teams: teamCopy.slice(1).map((t) => ({
						id: t.id,
						staff: t.staff.map((s) => staffToSpec(s)),
						constraints: t.constraints,
					})),
				}
			)
			.then((res) => {
				if (res.headers['solution-type'] === '2') setSnackbarOpen(true);

				setLoading(false);
				setTeams([
					{
						...teamCopy[0],
						staff: [...unavailableStaff, ...merge(allStaff, res.data.staff)],
					}, // Gray staff list
					...res.data.teams.map((t, ti) => ({
						...teamCopy[1 + ti],
						staff: merge(allStaff, t.staff),
					})),
				]);
			});
	}

	useQuery<QueryType>(OP_TEAMS_QUERY, {
		onCompleted: (res) => {
			axios.get<Array<string>>('/model/op-constraint-names').then((cn) => {
				setContraintNames(cn.data);
				const staffContainer = {
					id: '',
					description: '',
					isoperating: false,
					constraints: [],
					staff: res.op_Staff.map<Staff>((s) => ({
						id: s.id,
						name: s.name,
						profession: convertProfession(s.Profession.abbrev),
						teamAllowedPlacement: s.StaffQualificationTeams.map(
							(sq) => sq.Team.abbrev
						),
						available: true,
						locked: false,
					})),
				};
				getStaffAndTeamsAvailability(
					selectedDate,
					staffContainer,
					staffContainer.staff
				);
			});
		},
	});

	const onDrop = (
		staffId: number,
		sourceTeamId: string,
		destinationTeamId: string
	) => {
		setFade(false);
		setTeams((cur) => {
			const copy = [...cur];

			const source =
				sourceTeamId === 'Personal' || sourceTeamId === 'Staff'
					? copy[0]
					: copy.find((t) => t.id === sourceTeamId);
			const destination =
				destinationTeamId === 'Personal' || destinationTeamId === 'Staff'
					? copy[0]
					: copy.find((t) => t.id === destinationTeamId);

			if (source !== undefined && destination !== undefined) {
				const MovedStaff = source.staff.find((s) => s.id === staffId);
				if (MovedStaff !== undefined) {
					source.staff = source.staff.filter((s) => s.id !== staffId);

					destination.staff.push(MovedStaff);
					return copy;
				}
			}
			return cur;
		});
	};

	const swapStaff = (teamIndex: number, a: number, b: number) => {
		const copy = [...teams];

		const staffCopy = copy[teamIndex].staff[a];
		copy[teamIndex].staff[a] = copy[teamIndex].staff[b];
		copy[teamIndex].staff[b] = staffCopy;

		setTeams(copy);
	};

	const roomTeams = teams.filter(
		(t) => t.isoperating && t.id !== '' && t.constraints.length > 0
	);
	const otherTeams = teams.filter(
		(t) => !t.isoperating && t.id !== '' && t.constraints.length > 0
	);

	const locale = i18n.language === 'sv' ? svSE : enUS;

	const teamColors: { [color: string]: string } = {
		'Team 1': tm.paletteColors.green.dark,
		'Team 2': tm.paletteColors.green.light,
		'Team 3': tm.paletteColors.yellow.dark,
		'Team 4': tm.paletteColors.blue.dark,
		'Team 5': tm.paletteColors.purple.light,
		Dagkirurgi: tm.paletteColors.yellow.light,
		Samordnare: tm.paletteColors.blue.light,
		Korridorsteam: tm.paletteColors.red.dark,
		Sökarfunktion: tm.paletteColors.purple.dark,
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<PageBox pageTitle={t('teams')}>
				<CustomStaffDragLayer />
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
					<DatePicker
						disableMaskedInput
						value={selectedDate}
						onChange={(e) => {
							if (e !== null) {
								setSelectedDate(e);
								getStaffAndTeamsAvailability(
									e,
									teams[0],
									teams.map((t) => t.staff).flat()
								);
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('selected day')}
								sx={{ height: '100%', background: 'white' }}
							/>
						)}
					/>
				</LocalizationProvider>
				<Box
					sx={{
						height: 'max(700px, 75vh)',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '20% 80%',
							height: 'inherit',
						}}
					>
						<TeamDroppable
							key={teams[0].id}
							team={{ ...teams[0], id: t('staff') }}
							updateStaff={(updatedStaff) => {
								const copy = [...teams];
								const staffIndex = copy[0].staff.findIndex(
									(s) => s.id === updatedStaff.id
								);
								if (staffIndex !== -1) {
									copy[0].staff[staffIndex] = updatedStaff;
									setTeams(copy);
								}
							}}
							teamColor="#efefef"
							onDrop={(staffId, sourceTeamId) =>
								onDrop(staffId, sourceTeamId, teams[0].id)
							}
							swapStaff={(a, b) => swapStaff(0, a, b)}
							setSelectedStaff={(staff) => setSelectedStaff(staff)}
							fade={fade}
							sx={{ height: 'inherit' }}
							translationBase={`${props.translationBase}.teams`}
						/>

						<Box
							sx={{
								marginLeft: '0.5rem',
								display: 'grid',
								height: 'inherit',
								gridTemplateColumns: 'repeat(4, 1fr)',
								gridTemplateRows: `repeat(${Math.ceil(
									teams.length / 4
								)}, minmax(0, 1fr))`,
								columnGap: '1rem',
							}}
						>
							{roomTeams.map((team, index) => (
								<TeamDroppable
									key={team.id}
									team={team}
									updateStaff={(updatedStaff) => {
										const copy = [...teams];
										const teamIndex = 1 + index;
										const staffIndex = copy[teamIndex].staff.findIndex(
											(s) => s.id === updatedStaff.id
										);
										if (staffIndex !== -1) {
											copy[teamIndex].staff[staffIndex] = updatedStaff;
											setTeams(copy);
										}
									}}
									teamColor={teamColors[team.id]}
									onDrop={(staffId, sourceTeamId) =>
										onDrop(staffId, sourceTeamId, team.id)
									}
									swapStaff={(a, b) => swapStaff(1 + index, a, b)}
									setSelectedStaff={(staff) => setSelectedStaff(staff)}
									fade={fade}
									sx={{ height: '100%' }}
									translationBase={`${props.translationBase}.teams`}
								/>
							))}
							{roomTeams.length % 4 !== 0 &&
								[...Array(4 - (roomTeams.length % 4))].map((_empty, i) => (
									<Box
										key={`missing-${i}`}
										sx={{ display: 'flex', flexDirection: 'column' }}
									>
										<Typography variant="h5" sx={{ mt: '0.25rem' }}>
											&nbsp;
										</Typography>
										<Box
											sx={{
												border: '1px lightgrey dashed',
												borderRadius: '4px',
												flexGrow: 1,
											}}
										/>
									</Box>
								))}
							{otherTeams.map((team, index) => (
								<TeamDroppable
									key={team.id}
									team={team}
									updateStaff={(updatedStaff) => {
										const copy = [...teams];
										const teamIndex = 1 + roomTeams.length + index;
										const staffIndex = copy[teamIndex].staff.findIndex(
											(s) => s.id === updatedStaff.id
										);
										if (staffIndex !== -1) {
											copy[teamIndex].staff[staffIndex] = updatedStaff;
											setTeams(copy);
										}
									}}
									teamColor={teamColors[team.id]}
									onDrop={(staffId, sourceTeamId) =>
										onDrop(staffId, sourceTeamId, team.id)
									}
									swapStaff={(a, b) =>
										swapStaff(1 + roomTeams.length + index, a, b)
									}
									setSelectedStaff={(staff) => setSelectedStaff(staff)}
									fade={fade}
									translationBase={`${props.translationBase}.teams`}
								/>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '4fr 1fr 4fr',
							marginTop: '2rem',
						}}
					>
						<Button
							disabled={
								loading || !teams.slice(1).some((t) => t.staff.length > 0)
							}
							sx={{ ml: 'auto' }}
							onClick={() => {
								const copy = [...teams];
								for (let team of copy.slice(1))
									copy[0].staff.push(...team.staff.splice(0));
								copy[0].staff.sort((a, b) => {
									if (a.name < b.name) return -1;
									else if (a.name > b.name) return 1;
									return 0;
								});
								setTeams(copy);
							}}
						>
							{t('reset')}
						</Button>
						<Button
							disabled={loading}
							sx={{ mx: '0.25rem' }}
							onClick={() => distribute()}
						>
							{t('distribute')}
						</Button>
						<IconButton
							disabled={loading}
							sx={{ mr: 'auto' }}
							onClick={() => setTeamSettingsOpen(true)}
						>
							<Settings />
						</IconButton>
						{loading && (
							<CircularProgress
								sx={{ gridColumn: '1 / -1', mx: 'auto', mt: '0.5rem' }}
							/>
						)}
					</Box>
				</Box>

				<TeamSettingsDialog
					teams={teams.slice(1)}
					teamColors={teamColors}
					setTeams={(newTeams) => setTeams([teams[0], ...newTeams])}
					open={teamSettingsOpen}
					setOpen={setTeamSettingsOpen}
					constraintNames={contraintNames}
					translationBase={`${props.translationBase}.teams`}
					leftover={leftover}
					leftoverToggle={setLeftover}
				/>

				<StaffSettingsDialog
					selectedStaff={selectedStaff}
					setSelectedStaff={setSelectedStaff}
					availableTeams={teams.slice(1).map((t) => ({
						name: t.id,
						hexColor: teamColors[t.id],
					}))}
					updateStaff={(updatedStaff) => {
						setFade(false);
						let teamIndex: number = -1;
						let staffIndex: number = -1;
						const copy = [...teams];
						for (let teami = 0; teami < copy.length; teami++)
							for (let staffi = 0; staffi < copy[teami].staff.length; staffi++)
								if (copy[teami].staff[staffi].id === updatedStaff.id) {
									teamIndex = teami;
									staffIndex = staffi;
								}
						if (teamIndex === -1 || staffIndex === -1) return;

						if (updatedStaff.available === false && teamIndex > 0) {
							const staffCopy = copy[teamIndex].staff.splice(staffIndex, 1);
							teamIndex = 0;
							staffIndex = copy[0].staff.length;
							copy[0].staff.push(...staffCopy);
						}
						Object.assign(copy[teamIndex].staff[staffIndex], updatedStaff);
						setTeams(copy);
					}}
					translationBase={`${props.translationBase}.teams`}
				/>

				<Snackbar
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={() => setSnackbarOpen(false)}
				>
					<Alert
						onClose={() => setSnackbarOpen(false)}
						severity="error"
						elevation={2}
						sx={{ width: '100%' }}
					>
						<Typography sx={{ fontWeight: 'bold' }}>
							{t('model infeasible')}
						</Typography>
					</Alert>
				</Snackbar>
			</PageBox>
		</DndProvider>
	);
}

export default TeamsSO;
