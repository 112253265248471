import Dialog from '@mui/material/Dialog';
import {
	Alert,
	Avatar,
	Box,
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	LinearProgress,
	Paper,
	Snackbar,
	Tooltip,
	Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Room, Staff, SimulationState, Referral } from '../Types';
import {
	Staff as SpecStaff,
	MKP,
	Room as SpecRoom,
	Interpret,
} from '../../Common/Types';
import { ScheduleType } from '../../Common/Types';
import {
	RoomCalendarPalette,
	CALENDARHEIGHT,
} from '../Panels/RoomCalendarPanel';
import { getColor, getPriorityName } from '../ReferralSelector';
import { Legend } from '../../../../common/Legend';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../../AppTheme';
import chroma from 'chroma-js';
import { groupArray, merge } from '../../../../common/Utility';

function Block(props: {
	height: string;
	type: ScheduleType;
	palette: RoomCalendarPalette;
}) {
	let backgroundColor = '#fff';

	if (props.type === ScheduleType.EMERGENCY)
		backgroundColor = props.palette.emergency;
	else if (props.type === ScheduleType.BOOKED)
		backgroundColor = props.palette.planned;
	else if (props.type === ScheduleType.LUNCH)
		backgroundColor = props.palette.lunch;
	else if (props.type === ScheduleType.UNSCHEDULED)
		backgroundColor = props.palette.scheduled;
	else if (props.type === ScheduleType.SCHEDULED)
		backgroundColor = props.palette.scheduled;

	return (
		<Paper
			elevation={0}
			sx={{
				width: '100%',
				height: props.height,
				boxSizing: 'border-box',
				borderRadius: '0px',
				backgroundColor: backgroundColor,
				marginBottom: '1px',
			}}
		/>
	);
}

export function ReferralsPreview(props: {
	referrals: Array<Referral>;
	error?: boolean;
}) {
	const { paletteColors } = useContext(AppThemeContext);
	const palette = chroma
		.scale([
			paletteColors.red.light,
			paletteColors.yellow.dark,
			paletteColors.green.light,
		])
		.mode('lch')
		.colors(5);

	const groupedReferrals: { [type: string]: Array<Referral> } = {};

	for (let referral of props.referrals) {
		if (referral.referralType !== undefined) {
			if (!(referral.referralType in groupedReferrals))
				groupedReferrals[referral.referralType] = [referral];
			else groupedReferrals[referral.referralType].push(referral);
		}
	}

	return (
		<Box
			sx={{
				gridColumn: '1 / -1',
				m: '1rem 16px',
				height: '150px',
				overflowY: 'auto',
				overflowX: 'hidden',
				opacity: props.error ? 0.5 : 1,
			}}
		>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'min-content min-content 1fr',
				}}
			>
				{Object.keys(groupedReferrals).map((referralGroup) => {
					const referrals = groupedReferrals[referralGroup];
					referrals.sort(
						(a, b) =>
							Number(a.priority.replace('pr', '')) -
							Number(b.priority.replace('pr', ''))
					);
					return (
						<Fragment key={referralGroup}>
							<Typography noWrap>{referralGroup}</Typography>
							<Typography sx={{ mx: '2rem' }}>{referrals.length}</Typography>

							<Box
								sx={{
									display: 'flex',
									overflowX: 'hidden',
								}}
							>
								{referrals.slice(0, 40).map((referral, referralIndex) => (
									<Fade
										key={referral.id}
										timeout={Math.max(referralIndex * 150, 2000)}
										in={true}
									>
										<Tooltip
											placement="top"
											title={
												<Typography>
													{getPriorityName(referral.priority)}
												</Typography>
											}
										>
											<Box
												sx={{
													display: 'inline-block',
													background: getColor(palette, referral.priority),
													width: '12px',
													minWidth: '12px',
													height: '12px',
													mx: '1px',
													my: 'auto',
												}}
											/>
										</Tooltip>
									</Fade>
								))}
								{referrals.length > 41 && (
									<Typography sx={{ fontWeight: 'bold', ml: '0.25rem' }}>
										...
									</Typography>
								)}
							</Box>
						</Fragment>
					);
				})}
			</Box>
		</Box>
	);
}

function RoomsPreview(props: {
	numRooms: number;
	palette: RoomCalendarPalette;
	unscheduledData: Array<Room>;
	translationBase: string;
	fade: boolean;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Box sx={{ gridColumn: '1 / -1' }}>
			<Fade in={props.fade}>
				<Box>
					<Typography align="center" variant="body1">
						{t('preview of selected referrals')}
					</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: `repeat(${props.numRooms}, minmax(0, 1fr))`,
							columnGap: '1rem',
							margin: (t) => t.spacing(2),
						}}
					>
						{props.unscheduledData.map((room) => {
							return (
								<Box key={room.id}>
									<Box
										sx={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<Typography
											align="center"
											textOverflow="ellipsis"
											noWrap
											variant="body2"
										>
											{room.name}
										</Typography>
									</Box>

									<Paper style={{ height: CALENDARHEIGHT / 2 }}>
										{room.referrals.map((referral) => (
											<Block
												type={referral.schedulingType}
												key={referral.id}
												height={`${referral.duration / 2}px`}
												palette={props.palette}
											/>
										))}
									</Paper>
								</Box>
							);
						})}
						{[...Array(props.numRooms - props.unscheduledData.length)].map(
							(_unusedRoom, i) => {
								return (
									<Box key={i}>
										<Box
											sx={{ display: 'flex', justifyContent: 'space-between' }}
										>
											<Typography variant="body2">&nbsp;</Typography>
										</Box>

										<Paper
											sx={{
												height: CALENDARHEIGHT / 2,
												background:
													props.unscheduledData.length !== 0
														? '#ddd'
														: 'inherit',
											}}
										></Paper>
									</Box>
								);
							}
						)}
					</Box>
					<Legend
						items={[
							{
								name: t('scheduled'),
								color: props.palette.scheduled,
							},
							{
								name: t('acute'),
								color: props.palette.emergency,
							},
							{
								name: t('planned'),
								color: props.palette.planned,
							},
							{
								name: t('lunch'),
								color: props.palette.lunch,
							},
						]}
						spaceLeft="1rem"
						sx={{ marginRight: '1.5rem' }}
					/>
				</Box>
			</Fade>
		</Box>
	);
}

const PRUNE = true;

export const prunePayload = (payload: MKP, maxReferrals: number = 500) => {
	const getReferralTypes = (list: Array<SpecRoom | SpecStaff>) =>
		list.reduce<Array<string>>(
			(acc, curr) =>
				acc.concat(
					curr.referralTypes.filter((referral) => !acc.includes(referral))
				),
			[]
		);

	// Gather all referral types from the rooms in a list
	const roomReferralTypes = getReferralTypes(payload.rooms);
	const staffReferralTypes = getReferralTypes(payload.staff);

	// Choose the referral types that are present in any room and any staff
	const availableReferralTypes = roomReferralTypes.filter((referral) =>
		staffReferralTypes.some((otherReferral) => referral === otherReferral)
	);

	const pruneStaffAndRooms = (list: Array<SpecRoom | SpecStaff>) =>
		list.filter(
			(item) =>
				item.referralTypes.length > 0 &&
				item.referralTypes.some((referral) =>
					availableReferralTypes.includes(referral)
				)
		);

	const prunedRooms = pruneStaffAndRooms(payload.rooms) as Array<SpecRoom>;
	const prunedStaff = pruneStaffAndRooms(payload.staff) as Array<SpecStaff>;

	const prunedReferrals = payload.referrals.filter((referral) =>
		referral.referralType
			? availableReferralTypes.includes(referral.referralType)
			: false
	);

	return {
		staff: prunedStaff,
		rooms: prunedRooms,
		referrals: prunedReferrals,
	};
};

export const mergeRooms = (
	dataArray: Array<SpecRoom>,
	origRooms: Array<Room>,
	origRef: Array<Referral>,
	origStaff: Array<Staff | undefined>
) =>
	merge(origRooms, dataArray).map<Room>((data) => ({
		...data,
		referrals: merge(
			origRef,
			data.referrals.map((r) => ({
				...r,
				bma: r.bma === undefined ? undefined : merge(origStaff, [r.bma])[0],
			}))
		),
	}));

export function SchedulingDialog(props: {
	open: boolean;
	close: () => void;
	rooms: Array<Room>;
	setRooms: (room: Array<Room>) => void;
	timelimits: { selection: number; sequencing: number };
	palette: RoomCalendarPalette;
	payload: MKP;
	selectedDoctors: Array<Staff>;
	translationBase: string;
}) {
	const [timer, setTimer] = useState<Date | null>(null);
	const requestRef = useRef(0);

	const [startFetch, setStartFetch] = useState<Date | null>(null);
	const [unscheduledData, setUnscheduledData] = useState<Array<Room>>([]);
	const [solutionType, setSolutiontype] = useState({
		selection: -1,
		placement: -1,
	});

	const [simulationState, setSimulationState] = useState(SimulationState.NONE);

	const [abortController, setAbortController] = useState(new AbortController());

	const { t } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.scheduling dialog`,
	});

	const animate = () => {
		setTimer(new Date());
		requestRef.current = requestAnimationFrame(animate);
	};

	// updates internal animation
	useEffect(() => {
		if (startFetch !== null && timer === null) {
			setTimer(new Date());
			requestRef.current = requestAnimationFrame(animate);
		} else if (startFetch === null && timer !== null) {
			setTimer(null);
			cancelAnimationFrame(requestRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startFetch]);

	// trigger first fetch
	useEffect(() => {
		if (props.open && simulationState === SimulationState.NONE) {
			setSimulationState(SimulationState.LOADING_SIMULATION);
			setUnscheduledData([]);
			setStartFetch(new Date());
			setSolutiontype({
				selection: -1,
				placement: -1,
			});
			simulate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open && simulationState]);

	// trigger second fetch
	useEffect(() => {
		if (simulationState === SimulationState.FINISHED_LOADING_SIMULATION) {
			setSimulationState(SimulationState.LOADING_SCHEDULING);
			setStartFetch(new Date());
			schedule(unscheduledData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulationState]);

	// trigger third fetch
	useEffect(() => {
		if (simulationState === SimulationState.FINISHED_LOADING_SCHEDULING) {
			setSimulationState(SimulationState.LOADING_INTERPET);
			interpet();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulationState]);

	const simulate = () => {
		/** Pruning payload */
		const payload: MKP = !PRUNE
			? { ...props.payload }
			: prunePayload({ ...props.payload }, 250);

		const requestBody: MKP = {
			// Pre-processing
			rooms: payload.rooms.map((room) => ({
				...room,
				duration: 480,
			})),
			staff: payload.staff,
			referrals: payload.referrals,
		};

		axios
			.post<Array<SpecRoom>>(
				`/model/mkp?timeLimit=${props.timelimits.selection}`,
				requestBody,
				{
					headers: { 'Min-time': props.timelimits.selection * 1000 },
					signal: abortController.signal,
				}
			)
			.then((resp) => {
				if (resp === undefined || resp.status < 200 || resp.status >= 300)
					return;

				setStartFetch(null);
				setSolutiontype({
					...solutionType,
					selection: Number(resp.headers['solution-type']),
				});

				setUnscheduledData(
					mergeRooms(
						resp.data,
						props.rooms,
						payload.referrals as Array<Referral>,
						payload.staff as Array<Staff>
					)
				);
				setSimulationState(SimulationState.FINISHED_LOADING_SIMULATION);
			})
			.catch(() => setSimulationState(SimulationState.ERROR));
	};

	const schedule = (data: Array<Room>) => {
		axios
			.post<Array<Room>>(
				`/model/sequencing?timeLimit=${props.timelimits.sequencing}`,
				data,
				{
					headers: {
						'Min-time': props.timelimits.sequencing * 1000,
					},
					signal: abortController.signal,
				}
			)
			.then((resp) => {
				if (resp === undefined || resp.status < 200 || resp.status >= 300)
					return;

				setUnscheduledData(
					mergeRooms(
						resp.data,
						unscheduledData,
						unscheduledData.map((room) => room.referrals).flat(),
						unscheduledData
							.map((room) => room.referrals)
							.flat()
							.map((r) => r.bma)
					)
				);
				setStartFetch(null);
				setSolutiontype({
					...solutionType,
					placement: Array.from(
						resp.headers['solution-type'].matchAll(/[0-9]*/g)
					).reduce((acc, cur) => Math.max(acc, Number(cur)), 0),
				});
				setSimulationState(SimulationState.FINISHED_LOADING_SCHEDULING);
			})
			.catch(() => setSimulationState(SimulationState.ERROR));
	};

	const interpet = () => {
		axios
			.post<Interpret>(
				'/model/interpret',
				{
					availableStaff: props.selectedDoctors,
					rooms: unscheduledData.map<SpecRoom>((roomData) => ({
						id: roomData.id,
						referralTypes: roomData.referralTypes,
						duration: roomData.duration,
						referrals: roomData.referrals,
					})),
				},
				{ signal: abortController.signal }
			)
			.then((resp) => {
				if (resp === undefined || resp.status < 200 || resp.status >= 300)
					return;

				const updatedRooms = mergeRooms(
					resp.data.rooms,
					unscheduledData,
					unscheduledData.map((room) => room.referrals).flat(),
					unscheduledData
						.map((room) => room.referrals)
						.flat()
						.map((r) => r.bma)
				);

				const doctorDict = groupArray('id', props.selectedDoctors);
				// create a dictionary where the key is referral name and value doctor name
				const referralsToDoctors = updatedRooms.reduce<{
					[name: string]: string;
				}>((acc, cur) => {
					for (let ref of cur.referrals)
						if (ref.doctor !== undefined)
							acc[ref.id] = doctorDict[ref.doctor].name;

					return acc;
				}, {});

				// add the doctor name to the referrals
				const copy = [
					...updatedRooms.map((room) => ({
						...room,
						referrals: room.referrals.map((r) => ({
							...r,
							deadline: new Date(r.deadline),
							incoming:
								r.incoming !== undefined ? new Date(r.incoming) : undefined,
						})),
					})),
				];
				for (let roomdata of copy)
					for (let ref of roomdata.referrals)
						if (Object.keys(referralsToDoctors).includes(ref.id))
							ref.doctor = referralsToDoctors[ref.id];

				setUnscheduledData(copy);
				setSimulationState(SimulationState.FINISHED_LOADING_INTERPET);
			})
			.catch(() => setSimulationState(SimulationState.ERROR));
	};

	const progress =
		startFetch === null || timer === null
			? 0
			: Math.min(
					Math.round((timer.getTime() - startFetch.getTime()) / 100),
					100
			  );

	const error = simulationState === SimulationState.ERROR;
	const loadingOpacity = unscheduledData.length === 0 || error ? 0.5 : 1;

	return (
		<Dialog fullWidth maxWidth="md" open={props.open}>
			<DialogTitle>{t('planning')}</DialogTitle>

			<DialogContent
				sx={{
					display: 'grid',
					gridTemplateColumns: 'min-content max-content 2fr 1fr',
					padding: (t) => t.spacing(2),
					rowGap: (t) => t.spacing(1),
					columnGap: (t) => t.spacing(5),
					mx: '1rem',
				}}
			>
				<Avatar
					sx={{
						width: 24,
						height: 24,
						backgroundColor: (t) =>
							simulationState <= SimulationState.LOADING_SIMULATION
								? undefined
								: t.palette.primary.main,
					}}
				>
					1
				</Avatar>
				<Typography sx={{ opacity: error ? 0.5 : 1 }} fontWeight="bold">
					{t('selection')}
				</Typography>
				<LinearProgress
					sx={{ mt: '9px', opacity: error ? 0.5 : 1 }}
					variant="determinate"
					value={
						simulationState === SimulationState.LOADING_SIMULATION
							? Math.min(progress * (10 / props.timelimits.selection), 100)
							: 100
					}
				/>

				<Box>
					<Typography
						fontWeight="bold"
						sx={{
							display: 'inline-block',
							verticalAlign: 'top',
							opacity: error ? 0.5 : 1,
						}}
					>
						{t('referrals selected', {
							count: unscheduledData.reduce(
								(acc, curr) =>
									acc +
									curr.referrals.filter((r) => !r.id.includes('Lunch')).length,
								0
							),
						})}
					</Typography>
					<DoneIcon
						sx={{
							mt: '-2px',
							ml: (t) => t.spacing(1),
							opacity: !error && unscheduledData.length > 0 ? 1 : 0,
						}}
					/>
				</Box>

				<ReferralsPreview
					error={error}
					referrals={unscheduledData.map((data) => data.referrals).flat()}
				/>

				<Avatar
					sx={{
						opacity: loadingOpacity,
						width: 24,
						height: 24,
						backgroundColor: (t) =>
							simulationState <= SimulationState.FINISHED_LOADING_SCHEDULING
								? undefined
								: t.palette.primary.main,
					}}
				>
					2
				</Avatar>
				<Typography fontWeight="bold" sx={{ opacity: loadingOpacity }}>
					{t('sequencing')}
				</Typography>
				<LinearProgress
					sx={{ mt: '9px', opacity: loadingOpacity }}
					variant="determinate"
					value={
						simulationState <= SimulationState.FINISHED_LOADING_SIMULATION
							? 0
							: simulationState === SimulationState.LOADING_SCHEDULING
							? Math.min(progress * (10 / props.timelimits.sequencing), 100)
							: 100
					}
				/>
				<Box sx={{ opacity: loadingOpacity }}>
					<Typography
						fontWeight="bold"
						sx={{ display: 'inline-block', verticalAlign: 'top' }}
					>
						{t(
							`${
								simulationState <= SimulationState.FINISHED_LOADING_SCHEDULING
									? 'loading'
									: 'done'
							}`
						)}
					</Typography>
					<DoneIcon
						sx={{
							mt: '-2px',
							ml: (t) => t.spacing(1),
							opacity:
								simulationState <= SimulationState.FINISHED_LOADING_SCHEDULING
									? 0
									: 1,
						}}
					/>
				</Box>
				<RoomsPreview
					numRooms={props.rooms.length}
					palette={props.palette}
					unscheduledData={unscheduledData}
					translationBase={`${props.translationBase}.scheduling dialog`}
					fade={simulationState >= SimulationState.FINISHED_LOADING_SCHEDULING}
				/>
			</DialogContent>
			<DialogActions
				sx={{
					margin: '2rem 0 1rem 0',
					display: 'grid',
					gridTemplateColumns: '1fr 3fr 1fr',
					mx: '1rem',
				}}
			>
				<Box sx={{ gridColumn: 2, textAlign: 'center', my: 'auto' }}>
					<Chip
						label={`${t('selection')}: 
							${t(solutionType.selection === 0 ? 'optimal solution found' : 'solution found')}
						`}
						sx={{
							opacity:
								solutionType.selection === 0 || solutionType.selection === 1
									? 1
									: 0,
							mr: '0.25rem',
						}}
					/>
					<Chip
						label={`${t('sequencing')}: 
						${t(solutionType.placement === 0 ? 'optimal solution found' : 'solution found')}
					`}
						sx={{
							opacity:
								solutionType.placement === 0 || solutionType.placement === 1
									? 1
									: 0,
							ml: '0.25rem',
						}}
					/>
				</Box>
				<Box sx={{ gridColumn: 3, textAlign: 'right', mr: '16px' }}>
					<Button
						onClick={() => {
							abortController.abort();
							setAbortController(new AbortController());
							props.close();
							setSimulationState(SimulationState.NONE);
							setUnscheduledData([]);
						}}
						sx={{ display: 'inline-block' }}
					>
						{t('cancel')}
					</Button>
					<Button
						disabled={
							simulationState !== SimulationState.FINISHED_LOADING_INTERPET
						}
						onClick={() => {
							props.close();
							setSimulationState(SimulationState.NONE);

							const newRooms = [...props.rooms];
							for (const room of newRooms) {
								const roomIndex = unscheduledData.findIndex(
									(r) => r.id === room.id
								);
								if (roomIndex !== -1)
									room.referrals = unscheduledData[roomIndex].referrals;
							}

							props.setRooms(newRooms);
						}}
						sx={{ display: 'inline-block' }}
					>
						{t('view')}
					</Button>
				</Box>
			</DialogActions>
			<Snackbar open={error}>
				<Alert severity="error" elevation={2} sx={{ width: '100%' }}>
					<Typography sx={{ fontWeight: 'bold', mt: '-1px' }}>
						{t('simulation failed')}
					</Typography>
				</Alert>
			</Snackbar>
		</Dialog>
	);
}
