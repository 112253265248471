import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../../AppTheme';
import { TimeLine } from '../../DailyPlanningCP/DailyPlanningCP';
import { timeStr } from '../Dialogs/SettingsDialog';
import { Referral, Room, Staff } from '../Types';
import { ScheduleType } from '../../Common/Types';
import {
	CALENDARHEIGHT,
	contrastColor,
	RoomCalendarPalette,
} from './RoomCalendarPanel';

export function StaffViewPanel(props: {
	index: number;
	value: number;
	rooms: Array<Room>;
	allBMA: Array<Staff>;
	palette: RoomCalendarPalette;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});
	const { paletteColors } = useContext(AppThemeContext);

	const bmaList: {
		[name: string]: Array<{ room: string; referral: Referral }>;
	} = {};
	props.rooms.forEach((room) =>
		room.referrals.forEach((referral) => {
			if (referral.bma !== undefined) {
				const bmaName = referral.bma.name;
				if (bmaList[bmaName] === undefined) bmaList[bmaName] = [];
				bmaList[bmaName].push({
					room: room.name,
					referral: referral,
				});
			}
		})
	);

	const gridWidths = props.allBMA.reduce(
		(acc) => acc + ' minmax(0, 30.5%)',
		''
	);

	return (
		<Box role="tabpanel" hidden={props.value !== props.index}>
			{props.value === props.index && (
				<>
					<Typography variant="subtitle1">{t('choose rooms')}</Typography>
					<Box
						sx={{
							display: 'grid',
							columnGap: '1rem',
							gridTemplateColumns: `min-content${gridWidths}`,
						}}
					>
						{props.allBMA.map((bma, bmaIndex) => {
							return (
								<Box
									key={bma.name}
									sx={{
										overflow: 'hidden',
										gridRow: 1,
										gridColumn: `${2 + bmaIndex}`,
										marginBottom: '0.5rem',
										color:
											bmaList[bma.name] === undefined ? 'lightgrey' : undefined,
									}}
								>
									<Typography align="center" noWrap={true} variant="body2">
										{bma.name}
									</Typography>
								</Box>
							);
						})}
						<Box sx={{ gridRow: '2' }}>
							<TimeLine />
						</Box>

						{props.allBMA.map((bma, bmaIndex) => {
							const referrals:
								| Array<{ room: string; referral: Referral }>
								| undefined = bmaList[bma.name];

							return (
								<Paper
									key={bma.name}
									elevation={referrals === undefined ? 0 : undefined}
									sx={{
										position: 'relative',
										gridColumn: `${2 + bmaIndex}`,
										height: CALENDARHEIGHT,
										...(referrals === undefined
											? {
													border: '1px dashed lightgrey',
													background: '#fafafa',
											  }
											: {}),
									}}
								>
									{referrals !== undefined && (
										<>
											{referrals.map((r) => {
												let color = 'black';
												let backgroundColor = 'white';
												let border = '1px solid black';

												if (
													r.referral.schedulingType === ScheduleType.EMERGENCY
												) {
													backgroundColor = props.palette.emergency;
													border = `1px dashed ${paletteColors.red.dark}`;
													color = contrastColor(props.palette.emergency);
												} else if (
													r.referral.schedulingType === ScheduleType.BOOKED
												) {
													backgroundColor = props.palette.planned;
													color = contrastColor(props.palette.planned);
													border = '1px solid #000';
												} else if (
													r.referral.schedulingType === ScheduleType.LUNCH
												)
													backgroundColor = props.palette.lunch;
												else if (
													r.referral.schedulingType === ScheduleType.SCHEDULED
												) {
													color = contrastColor(props.palette.scheduled);
													backgroundColor = props.palette.scheduled;
													border = '1px solid #000';
												}

												return (
													<Tooltip
														key={r.referral.id}
														title={
															<Box sx={{ margin: '0.25rem' }}>
																<Typography>
																	{t('name')}: {r.referral.id}
																</Typography>
																<Typography>
																	{t('type')}: {r.referral.referralType}
																</Typography>
																<Typography>
																	{t('room')}: {r.room}
																</Typography>
																<Typography>
																	{t('start')}: {timeStr(r.referral.start)}
																</Typography>
																<Typography>
																	{t('duration')} ({t('minutes')}):{' '}
																	{r.referral.duration}
																</Typography>
																<Typography>
																	{t('end')}:{' '}
																	{timeStr(
																		r.referral.start + r.referral.duration
																	)}
																</Typography>
																<Typography>
																	{t('MLS')}: {bma.name}
																</Typography>
																<Typography>
																	{t('interpreter')}:{' '}
																	{r.referral.doctor === undefined
																		? ''
																		: r.referral.doctor}
																</Typography>
															</Box>
														}
													>
														<Paper
															elevation={0}
															sx={{
																transform: `translateY(${
																	r.referral.start + 30
																}px)`,
																transition:
																	'background-color 0.5s, color 0.5s, border 0.5s',
																backgroundColor: backgroundColor,
																color: color,
																border: border,
																position: 'absolute',
																width: '100%',
																boxSizing: 'border-box',
																height: `${r.referral.duration}px`,
																borderRadius: '3px',
															}}
														>
															<Typography
																variant="caption"
																component="div"
																sx={{
																	margin: '0.15rem',
																	overflow: 'hidden',
																	whiteSpace: 'nowrap',
																	textOverflow: 'ellipsis',
																}}
																align="right"
															>
																{r.referral.duration >= 20 && (
																	<Typography>
																		{r.room}: {r.referral.id}
																	</Typography>
																)}
															</Typography>
														</Paper>
													</Tooltip>
												);
											})}
											<Paper
												elevation={0}
												sx={{
													transform: 'translateY(300px)',
													backgroundColor: props.palette.lunch,
													border: 'black',
													position: 'absolute',
													width: '100%',
													height: '60px',
													borderRadius: '3px',
												}}
											>
												<Typography
													variant="caption"
													component="div"
													sx={{ margin: '0.15rem' }}
													align="right"
												>
													Lunch
												</Typography>
											</Paper>
										</>
									)}
								</Paper>
							);
						})}
					</Box>
				</>
			)}
		</Box>
	);
}
