import { useState, useContext, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
	Box,
	CircularProgress,
	Button,
	useTheme,
	Tabs,
	Tab,
	TextField,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Paper,
	Typography,
} from '@mui/material';
import { GET_SIMULATION_INPUT } from './Queries';
import {
	Staff,
	QueryType,
	ReferralType,
	Room,
	Referral,
	BackgroundColorMode,
} from './Types';
import { ScheduleType, ReferralInQueue } from '../Common/Types';
import StaffSelector from './StaffSelector';
import ReferralSelector from './ReferralSelector';
import RoomCalendarPanel, {
	RoomCalendarPalette,
} from './Panels/RoomCalendarPanel';
import { SettingsDialog } from './Dialogs/SettingsDialog';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { PageBox } from '../../../common/Components';
import { AppThemeContext } from '../../../../AppTheme';
import { mergeRooms, SchedulingDialog } from './Dialogs/SchedulingDialog';
import { GetIntervalInMinutes } from '../../../common/Utility';
import { InterpretPanel } from './Panels/InterpretPanel';
import { StatisticsPanel } from './Panels/StatisticsPanel';
import { StaffViewPanel } from './Panels/StaffViewPanel';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import svSE from 'date-fns/locale/sv';
import enUS from 'date-fns/locale/en-US';
import axios from 'axios';
import { format } from 'date-fns';
import ConfirmStaffSelectionDialog from './Dialogs/ConfirmStaffSelectionDialog';
import SavePlanningDialog from './Dialogs/SavePlanningDialog';
import { merge } from '../../../common/Utility';
import SetReferralDialog from './Dialogs/SetReferralDialog';

const DEFAULT_ACTIVE_ROOMS = [
	'Ultraljudsrum 1',
	'Ultraljudsrum 2',
	'Sköterskerum 1',
	'Arbetsprovsrum',
];

function SimulationCP(props: { translationBase: string }) {
	const { t, i18n } = useTranslation('translation', {
		keyPrefix: `${props.translationBase}.simulation`,
	});

	const { paletteColors } = useContext(AppThemeContext);
	const theme = useTheme();

	const [availableStaff, setAvailableStaff] = useState<Array<Staff>>([]);
	const [selectedStaff, setSelectedStaff] = useState<Array<Staff>>([]);

	const [filterByDoctors, setFilterByDoctors] = useState(false);
	const [bgColorMode, setBgColorMode] = useState(BackgroundColorMode.PRIORITY);
	const [selectedTab, setSelectedTab] = useState(0);

	const [allRooms, setAllRooms] = useState<Array<Room>>([]);

	const [DBReferrals, setDBReferrals] = useState<Array<ReferralInQueue>>([]);

	const [staffAvailableReferralTypes, setStaffAvailableReferralTypes] =
		useState<{
			doctor: Array<ReferralType>;
			bma: Array<ReferralType>;
		}>({ doctor: [], bma: [] });

	const [referralTypes, setReferralTypes] = useState<{
		[name: string]: string;
	}>({});

	const [timeLimits, setTimeLimits] = useState({
		selection: 5,
		sequencing: 2,
	});

	const tm = useContext(AppThemeContext);

	const [dialogsOpen, setDialogsOpen] = useState({
		confirmStaffSelectionDialog: false,
		savePlanningDialog: false,
		schedulingDialog: false,
		settingDialog: false,
		setReferralDialog: false,
	});

	const [warningDialogUser, setWarningDialogUser] = useState({} as Staff);
	const [setReferralDialogReferral, setSetReferralDialogReferral] = useState(
		{} as Referral
	);

	const [selectedDate, setSelectedDate] = useState(new Date());

	const [mode, setMode] = useState<'simulation' | 'planning'>('simulation');

	const palette: RoomCalendarPalette = {
		emergency: '#f0d1d1',
		planned: paletteColors.green.light,
		lunch: theme.palette.grey[200],
		scheduled: '#e4e4e4',
	};

	const doctorPalette = [
		tm.paletteColors.red.light,
		tm.paletteColors.purple.light,
		tm.paletteColors.yellow.light,
		tm.paletteColors.blue.light,
		tm.paletteColors.red.dark,
		tm.paletteColors.purple.dark,
		tm.paletteColors.yellow.dark,
		tm.paletteColors.blue.dark,
	];

	const activeRooms = useMemo(
		() => allRooms.filter((r) => r.active),
		[allRooms]
	);

	const getStaffAvailability = (
		date: Date | undefined,
		staff: Array<Staff>
	) => {
		const urlStr =
			date === undefined
				? '/simulation/klinfys/available-staff'
				: `/simulation/klinfys/available-staff?date=${format(
						date,
						'yyyy-MM-dd'
				  )}`;
		axios.post<Array<Staff>>(urlStr, staff).then((response) => {
			const res = merge(staff, response.data);
			setAvailableStaff(res);
			setSelectedStaff(res.filter((s) => s.workTime !== 'UNAVAILABLE'));
		});
	};

	useQuery<QueryType>(GET_SIMULATION_INPUT, {
		onCompleted: (data) => {
			// set staff and referral types
			const newBMAAvailableReferralTypes: Array<ReferralType> = [];
			const newDoctorAvailableReferralTypes: Array<ReferralType> = [];
			const newStaffList: Array<Staff> = [];

			const lunchBlock: Referral = {
				start: 270,
				duration: 60,
				id: 'Lunch',
				schedulingType: ScheduleType.LUNCH,
				priority: 'pr5',
				deadline: new Date(),
				timeSlot: 'MORNING',
			};

			for (const staff of data.staff) {
				const staffReferralTypes = staff.staffqualifications.map((item) => ({
					abbrev: item.referraltype.abbrev,
					bma: item.referraltype.bma,
				}));

				const newStaff: Staff = {
					id: staff.id,
					name: staff.name,
					referralTypes: staffReferralTypes.map((rt) => rt.abbrev),
					workTime: 'WHOLE_DAY',
					profession: 'BMA',
				};

				if (staff.profession.title === 'BMA') {
					newStaffList.push(newStaff);
					newBMAAvailableReferralTypes.push(
						...staffReferralTypes.filter(
							(item) =>
								!newBMAAvailableReferralTypes
									.map((newItem) => newItem.abbrev)
									.includes(item.abbrev)
						)
					);
				} else {
					newStaffList.push({ ...newStaff, profession: 'DOCTOR' });
					newDoctorAvailableReferralTypes.push(
						...staffReferralTypes.filter(
							(item) =>
								!newDoctorAvailableReferralTypes
									.map((newItem) => newItem.abbrev)
									.includes(item.abbrev)
						)
					);
				}
			}

			const cleanedUpRooms = data.room.map<Room>((room) => ({
				id: room.id,
				name: room.name,
				duration: 480,
				referralTypes: room.roomreferraltypes.map(
					(type) => type.referraltype.abbrev
				),
				active: DEFAULT_ACTIVE_ROOMS.includes(room.name),
				referrals: [{ ...lunchBlock }],
			}));

			cleanedUpRooms.sort((a, b) => Number(b.active) - Number(a.active));

			setAvailableStaff(newStaffList);
			setStaffAvailableReferralTypes({
				doctor: newDoctorAvailableReferralTypes,
				bma: newBMAAvailableReferralTypes,
			});
			setAllRooms([...cleanedUpRooms]);

			let rt: { [name: string]: string } = {};
			data.referraltype.forEach((referralType) => {
				if (!Object.keys(rt).includes(referralType.abbrev))
					rt[referralType.abbrev] = referralType.interpret;
			});
			setReferralTypes(rt);
			getStaffAvailability(selectedDate, newStaffList);
		},
		fetchPolicy: 'no-cache',
	});

	const Reset = () => {
		getStaffAvailability(selectedDate, availableStaff);
		setAllRooms(
			allRooms.map((r) => ({
				...r,
				active: DEFAULT_ACTIVE_ROOMS.includes(r.name),
				referrals: r.referrals.filter((ref) => ref.schedulingType === 'LUNCH'), // only keeping Lunches
			}))
		);

		setFilterByDoctors(false);
		setBgColorMode(BackgroundColorMode.INTERPRET);
		setSelectedTab(0);
	};

	useEffect(
		() => {
			setSelectedTab(0);
			setAllRooms(
				allRooms.map((r) => ({
					...r,
					active: DEFAULT_ACTIVE_ROOMS.includes(r.name),
					referrals: r.referrals.filter(
						(ref) => ref.schedulingType === 'LUNCH'
					), // only keeping Lunches
				}))
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mode]
	);

	const getFormattedReferrals = (
		filter: (
			value: ReferralInQueue,
			index: number,
			array: Array<ReferralInQueue>
		) => boolean
	) =>
		DBReferrals.filter(filter).map((referral) => {
			const type = staffAvailableReferralTypes.bma.find(
				(type) => type.abbrev === referral.referralType
			);

			return {
				id: `${referral.referralType}-${referral.id}`,
				referralType: referral.referralType,
				duration: type !== undefined ? GetIntervalInMinutes(type.bma) : 0,
				schedulingType: ScheduleType.UNSCHEDULED,
				priority: referral.priority,
				deadline: referral.deadline,
				incoming: referral.incoming,
				start: 0,
				interpretDuration:
					referralTypes[referral.referralType] !== undefined
						? GetIntervalInMinutes(referralTypes[referral.referralType])
						: undefined,
			};
		});

	const selectedDoctors = selectedStaff.filter(
		(s) => s.profession === 'DOCTOR'
	);
	const selectedBMA = selectedStaff.filter((s) => s.profession === 'BMA');

	const doctorColors: { [name: string]: string } = {};
	for (let i = 0; i < selectedDoctors.length && i < doctorPalette.length; i++)
		doctorColors[selectedDoctors[i].name] = doctorPalette[i];

	const locale = i18n.language === 'sv' ? svSE : enUS;

	return (
		<PageBox pageTitle={t('planning')}>
			<Typography variant="subtitle1">{t('mode')}</Typography>
			<Paper
				variant="outlined"
				sx={{
					borderColor: 'rgba(204, 204, 204, 1)',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
				style={{ padding: '8px 16px' }}
			>
				<FormControl>
					<RadioGroup row name="mode" value={mode}>
						<FormControlLabel
							value="simulation"
							label={t('simulation')}
							control={
								<Radio
									onClick={() =>
										getStaffAvailability(selectedDate, availableStaff)
									}
								/>
							}
							onClick={() => {
								setMode('simulation');
							}}
						/>
						<FormControlLabel
							value="planning"
							label={t('planning')}
							control={<Radio />}
							onClick={() => {
								setMode('planning');
								getStaffAvailability(undefined, availableStaff);
							}}
						/>
					</RadioGroup>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
					<DatePicker
						disableMaskedInput
						value={selectedDate}
						onChange={(e) => {
							if (e !== null) {
								setSelectedDate(e);
								getStaffAvailability(e, availableStaff);
							}
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('selected day')}
								sx={{ height: '100%', background: 'white' }}
							/>
						)}
					/>
				</LocalizationProvider>
			</Paper>

			{availableStaff.length > 0 && (
				<ReferralSelector
					referrals={DBReferrals}
					setReferrals={setDBReferrals}
					filterByDoctors={filterByDoctors}
					setFilterByDoctors={(val) => setFilterByDoctors(val)}
					filterByDoctorsCheckboxDisabled={selectedDoctors.length === 0}
					translationBase={`${props.translationBase}.simulation`}
					sx={{ marginTop: (t) => t.spacing(2) }}
				/>
			)}

			<StaffSelector
				availableStaff={availableStaff.filter((s) => s.profession === 'BMA')}
				changeWorkingTimes={true}
				openWarningDialog={(staff) => {
					setWarningDialogUser(staff);
					setDialogsOpen({ ...dialogsOpen, confirmStaffSelectionDialog: true });
				}}
				selectedStaff={selectedBMA}
				setSelectedStaff={(func) =>
					setSelectedStaff((cur) =>
						cur
							.filter((s) => s.profession !== 'BMA')
							.concat(func(cur.filter((s) => s.profession === 'BMA')))
					)
				}
				translationBase={`${props.translationBase}.simulation`}
				titleTranslation="choose MLS"
				labelTranslation="MLS staff"
				sx={{ my: (t) => t.spacing(2) }}
				workTimeColors={{
					red: tm.paletteColors.red.light,
					yellow: tm.paletteColors.yellow.light,
					green: tm.paletteColors.green.light,
				}}
			/>

			<StaffSelector
				availableStaff={availableStaff.filter((s) => s.profession === 'DOCTOR')}
				openWarningDialog={(staff) => {
					setWarningDialogUser(staff);
					setDialogsOpen({ ...dialogsOpen, confirmStaffSelectionDialog: true });
				}}
				selectedStaff={selectedDoctors}
				setSelectedStaff={(func) =>
					setSelectedStaff((cur) =>
						cur
							.filter((s) => s.profession !== 'DOCTOR')
							.concat(func(cur.filter((s) => s.profession === 'DOCTOR')))
					)
				}
				translationBase={`${props.translationBase}.simulation`}
				titleTranslation="choose doctors"
				labelTranslation="doctor staff"
				colors={
					bgColorMode === BackgroundColorMode.INTERPRET ? doctorPalette : []
				}
				workTimeColors={{
					red: tm.paletteColors.red.light,
					yellow: tm.paletteColors.yellow.light,
					green: tm.paletteColors.green.light,
				}}
			/>

			<Tabs
				value={selectedTab}
				onChange={(_e, newValue) => setSelectedTab(Number(newValue))}
				sx={{ mt: (t) => t.spacing(2) }}
				TabIndicatorProps={{
					sx: { background: 'white', height: '0px' },
				}}
			>
				<Tab
					disableRipple
					sx={{
						background: 'white',
						borderTopLeftRadius: '4px',
						border: '1px solid rgb(204, 204, 204)',
						borderBottomColor:
							selectedTab === 0 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('room view')}
				/>
				<Tab
					disableRipple
					disabled={
						mode === 'planning' ||
						allRooms.every(
							(r) =>
								r.active === false ||
								r.referrals.filter(
									(r) => r.schedulingType !== ScheduleType.LUNCH
								).length === 0
						)
					}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 1 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('interpret view')}
				/>
				<Tab
					disableRipple
					disabled={allRooms.every(
						(r) =>
							r.active === false ||
							r.referrals.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
								.length === 0
					)}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 2 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('statistics view')}
				/>
				<Tab
					disableRipple
					disabled={allRooms.every(
						(r) =>
							r.active === false ||
							r.referrals.filter((r) => r.schedulingType !== ScheduleType.LUNCH)
								.length === 0
					)}
					sx={{
						background: 'white',
						border: '1px solid rgb(204, 204, 204)',
						borderLeftWidth: '0px',
						borderBottomColor:
							selectedTab === 3 ? 'white' : 'rgb(204, 204, 204)',
					}}
					label={t('staff view')}
				/>
			</Tabs>

			<Box
				sx={{
					border: '1px solid rgb(204, 204, 204)',
					marginTop: '-1px',
					borderRadius: '4px',
					borderTopLeftRadius: '0px',
					background: 'white',
					p: '1rem',
				}}
			>
				<RoomCalendarPanel
					draggable={mode === 'simulation'}
					updateRooms={setAllRooms}
					setSetReferralReferral={(referral) => {
						setSetReferralDialogReferral(referral);
						setDialogsOpen({ ...dialogsOpen, setReferralDialog: true });
					}}
					allRooms={allRooms}
					translationBase={`${props.translationBase}.simulation`}
					palette={palette}
					doctorColors={doctorColors}
					bgColorMode={bgColorMode}
					setBgColorMode={(newVal) => setBgColorMode(newVal)}
					value={selectedTab}
					index={0}
				/>

				<InterpretPanel
					value={selectedTab}
					index={1}
					rooms={activeRooms}
					referralTypes={referralTypes}
					doctors={selectedDoctors}
					doctorColors={doctorColors}
					translationBase={`${props.translationBase}.simulation`}
				/>
				<StatisticsPanel
					value={selectedTab}
					index={2}
					rooms={activeRooms}
					referralTypes={Object.keys(referralTypes)}
					doctorColors={doctorColors}
					translationBase={`${props.translationBase}.simulation`}
				/>
				<StaffViewPanel
					value={selectedTab}
					index={3}
					rooms={activeRooms}
					allBMA={availableStaff.filter((s) => s.profession === 'BMA')}
					palette={palette}
					translationBase={`${props.translationBase}.simulation`}
				/>
			</Box>
			<Box sx={{ display: 'flex', marginBottom: (t) => t.spacing(4) }}>
				<Box sx={{ margin: 'auto', mt: '0.5rem' }}>
					<Button
						disabled={
							!filterByDoctors &&
							!bgColorMode &&
							mode === 'simulation' &&
							allRooms.every(
								(room) =>
									room.referrals.filter(
										(r) => r.schedulingType !== ScheduleType.LUNCH
									).length === 0
							)
						}
						onClick={Reset}
					>
						{t('reset')}
					</Button>
					<Button
						disabled={
							selectedBMA.length === 0 ||
							(DBReferrals.length === 0 && mode === 'simulation')
						}
						onClick={() => {
							if (mode === 'simulation') {
								setAllRooms(
									allRooms.map((room) => ({
										...room,
										referrals: room.referrals.filter(
											(r) =>
												r.schedulingType === ScheduleType.BOOKED ||
												r.schedulingType === ScheduleType.EMERGENCY ||
												r.schedulingType === ScheduleType.LUNCH
										),
									}))
								);
								setDialogsOpen({ ...dialogsOpen, schedulingDialog: true });
							} else {
								axios
									.get<Array<Room>>('/simulation/klinfys/schedule')
									.then((res) => {
										const mergedRooms = mergeRooms(
											res.data,
											allRooms,
											res.data.map((r) => r.referrals).flat(),
											availableStaff.filter((s) => s.profession === 'BMA')
										);

										const newRooms = [...allRooms];
										for (const room of newRooms) {
											const roomIndex = mergedRooms.findIndex(
												(r) => r.id === room.id
											);
											if (roomIndex !== -1)
												room.referrals = mergedRooms[roomIndex].referrals;
										}

										newRooms.forEach((room) =>
											room.referrals.map<Referral>((r) =>
												Object.assign(r, {
													deadline: new Date(r.deadline),
													incoming:
														r.incoming === undefined
															? undefined
															: new Date(r.incoming),
												})
											)
										);
										setAllRooms(newRooms);
									});
							}
						}}
					>
						{dialogsOpen.schedulingDialog && (
							<CircularProgress
								size="1rem"
								sx={{ marginRight: (t) => t.spacing(1) }}
							/>
						)}
						{t('plan')}
					</Button>
					<Button
						sx={{ padding: 0, minWidth: 'initial' }}
						variant="text"
						onClick={() =>
							setDialogsOpen({ ...dialogsOpen, settingDialog: true })
						}
					>
						<SettingsIcon sx={{ fontSize: '1.5rem' }} />
					</Button>
					<Button
						disabled={allRooms.every(
							(room) =>
								room.referrals.filter(
									(referral) => referral.schedulingType !== ScheduleType.LUNCH
								).length === 0
						)}
						sx={{ padding: 0 }}
						variant="text"
						onClick={() =>
							setDialogsOpen({ ...dialogsOpen, savePlanningDialog: true })
						}
					>
						{t('save')}
					</Button>
				</Box>
			</Box>
			<SchedulingDialog
				open={dialogsOpen.schedulingDialog}
				close={() =>
					setDialogsOpen({ ...dialogsOpen, schedulingDialog: false })
				}
				palette={palette}
				rooms={allRooms}
				setRooms={(rooms) => setAllRooms(rooms)}
				timelimits={timeLimits}
				payload={{
					staff: selectedBMA,
					rooms: activeRooms,
					referrals: dialogsOpen.schedulingDialog
						? getFormattedReferrals(
								(referral) =>
									!filterByDoctors ||
									selectedDoctors.length === 0 ||
									staffAvailableReferralTypes.doctor.some(
										(availableReferral) =>
											availableReferral.abbrev === referral.referralType
									)
						  )
						: [],
				}}
				selectedDoctors={selectedDoctors}
				translationBase={`${props.translationBase}.simulation`}
			/>
			<SettingsDialog
				open={dialogsOpen.settingDialog}
				close={() => setDialogsOpen({ ...dialogsOpen, settingDialog: false })}
				rooms={allRooms}
				staff={availableStaff.filter((s) => s.profession === 'BMA')}
				timeLimits={timeLimits}
				setTimeLimits={(newTimeLimits) => setTimeLimits(newTimeLimits)}
				translationBase={`${props.translationBase}.simulation`}
				palette={palette}
			/>
			<ConfirmStaffSelectionDialog
				open={dialogsOpen.confirmStaffSelectionDialog}
				close={() =>
					setDialogsOpen({
						...dialogsOpen,
						confirmStaffSelectionDialog: false,
					})
				}
				staff={warningDialogUser}
				staffIndex={selectedStaff.findIndex(
					(s) => s.id === warningDialogUser.id
				)}
				workTimeColors={{
					red: tm.paletteColors.red.light,
					yellow: tm.paletteColors.yellow.light,
					green: tm.paletteColors.green.light,
				}}
				confirm={(newTimeSlot, staffIndex) => {
					setDialogsOpen({
						...dialogsOpen,
						confirmStaffSelectionDialog: false,
					});
					if (staffIndex !== -1) {
						const staffCopy = [...selectedStaff];
						staffCopy[staffIndex].workTime = newTimeSlot;
						setSelectedStaff(staffCopy);
					} else {
						const staffIndex = availableStaff.findIndex(
							(s) => s.id === warningDialogUser.id
						);
						if (staffIndex !== -1) {
							const staffCopy = [...availableStaff];
							staffCopy[staffIndex].workTime = newTimeSlot;

							setAvailableStaff(staffCopy);
							setSelectedStaff([
								...selectedStaff,
								{ ...warningDialogUser, workTime: newTimeSlot },
							]);
						}
					}
				}}
				translationBase={`${props.translationBase}.simulation`}
			/>
			<SetReferralDialog
				open={dialogsOpen.setReferralDialog}
				close={() =>
					setDialogsOpen({
						...dialogsOpen,
						setReferralDialog: false,
					})
				}
				referral={setReferralDialogReferral}
				referrals={
					dialogsOpen.setReferralDialog
						? getFormattedReferrals(
								(val) =>
									val.referralType === setReferralDialogReferral.referralType
						  )
						: []
				}
				confirm={(newReferral) => {
					setDialogsOpen({
						...dialogsOpen,
						setReferralDialog: false,
					});
					const copy = [...allRooms];
					for (let roomI = 0; roomI < copy.length; roomI++) {
						for (
							let referralI = 0;
							referralI < copy[roomI].referrals.length;
							referralI++
						)
							if (
								copy[roomI].referrals[referralI].id ===
								setReferralDialogReferral.id
							) {
								copy[roomI].referrals[referralI] = {
									...copy[roomI].referrals[referralI],
									...newReferral,
									bma: copy[roomI].referrals[referralI].bma,
									start: copy[roomI].referrals[referralI].start,
									timeSlot: copy[roomI].referrals[referralI].timeSlot,
									schedulingType: ScheduleType.BOOKED,
								};
							}
					}

					setDBReferrals(
						DBReferrals.filter((r) => {
							const originalid = r.id.match(/\d+/g);
							const newId = newReferral.id.match(/\d+/g);

							return (
								originalid !== null &&
								newId !== null &&
								originalid.join('') !== newId.join('')
							);
						})
					);
					setAllRooms(copy);
				}}
				translationBase={`${props.translationBase}.simulation`}
			/>
			<SavePlanningDialog
				open={dialogsOpen.savePlanningDialog}
				close={() =>
					setDialogsOpen({
						...dialogsOpen,
						savePlanningDialog: false,
					})
				}
				rooms={allRooms}
				selectedBMA={selectedBMA}
				selectedDoctors={selectedDoctors}
				selectedDate={selectedDate}
				translationBase={`${props.translationBase}.simulation`}
			/>
		</PageBox>
	);
}

export default SimulationCP;
