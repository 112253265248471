import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Radio,
	styled,
	tableCellClasses,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Referral } from '../Types';

export default function SetReferralDialog(props: {
	open: boolean;
	close: () => void;
	referral: Referral;
	referrals: Array<Referral>;
	confirm: (newReferral: Referral) => void;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const [selectedReferral, setSelectedReferral] = useState(props.referral);

	useEffect(() => setSelectedReferral(props.referral), [props.referral]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		},
	}));

	return (
		<Dialog open={props.open} onClose={props.close} maxWidth="lg">
			<DialogTitle>{t('assign referral to scheduled time')}</DialogTitle>
			<DialogContent>
				<TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
					<Table size="small" sx={{ minWidth: 650 }}>
						<TableHead>
							<TableRow>
								<StyledTableCell>{t('selected')}</StyledTableCell>
								<StyledTableCell>{t('name')}</StyledTableCell>
								<StyledTableCell>{t('type')}</StyledTableCell>
								<StyledTableCell>Deadline</StyledTableCell>
								<StyledTableCell>{t('priority')}</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.referrals
								.slice(props.referrals.length - 10)
								.reverse()
								.map((referral) => (
									<TableRow
										key={referral.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											<Radio
												checked={selectedReferral.id === referral.id}
												onChange={() => setSelectedReferral(referral)}
												name="available-referrals"
											/>
										</TableCell>
										<TableCell>{referral.id}</TableCell>
										<TableCell>{referral.referralType}</TableCell>
										<TableCell>
											{format(referral.deadline, 'yyyy-MM-dd')}
										</TableCell>
										<TableCell>{referral.priority.replace('pr', '')}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} autoFocus>
					{t('cancel')}
				</Button>
				<Button onClick={() => props.confirm(selectedReferral)}>
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
