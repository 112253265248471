import {
	Box,
	TextField,
	Typography,
	Chip,
	Tooltip,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Autocomplete,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralNameAndAbbr, Room } from './Types';

export interface TabPanelProps {
	index: number;
	selectedTab: number;
	translationBase: string;
	room: Room;
	allReferrals: Array<ReferralNameAndAbbr>;
	deleteRoom: (id: number) => void;
	updateRoom: (updatedRoom: Room) => void;
}

function HasChanged(arr1: ReferralNameAndAbbr[], arr2: ReferralNameAndAbbr[]) {
	if (arr1.length !== arr2.length) return true;
	for (let i = 0; i < arr1.length; ++i) {
		if (!arr2.some((element) => element.abbrev === arr1[i].abbrev)) {
			return true;
		}
	}
	return false;
}

export function RoomPanel(props: TabPanelProps) {
	const { selectedTab, index } = props;
	const [roomCopy, setRoomCopy] = useState<Room>({ ...props.room });
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const defaultRefs = [...props.room.roomreferraltypes].reduce<
		ReferralNameAndAbbr[]
	>((acc, current) => {
		const result = props.allReferrals.find(
			(referral) => referral.id === current.referraltype.id
		);
		if (result !== undefined) return [...acc, result];
		return acc;
	}, []);
	const [selectedReferrals, setSelectedReferrals] = useState(defaultRefs);

	// const defaultRefs: ReferralNameAndAbbr[] = roomCopy.roomreferraltypes
	// .map((item) =>
	// 	props.referrals.find((referral) => referral.id === item.referraltype.id)
	// )
	// .filter<ReferralNameAndAbbr>((item) => item !== undefined);

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	// disabled if abbrev, name & selected referrals unchanged
	const disabled =
		roomCopy.abbrev === props.room.abbrev &&
		roomCopy.name === props.room.name &&
		!HasChanged(selectedReferrals, defaultRefs);

	return (
		<div
			// role="roompanel"  // wrong ARIA role, find an appropriate role, if needed
			hidden={selectedTab !== index}
			style={{
				margin: '1rem',
				height: 'inherit',
				width: 'inherit',
			}}
		>
			{selectedTab === index &&
				props.allReferrals &&
				props.allReferrals.length > 0 && (
					<Box
						sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
					>
						<Box sx={{ flex: '1' }}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									gap: '1rem',
								}}
							>
								<TextField
									label={t('name')}
									value={roomCopy.name}
									onChange={(e) =>
										setRoomCopy({ ...roomCopy, name: e.target.value })
									}
								/>
								<TextField
									label={t('abbreviation')}
									value={roomCopy.abbrev}
									onChange={(e) =>
										setRoomCopy({ ...roomCopy, abbrev: e.target.value })
									}
								/>
							</Box>
							<Typography sx={{ marginTop: 2 }} variant="subtitle1">
								{t('available for')}
							</Typography>
							<Box>
								<Autocomplete
									multiple
									id="-standard"
									options={props.allReferrals}
									getOptionLabel={(option: ReferralNameAndAbbr) => option.name}
									value={selectedReferrals}
									onChange={(event: any, newValue: ReferralNameAndAbbr[]) => {
										setSelectedReferrals(newValue);
										setRoomCopy({
											...roomCopy,
											roomreferraltypes: newValue.map((ref) => ({
												referraltype: { id: ref.id },
											})),
										});
									}}
									renderTags={(
										value: readonly ReferralNameAndAbbr[],
										getTagProps
									) =>
										value.map((option: ReferralNameAndAbbr, index: number) => (
											<Chip label={option.name} {...getTagProps({ index })} />
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											placeholder="Utrustning"
										/>
									)}
								/>
							</Box>
							<Box sx={{ '& .MuiChip-root': { marginRight: '1rem' } }}>
								{roomCopy.roomequipments.map(
									(item, index) =>
										item.equipment && (
											<Tooltip key={index} title={item.equipment.name}>
												<Chip label={item.equipment.abbrev} />
											</Tooltip>
										)
								)}
							</Box>
						</Box>

						<Box>
							<Box sx={{ float: 'left' }}>
								<Button
									color="primary"
									onClick={() => {
										props.updateRoom(roomCopy);
									}}
									disabled={disabled}
								>
									{t('update room')}
								</Button>
								<Button
									color="primary"
									onClick={() => {
										setRoomCopy({ ...props.room });
										setSelectedReferrals(defaultRefs);
									}}
									disabled={disabled}
								>
									{t('reset')}
								</Button>
							</Box>
							<Box sx={{ float: 'right' }}>
								<Button color="error" onClick={() => setDeleteDialogOpen(true)}>
									{t('remove room')}
								</Button>
							</Box>
						</Box>
					</Box>
				)}
			<Dialog
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
			>
				<DialogTitle>
					{`${t('verify removal of room')}: ${props.room.name}`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('remove room text')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
						{t('cancel')}
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							props.deleteRoom(props.room.id);
							setDeleteDialogOpen(false);
						}}
					>
						{t('remove')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
