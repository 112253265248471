import {
	DragLayerMonitor,
	DragSourceMonitor,
	useDrag,
	useDragLayer,
	useDrop,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import type { XYCoord } from 'dnd-core';
import { Settings, Lock, LockOpen } from '@mui/icons-material';
import { SxProps, Box, Typography, IconButton, Fade } from '@mui/material';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { DragStaff, Staff, Team } from './Types';
import { useEffect, useRef } from 'react';

function isInvalid(team: Team, additionalStaff?: Staff) {
	// The staff container is never invalid
	if (team.id === 'Personal' || team.id === 'Staff') return false;

	const teamCopy = { ...team, staff: [...team.staff] };
	if (additionalStaff !== undefined) teamCopy.staff.push(additionalStaff);

	let invalid = false;
	// Make sure the number of staff with a profession is within the constraint bounds
	for (let constraint of teamCopy.constraints) {
		const numOfProfession = teamCopy.staff.filter(
			(s) => s.profession === constraint.name
		).length;
		if (numOfProfession < constraint.min || numOfProfession > constraint.max) {
			invalid = true;
			break;
		}
	}

	if (!invalid) {
		const staffProfessions = Array.from(
			new Set(teamCopy.staff.map((s) => s.profession))
		);
		const availableConstraints = Array.from(
			new Set(teamCopy.constraints.map((constraint) => constraint.name))
		);

		invalid = ![...staffProfessions].every((value) =>
			availableConstraints.includes(value)
		);
	}

	return invalid;
}

function getItemStyles(
	initialOffset: XYCoord | null,
	currentOffset: XYCoord | null
) {
	if (!initialOffset || !currentOffset) return { display: 'none' };

	const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
	return { transform, WebkitTransform: transform };
}

export const CustomStaffDragLayer = () => {
	const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
		(monitor: DragLayerMonitor<DragStaff>) => ({
			item: monitor.getItem() as DragStaff,
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging(),
		})
	);

	if (!isDragging) return null;

	const color = chroma(item.color);
	const contrastColor =
		chroma.contrast(color.hex(), '#000') < 4.5 ? '#fff' : '#000';

	return (
		<div
			style={{
				position: 'fixed',
				pointerEvents: 'none',
				zIndex: 100000,
				left: 0,
				top: 0,
				width: item.sourceWidth,
				height: item.sourceHeight,
			}}
		>
			<div style={getItemStyles(initialOffset, currentOffset)}>
				<Box
					sx={{
						background: color.hex(),
						color: contrastColor,
						cursor: 'move',
						userSelect: 'none',
						border: '1px solid #000',
						borderRadius: '4px',
						padding: '0.25rem 0.5rem',
						margin: '0.125rem',
						mx: '0.25rem',
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr min-content',
						}}
					>
						<Typography variant="body2">{item.staff.name}</Typography>
						<IconButton sx={{ p: 0, color: contrastColor }}>
							<Settings />
						</IconButton>

						<Typography variant="body2">{item.staff.profession}</Typography>
						<IconButton
							sx={{
								p: 0,
								color: contrastColor,
							}}
						>
							{item.staff.locked ? <Lock /> : <LockOpen />}
						</IconButton>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export const DragableStaff = (props: {
	staff: Staff;
	updateStaff: (updatedStaff: Staff) => void;
	fade: boolean;
	team: Team;
	teamColor: string;
	staffIndex: number;
	setSelectedStaff: (staff: Staff) => void;
	swapStaff: (a: number, b: number) => void;
	swapTeam: (sourceTeamId: string, staffId: number) => void;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [, drop] = useDrop<DragStaff, void>(
		{
			accept: 'staff',
			hover: (item: DragStaff, monitor) => {
				if (!ref.current) return;

				if (props.team.id !== item.sourceTeamId) {
					item.index = props.team.staff.length;
					props.swapTeam(item.sourceTeamId, item.staff.id);
					item.sourceTeamId = props.team.id;
					return;
				}

				const dragIndex = item.index;
				const hoverIndex = props.staffIndex;

				// Don't replace items with themselves
				if (dragIndex === hoverIndex) return;

				// Determine rectangle on screen
				const hoverBoundingRect = ref.current?.getBoundingClientRect();

				// Get vertical middle
				const hoverMiddleY =
					(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

				// Determine mouse position
				const clientOffset = monitor.getClientOffset();

				// Get pixels to the top
				const hoverClientY =
					(clientOffset as XYCoord).y - hoverBoundingRect.top;

				// Only perform the move when the mouse has crossed half of the items height
				// When dragging downwards, only move when the cursor is below 50%
				// When dragging upwards, only move when the cursor is above 50%

				// Dragging downwards
				if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

				// Dragging upwards
				if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

				// Time to actually perform the action
				props.swapStaff(dragIndex, hoverIndex);

				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				item.index = hoverIndex;
			},
		},
		[props]
	);

	const [{ isDragging }, drag, preview] = useDrag(
		() => ({
			canDrag: props.staff.available,
			type: 'staff',
			item: () => ({
				staff: props.staff,
				sourceTeamId: props.team.id,
				index: props.staffIndex,
				sourceWidth: ref.current
					? ref.current.getBoundingClientRect().width
					: 0,
				sourceHeight: ref.current
					? ref.current.getBoundingClientRect().height
					: 0,
				color: props.teamColor,
			}),
			collect: (monitor: DragSourceMonitor<DragStaff, void>) => ({
				isDragging: monitor.isDragging(),
			}),
			isDragging: (monitor: DragSourceMonitor<DragStaff, void>) =>
				monitor.getItem().staff.id === props.staff.id,
		}),
		[props, ref]
	);

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const color = chroma(props.teamColor);
	const contrastColor =
		chroma.contrast(color.hex(), '#000') < 4.5 ? '#fff' : '#000';

	drag(drop(ref));
	return (
		<Box
			ref={ref}
			sx={{ opacity: isDragging ? 0 : props.staff.available ? undefined : 0.5 }}
		>
			<Fade
				in={true}
				timeout={
					props.fade === false || props.team.id === ''
						? 0
						: Math.max(props.staffIndex * 100, 2000)
				}
			>
				<Box
					sx={{
						background: color.hex(),
						cursor: props.staff.available ? 'move' : undefined,
						userSelect: 'none',
						border: '1px solid #000',
						borderRadius: '4px',
						padding: '0.25rem 0.5rem',
						margin: '0.125rem',
						mx: '0.25rem',
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr min-content',
						}}
					>
						<Typography variant="body2">{props.staff.name}</Typography>
						<IconButton
							sx={{ p: 0, color: contrastColor }}
							onClick={() => props.setSelectedStaff(props.staff)}
						>
							<Settings />
						</IconButton>
						<Typography variant="body2">{props.staff.profession}</Typography>
						<IconButton
							onClick={() =>
								props.updateStaff({
									...props.staff,
									locked: !props.staff.locked,
								})
							}
							sx={{
								display: !props.staff.available ? 'none' : undefined,
								p: 0,
								color: contrastColor,
							}}
						>
							{props.staff.locked ? <Lock /> : <LockOpen />}
						</IconButton>
					</Box>
				</Box>
			</Fade>
		</Box>
	);
};

export function TeamDroppable(props: {
	team: Team;
	updateStaff: (updated: Staff) => void;
	teamColor: string;
	onDrop: (
		staffId: number,
		sourceTeamId: string,
		sourceIndex: number,
		hoverIndex: number
	) => void;
	setSelectedStaff: (staff: Staff) => void;
	swapStaff: (a: number, b: number) => void;
	fade: boolean;
	sx?: SxProps;
	translationBase: string;
}) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const color = chroma(props.teamColor);

	const [{ isOver, draggedStaff }, drop] = useDrop(
		() => ({
			accept: 'staff',
			drop: (item: { staff: Staff; sourceTeamId: string }) => {
				if (item.sourceTeamId !== props.team.id)
					props.onDrop(item.staff.id, item.sourceTeamId, 0, 0);
			},
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				draggedStaff: monitor.getItem(),
			}),
		}),
		[props]
	);
	return (
		<Box
			ref={drop}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				...props.sx,
				'div > div:nth-of-type(1) > div.draggable-box': {
					marginTop: '0.25rem',
				},
				'div > div:nth-last-of-type(1) > div.draggable-box': {
					marginBottom: '0.25rem',
				},
			}}
		>
			<Typography variant="h6" sx={{ mt: '0.25rem' }}>
				{props.team.id !== 'Staff' || 'Personal' ? props.team.id : t('staff')}
			</Typography>

			<Box
				key={props.team.id}
				sx={{
					background: color.desaturate(0.5).hex(),
					// background: chroma.blend(color, '#aaa', 'lighten').hex(),
					color: chroma.contrast(color, '#000') < 4.5 ? '#fff' : '#000',
					flex: '1 1 min-content',
					borderRadius: '4px',
					overflowY: 'auto',
					height: '100%',
					outline: isInvalid(
						props.team,
						isOver ? draggedStaff.staff : undefined
					)
						? 'red solid 2px'
						: '',
				}}
			>
				{props.team.staff.map((staff, staffIndex) => (
					<DragableStaff
						key={staff.id}
						staff={staff}
						updateStaff={props.updateStaff}
						setSelectedStaff={props.setSelectedStaff}
						swapStaff={(a, b) => props.swapStaff(a, b)}
						swapTeam={(sourceTeamId, staffId) =>
							props.onDrop(staffId, sourceTeamId, 0, 0)
						}
						fade={props.fade}
						staffIndex={staffIndex}
						team={props.team}
						teamColor={props.teamColor}
					/>
				))}
			</Box>
		</Box>
	);
}
