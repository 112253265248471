import {
	Stack,
	Autocomplete,
	InputLabel,
	TextField,
	Chip,
	Box,
	Typography,
} from '@mui/material';
import chroma from 'chroma-js';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppThemeContext } from '../../../../AppTheme';
import { ReferralTypeQualification } from './Types';

export interface QualificationsSelectProps {
	values: Array<ReferralTypeQualification>;
	options: Array<ReferralTypeQualification>;
	translationBase: string;
	updateSelection: (newValue: Array<ReferralTypeQualification>) => void;
	title?: string;
	color: string;
}

export function QualificationsSelect(props: QualificationsSelectProps) {
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	const tm = useContext(AppThemeContext);
	const foregroundColor =
		props.color && chroma.contrast(props.color, '#000') < 4.5 ? '#fff' : '#000';

	return (
		<Stack spacing={1}>
			<Autocomplete
				multiple
				disableCloseOnSelect
				id="multi-select-qualifications"
				options={[...props.options]}
				value={props.values}
				filterSelectedOptions
				onChange={(_, newValue) => props.updateSelection(newValue)}
				isOptionEqualToValue={(option, value) =>
					option.id === value.id && option.abbrev === value.abbrev
				}
				getOptionLabel={(option) => option.abbrev}
				renderInput={(params) => (
					<>
						<InputLabel shrink>
							{props.title ? props.title : t('qualifications list')}
						</InputLabel>
						<TextField
							{...params}
							variant="outlined"
							placeholder={t('qualifications')}
						/>
					</>
				)}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							label={option.abbrev}
							{...getTagProps({ index })}
							sx={{
								backgroundColor: props.color && props.color,
								color: foregroundColor,
							}}
						/>
					))
				}
				noOptionsText={t('no options')}
				renderOption={(props, option, state) => {
					return (
						<li {...props} style={{ borderBottom: '1px solid #ccc' }}>
							<Box>
								<Typography variant="body1" component="p">
									{option.abbrev}
								</Typography>
								<Typography
									variant="body2"
									color="#777"
									display="flex"
									sx={{ gap: '1rem' }}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'baseline',
											justifyContent: 'flex-start',
											gap: '2px',
										}}
									>
										<Box
											sx={{
												backgroundColor: tm.paletteColors.green.light,
												height: '0.75rem',
												width: '0.75rem',
											}}
										/>
										{`${option.bma} min ${t('exam duration')}`}
									</Box>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
											gap: '2px',
										}}
									>
										<Box
											sx={{
												backgroundColor: tm.paletteColors.green.dark,
												height: '0.75rem',
												width: '0.75rem',
											}}
										/>
										{`${option.interpret} min ${t('interpret duration')}`}
									</Box>
								</Typography>
							</Box>
						</li>
					);
				}}
			/>
		</Stack>
	);
}
