import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Staff } from './Types';
import {
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Button,
	DialogActions,
	DialogContent,
	Chip,
	Box,
	FormLabel,
	TextField,
	FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

export function StaffSettingsDialog(props: {
	selectedStaff: Staff | undefined;
	setSelectedStaff: (newSelectedStaff: Staff | undefined) => void;
	availableTeams: Array<{ name: string; hexColor: string }>;
	updateStaff: (updatedStaff: Staff) => void;
	translationBase: string;
}) {
	const removeTeam = (teamName: string) => {
		if (props.selectedStaff !== undefined)
			props.updateStaff({
				...props.selectedStaff,
				teamAllowedPlacement: props.selectedStaff.teamAllowedPlacement.filter(
					(t) => t !== teamName
				),
			});
	};

	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog
			onClose={() => props.setSelectedStaff(undefined)}
			open={props.selectedStaff !== undefined}
			maxWidth="sm"
			fullWidth={true}
		>
			{props.selectedStaff !== undefined && (
				<>
					<DialogTitle>{`${t('settings for')} ${
						props.selectedStaff.name
					}`}</DialogTitle>
					<DialogContent>
						<FormControl>
							<FormLabel>{t('profession')}</FormLabel>
							<TextField disabled value={props.selectedStaff.profession} />
						</FormControl>

						<FormControl size="small" fullWidth={true} sx={{ mt: '0.5rem' }}>
							<FormLabel>{t('allowed team placement')}</FormLabel>
							<Select
								multiple
								value={props.selectedStaff.teamAllowedPlacement}
								renderValue={(selected) => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => {
											const foundTeam = props.availableTeams.find(
												(s) => s.name === value
											);
											const bgColor =
												foundTeam !== undefined ? foundTeam.hexColor : '#fff';
											const textColor =
												chroma.contrast(bgColor, '#fff') < 4.5
													? '#000'
													: '#fff';

											return foundTeam === undefined ? undefined : (
												<Chip
													sx={{ background: bgColor, color: textColor }}
													key={value}
													label={value}
													onMouseDown={(e) => e.stopPropagation()}
													onDelete={() => removeTeam(value)}
												/>
											);
										})}
									</Box>
								)}
							>
								{props.availableTeams.map((team) => (
									<MenuItem
										key={team.name}
										value={team.name}
										onClick={() => {
											if (props.selectedStaff !== undefined) {
												if (
													!props.selectedStaff.teamAllowedPlacement.includes(
														team.name
													)
												)
													props.updateStaff({
														...props.selectedStaff,
														teamAllowedPlacement: [
															...props.selectedStaff.teamAllowedPlacement,
															team.name,
														],
													});
												else removeTeam(team.name);
											}
										}}
									>
										<Checkbox
											checked={props.selectedStaff?.teamAllowedPlacement.includes(
												team.name
											)}
										/>
										<ListItemText primary={team.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControlLabel
							control={
								<Checkbox
									checked={props.selectedStaff.available}
									onClick={() => {
										if (props.selectedStaff !== undefined)
											props.updateStaff({
												...props.selectedStaff,
												available: !props.selectedStaff?.available,
											});
									}}
								/>
							}
							label={t('available')}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.setSelectedStaff(undefined)}>
							{t('close')}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
