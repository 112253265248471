import { Box, Chip, FormControlLabel, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Room, Staff } from '../Types';
import { ReferralsPreview } from './SchedulingDialog';

export default function SavePlanningDialog(props: {
	open: boolean;
	close: () => void;
	selectedDate: Date;
	rooms: Array<Room>;
	selectedBMA: Array<Staff>;
	selectedDoctors: Array<Staff>;
	translationBase: string;
}) {
	const [sendNotification, setSendNotification] = useState(true);
	const { t } = useTranslation('translation', {
		keyPrefix: props.translationBase,
	});

	return (
		<Dialog open={props.open} onClose={props.close}>
			<DialogTitle>
				<Typography>{t('confirm save')}</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography sx={{ mb: '0.25rem' }}>
					{t('date')}: {props.selectedDate.toLocaleString().split(' ')[0]}
				</Typography>

				<Typography>{t('referrals')}</Typography>
				<ReferralsPreview
					referrals={props.rooms.map((r) => r.referrals).flat()}
				/>

				<Typography>{t('MLS')}</Typography>
				<Box sx={{ mb: '0.5rem' }}>
					{props.selectedBMA.map((bma) => (
						<Chip
							key={bma.id}
							label={bma.name}
							sx={{ m: '0.125rem 0.25rem' }}
						/>
					))}
				</Box>

				<Typography>{t('doctor')}</Typography>
				<Box>
					{props.selectedDoctors.map((doctor) => (
						<Chip
							key={doctor.id}
							label={doctor.name}
							sx={{ m: '0.125rem 0.25rem' }}
						/>
					))}
				</Box>
				<FormControlLabel
					control={
						<Switch
							checked={sendNotification}
							onChange={() => setSendNotification(!sendNotification)}
						/>
					}
					sx={{
						m: '0px',
						mt: '1rem',
					}}
					labelPlacement="top"
					label={t('send notification')}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} autoFocus>
					{t('cancel')}
				</Button>
				<Button onClick={props.close}>{t('confirm')}</Button>
			</DialogActions>
		</Dialog>
	);
}
